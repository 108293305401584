import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const MainScanTile = ({ palletQtyAndLocation, unboundedText, unboundedText2, boundedText, locationQtyPallet_List, DefaultValue, throw_type, button_type, InputPlaceholder, onScanHandler, scanValue, inputRef, scanText, onLongItemNameClick }) => {
    const { keyboardType } = useSelector(state => state.slice);
    const [showinput, setShowinput] = useState(true);
    const [inputMode, setInputMode] = useState('none');

    useEffect(() => {
        setShowinput(false)
        setTimeout(() => {
            setShowinput(true)
        }, 10);
    }, [DefaultValue])

    useEffect(() => setInputMode('none'), [unboundedText, boundedText, InputPlaceholder])

    const showKeyboard = () => {
        setInputMode('text');
        inputRef.current.inputMode = 'text';
    }

    return (
        <div className={"cmt-10 mainScanTile" + (throw_type === "specialpick" ? ' backgroundColor-Type8' : ' backgroundColor-Type3')}>
            {throw_type === "specialpick" &&
                <div className="sp_Pick"><label>Special Pick</label></div>
            }
            <div className={"backgroundColor-Gradient" + (palletQtyAndLocation ? ' uperScaner-div2' : ' uperScaner-div')}>
                <div className="uperSD-Text font-large-md px-3 py-4">
                    <span className="d-block">{unboundedText}</span>
                    <span className="d-block">{unboundedText2}</span>
                </div>

                {boundedText && <div className="uperSD-boundedText font-xlarge" onClick={(e) => { onLongItemNameClick(boundedText) }}>{boundedText}</div>}
                {
                    palletQtyAndLocation &&
                    <div className="uperSD-Text2 font-small ">
                        <div className="uperSD-boundedTextHeader cpr-15 cpl-15 cpt-15 cpb-10 font-medium" >
                            <div className="font-large-md">Location(s)</div>
                            <div className="font-large-md">Qty of Pallet(s)</div>
                        </div>
                        <div className="uperSD-boundedText2">
                            {Object.keys(locationQtyPallet_List).map(item => (
                                <li className="flexspacebtw" key={item}>
                                    <div className="font-small">{item}</div>
                                    <div className="font-small">{locationQtyPallet_List[item]}</div>
                                </li>
                            ))}
                        </div>
                    </div>
                }
            </div>
            <div className="backgroundColor-Type3 scanner-div cpt-15 cmb-15">
                <div className=" backgroundColor-Type4 scannerInput cmb-15 field" style={{ "width": button_type ? "80%" : "95%" }}>
                    {showinput &&
                        <input type={keyboardType}
                            autoFocus={true}
                            ref={inputRef}
                            className="backgroundColor-Type4 inputscan"
                            onPaste={(e) => {
                                e.preventDefault()
                                return false;
                            }}
                            onCopy={(e) => {
                                e.preventDefault()
                                return false;
                            }}
                            onClick={showKeyboard}
                            inputMode={inputMode}
                            onKeyUp={onScanHandler}
                            defaultValue={DefaultValue}
                            placeholder={InputPlaceholder}
                            onChange={(e) => scanValue(e.target.value)}
                        />
                    }

                    {
                        !button_type &&
                        <img className="floatRight op_5" src={process.env.PUBLIC_URL + '/Assets/icons/noun_Barcode_2011116 2.svg'} alt="line" onClick={(e) => { onScanHandler(e) }} />
                    }
                </div>
                {
                    button_type &&
                    <img className="floatRight cml-10 cmb-15" src={process.env.PUBLIC_URL + '/Assets/icons/B3.png'} alt="line" onClick={(e) => { onScanHandler(e) }} />
                }
            </div>
        </div>
    )
}

export default MainScanTile;
