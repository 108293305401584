import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../Redux/mainRedux/mainActions";
import { slice } from "../Redux/mainRedux/mainSlice";
import AppButton from "../Components/appButton";
import AuthCode from "../Components/authCodeInput";
import { Backdrop, Slide } from "@mui/material";

const TwoFactorAuth = () => {
    const { totpData, error, userCredentials } = useSelector(state => state.slice);
    const [open, setOpen] = useState(false);
    // const [warehouseClick, setWarehouseClick] = useState(false);
    const dispatch = useDispatch();

    const style = {
        pageHeaderText: {
            fontSize: '30px'
        },
        pageSubHeading1: { fontSize: '14px', lineHeight: '22px', textAlign: 'center', color: '#000000', paddingLeft: '20px', paddingRight: '20px', fontWeight: 500 }
    }

    const handleValidate = (e) => {
        e.preventDefault();
        const pinCode = window.getInputCode();
        // {"username": "aatisamahmed", "code": "1022323" }
        if (pinCode) {
            const body = { userCredentials, code: pinCode }
            console.log(body);
            dispatch(actions.vlidateTOTP(body, 'post', null, 'home'));
        }
    }

    // Handle close for dropdown and backdrop
    // const handleClose = () => {
    //     setWarehouseClick((prev) => !prev);
    //     setOpen(false);
    // };

    return (
        <form className="row" style={{ marginTop: '20%' }} onSubmit={handleValidate}>
            <div className="col-sm-12 col-xs-12 text-center">
                <h3 style={style.pageHeaderText}>Authenticator</h3>
            </div>
            <div className="col-xs-12">
                <p style={style.pageSubHeading1}>
                    Your login requires Two Factor Authentication. Please install Google Authenticator IOS Google Authenticator or other TOTP (Time-based One-Time Password) Supporting MFA. Then enter the below login Secret key
                </p>
            </div>
            <div className="col-xs-12 text-center">
                {totpData && totpData.data?.attributes?.mfa_response?.isNewSetup ? (
                    <div style={{ width: '186px', height: '34px', backgroundColor: '#56CCF2', borderRadius: '25px', paddingTop: '6px', margin: '0 auto' }}>
                        <p style={{ fontSize: '14px', fontWeight: '500', textTransform: 'uppercase' }}>
                            {totpData && totpData.data?.attributes?.mfa_response?.secret}
                        </p>
                    </div>
                ) : null}
            </div>
            <div className="col-xs-12 col-sm-12 text-center mt-4">
                {totpData && totpData.data?.attributes?.mfa_response?.isNewSetup ? (
                    <img src={totpData.data?.attributes?.mfa_response.qrCodeUrl} width="101" alt="QR Code" loading="lazy" />
                ) : null}
            </div>

            <div className="col-xs-12 col-sm-12 mt-3">
                <p style={{ fontSize: '14px', lineHeight: '22px', textAlign: 'center', color: '#000000', paddingLeft: '12px', paddingRight: '12px', fontWeight: 500 }}>Enter the verification code generated by Authenticator to validate setup correctly.</p>
            </div>

            <div className="col-xs-12 col-sm-12 text-center">
                <AuthCode error={error} />
            </div>
            {error ? <small className="text-danger text-center px-5 mt-2">We’re sorry, your One-Time Password is incorrect. Please try again.</small> : null}
            <div className="col-ms-12 col-xs-12" style={{ marginTop: '10px' }}>
                <div style={{ width: '280px', margin: '0 auto' }}>
                    <AppButton displayText="Validate" />
                </div>
            </div>

            {/* <Backdrop sx={{ color: "#fff" }} open={open} onClick={handleClose} /> */}
            {/* <Slide direction="up" in={warehouseClick} mountOnEnter unmountOnExit> */}
            {/* {Icon(tile2.warehouse_list, tile2, setWarehouseClick, setOpen)} */}
            {/* </Slide> */}
        </form>
    )
};

export default TwoFactorAuth;