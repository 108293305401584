import React, { useEffect, useState, useRef } from "react";
import AppHeader from "../Components/appHeader";
import AppButton from "../Components/appButton";
import { useDispatch, useSelector } from "react-redux";
import { slice } from "../Redux/mainRedux/mainSlice";


const Password = () => {
    const dispatch = useDispatch();

    const onBackClick = () => {
        // localStorage.clear();
        // let body = { identifier: tile2?.back_button?.identifier, apiSessionStorage:sessionStorage }
        // dispatch(actions.generic_call(tile2?.back_button?.callback_url, body, 'post', token));
        dispatch(slice.actions.setPage('settings'))

    }

    return (
        <>
            <AppHeader
                pauseStatus={false}
                checkCountdown={false}
                menuIconVisible={false}
                backIconVisible={true}
                onBackClick={onBackClick}
                textVisible={true}
                text={"Password"}
                warehouseName={""}
                warehouseEnable={false}
            />
            <div className="row">
                <div className="col-sm-12 col-xs-12">
                    <form className="cml-20 cmr-20 cmt-60">
                        <div className="cml-10 cmr-10 cmt-10 cmb-10 input-container">
                            <label className="font-large">Create Password</label>
                            <input className={`cpt-20 cpb-20 cpl-20 cpr-20 font-large-md textColor-Type1 backgroundColor-Type1 forminput focusinput `} type="password" name="cpassword" />
                            {/* {userValue.error && <p className="font-smallBold textColor-LoginError">This is required Field </p>} */}
                        </div>
                        <div className="cml-10 cmr-10 cmt-10 cmb-10 input-container">
                            <label className="font-large">Re-enter password</label>
                            <div className={`cpt-22 cpb-22 passInput forminput `}>
                                <input
                                    className={`cpt-20 cpb-20 cpl-20 cpr-20 font-large-md textColor-Type1 backgroundColor-Type1 forminput focusinput`}
                                    type="password"
                                    name="pass"
                                // onChange={(e) => setPasswordValue({ value: e.target.value, error: null })}
                                />
                                {/* {passwordType === "password"
                                    ? <img className="floatRight eyeImage cpr-10" src={process.env.PUBLIC_URL + '/Assets/icons/EyeClose.png'} alt="line" onClick={togglePassword} />
                                    : <img className="floatRight eyeImage cpr-10" src={process.env.PUBLIC_URL + '/Assets/icons/EyeOpen.png'} alt="line" onClick={togglePassword} />} */}
                            </div>

                            {/* {passwordValue.error && <p className="font-smallBold textColor-LoginError">This is required Field </p>} */}
                        </div>
                        <div className="cml-30 cmr-30 cmt-30 cmb-30 formsubmit-container">
                            <AppButton displayText="Done" />
                        </div>
                    </form>
                </div >
            </div>

        </>

    )
}
export default Password;