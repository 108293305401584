import axios from "axios";
// const MIDDLEWARE_URL = process.env.REACT_APP_backendApiUrl; // it's a base url coming from .env file
const MIDDLEWARE_URL = window.API_Config.REACT_APP_backendApiUrl ? window.API_Config.REACT_APP_backendApiUrl : process.env.REACT_APP_backendApiUrl;

/**
 * SYNTAX OF API'S CALL
 */
// POST: add a new record to the server
// Syntax: return axios.post(url, body, headers);

// GET: get a record from server
// Syntax: return axios.get(url, headers);

// PUT: update a record to the server
// Syntax: return axios.put(url, body, headers);

// DELETE: remove a record from server
// Syntax: return axios.delete(url, headers);


/**
 * EXAMPLES OF API'S CALL
 */
// POST Request Example
// export function createNewRecord(record) {
//   return axios.post(`${MIDDLEWARE_URL}/create`, { data }, {
//     headers: {
//       'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9',
//       'Content-Type': 'application/json',
//       'Accept': 'application/json'
//     }
//   });
// }

// GET Request Example
// export function getRecord(id) {
//   return axios.get(`${MIDDLEWARE_URL}/getdata/${id}`, { headers: headers });
// }

// UPDATE => PUT: update the record on the server
// export function updateRecord(record, id) {
//   return axios.put(`${MIDDLEWARE_URL}/update/${id}`, { record }, { headers: headers });
// }

// // DELETE => delete the record from the server
// export function deleteRecord(id) {
//   return axios.delete(`${MIDDLEWARE_URL}/delete/${id}`, { headers: headers });
// }
export let isRequestPending = false;
const CancelToken = axios.CancelToken;

export function Call_API(baseURL, callback_url, body, method, token) {
  window.source = CancelToken.source();
  if (!isRequestPending) {
    isRequestPending = true;

    let config = {
      headers: { Authorization: `Bearer ${token}` },
      cancelToken: window.source.token,
    };
    let url = `${baseURL ? baseURL : MIDDLEWARE_URL + 'account-service/mobile/V3_0_0/'}${callback_url}`;
    return method == 'post'
      ? axios.post(url, body, config)
        .finally(() => isRequestPending = false)
      : axios.get(url, config)
        .finally(() => isRequestPending = false)
  }
}