export function Table({ title, tableData }) {
   return (
      <div className="mt-1">
         <p className="my-1 font-weight-bold">{title}</p>
         <div className="overflow-auto" style={{'height':'100px'}}>
            <table cellSpacing={0} cellPadding={0} className="w-100 table-grid rounded-4">
               <thead>
                  <tr className="">
                     {tableData.header.map(((label, i) => (
                        <td style={{"fontSize":"14px"}} className="text-center font-weight-500 py-1 px-2 border-bottom" key={i}>{label}</td>
                     )))}
                  </tr>
               </thead>
               <tbody>
                  {tableData.body.map((row, i) => (
                     <tr key={i}>
                        {row.map((value, i) => (
                           <td key={i} style={{"fontSize":"14px"}} className="p-2 text-center">
                              {value}
                           </td>
                        ))}
                     </tr>
                  ))}
               </tbody>
            </table>
         </div>
      </div>
   )
}
