import React, { useEffect, useState, useRef } from "react";
import AppHeader from "../Components/appHeader";
import PickLocationsTile from "../Components/PicklocationsTile";
import MainScanTile from "../Components/mainScanTile";
import ScanScreenSubHeader from "../Components/ScanScreenSubHeader";
import SuccessAlert from "../Components/SuccessAlert";
import Section from "../Components/section";
import ConfirmationPopup from "../Components/ConfirmationPopup";
import TimerFooter from "../Components/timerFooter";
import TimerFooterCountup from "../Components/timerFooterCountup";
import PauseTimer from "../Components/pauseTimer";
import AudioPlayer from "../Components/audioPlayer";
import Backdrop from "@mui/material/Backdrop";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../Redux/mainRedux/mainActions";
import ErrorMessage from "../Components/errorMessage";
import WarningMessage from "../Components/warningMessage";
import { slice } from "../Redux/mainRedux/mainSlice";
import SuccessMessage from "../Components/successMessage";
import ToggleButton from "../Components/toggleButton"
import DroppedPackagesList from "../Components/droppedPackagesList";
import { Table } from "../Components/Table";
import { BottomSheet } from "../Components/BottomSheet";
import BottomSlide from "../Components/bottomSlide";
import AppButton from "../Components/appButton";
import { hideKeyboard } from "../utility/hideKeyboard";

const ScanScreenType1 = () => {
  const { baseAPIURL, scan1, token, sessionStorage, apiSuccess, error, warning, success, timer, totalTimeSpend, timerdetail, pauseTime, pauseCount, keyboardStatus, userType } = useSelector(state => state.slice);
  const dispatch = useDispatch();
  const [scanVal, setScanVal] = useState(null)
  const [errorOpen, setErrorOpen] = useState(false);
  const [warningOpen, setWarningOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [checkReprintClick, setReprintClick] = useState(false);
  const [checkCountdown, setCountDown] = useState(false)
  const [checkShowCountDown, setShowCountDown] = useState(false)
  const [checkRestartTimer, setRestartTimer] = useState(false);
  const [checkPopupHolder, setPopupHolder] = useState(null);
  const [checkPause, setPause] = useState(false)
  const [totalTime, setTotalTime] = useState(0);
  const [totalPauseTime, setTotalPauseTime] = useState(0);
  const [successOpen, setSuccessOpen] = useState(false);
  const [scanError, setScanError] = useState(false);
  const [hideArea, setHideArea] = useState(true)
  const [checkPackageList, setPackageList] = useState(false)
  const [checkSuccessBeep, setSuccessBeep] = useState(false)
  const [showdefaultVal, setShowdefaultVal] = useState(null);
  const [showBottomList, setShowBottomList] = useState([]);
  const [showExitPopupVal, setExitPopupVal] = useState(false);
  const [checkBottomSlider, setBottomSlider] = useState(false);
  const [checkBottomSliderVal, setBottomSliderVal] = useState(null);

  let sound1 = "/Assets/audio/beep90.mp3";
  let sound2 = "/Assets/audio/goodscan.mp3";
  let sound3 = "/Assets/audio/badscan.mp3";
  let scanErrorMessage = {
    'detail': 'Barcode not recognized. Please scan again properly.'
  }
  const inputRef = useRef();

  window.scrollEnable = function () {
    setHideArea(false)
  }

  window.scrollDisable = function () {
    setHideArea(true)
  }

  const scanHandler = e => {
    e.preventDefault()
    if (e.key === "Enter" || e.type === "click") {
      if (inputRef.current.value) {
        setSuccessBeep(false)
        dispatch(slice.actions.setApiSuccess(false))
        let body = { ...scan1.call_back_url_params, scan_value: inputRef.current.value, apiSessionStorage: sessionStorage, pause_duration: pauseTime, pause_count: pauseCount, net_time_consumed: totalTime, };
        inputRef.current.value = "";
        hideKeyboard();
        dispatch(actions.generic_call(baseAPIURL, scan1.call_back_url, body, 'post', token));
      } else {
        hideKeyboard();
        setScanError(true);
        setErrorOpen(true);
      }
    }
  }

  useEffect(() => {
    const audio = new Audio(sound2);
    audio.play();
    if (scan1?.default_scan_value) {
      setShowdefaultVal(scan1?.default_scan_value)
    }
  }, [scan1]);

  const printCartonLabelsByPickTicketId = () => {
    // let body = { pick_ticket_id: scan1.pick_ticket_id, apiSessionStorage: sessionStorage }
    // dispatch(actions.generic_call('pickpack/printCartonLabelsByPickTicketId', body, 'post', token));
    setReprintClick(true);
    setConfirmOpen(true);
  }

  const onExitClickHandler = () => {
    setReprintClick(false);
    setConfirmOpen(true);
  }

  // For Error Message
  const errorCloseHandler = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ name: 'hide_keyboard' }))
    }
    setScanError(false);
    setErrorOpen(false);
    if (error.call_back_url && error.call_back_url_params) redirectAfterError(error)
    dispatch(slice.actions.clearError());
  };

  const confirmationCloseHandler = () => {
    setConfirmOpen(false);
  };

  const confirmationActionHandler = (data) => {
    setConfirmOpen(false);
    if (data.on_click_url) {
      if (data.title === "No" && data?.popup_message) {
        setPopupHolder(data)
        setErrorOpen(true);
        setTimeout(() => {
          redirectAfterConfirmation(data);
          // setErrorOpen(false);
          // dispatch(slice.actions.clearError());
          // setPopupHolder(null)
        }, 2000);
      } else {
        redirectAfterConfirmation(data);
      }
    }
  };

  const redirectAfterConfirmation = (data) => {
    let body = { ...data.on_click_url_params, apiSessionStorage: sessionStorage };
    dispatch(actions.generic_call(baseAPIURL, data.on_click_url, body, 'post', token));
  }

  // This will only come when there is RLLP is already created or call back URL is comes in error block
  const redirectAfterError = (data) => {
    let body = { ...data.call_back_url_params, apiSessionStorage: sessionStorage };
    dispatch(actions.generic_call(baseAPIURL, data.call_back_url, body, 'post', token));
  }

  useEffect(() => {
    // check error
    if (error) {
      setErrorOpen(true)
      if (error.code === 306) {
        setTimeout(() => {
          redirectAfterSessionExpire();
          localStorage.clear();
          setErrorOpen(false)
          dispatch(slice.actions.clearError());
        }, 2000);
      }
    }
    else {
      setErrorOpen(false)
    }

    // check success
    if (success) {
      setSuccessOpen(true)
      setSuccessBeep(true)

    } else setSuccessOpen(false)
    // check warning
    if (warning) {
      setWarningOpen(true)
      setTimeout(() => {
        setWarningOpen(false);
        dispatch(slice.actions.clearWarning());
      }, 2000);
    } else {
      setWarningOpen(false)
    }

  }, [error, success, warning]);

  useEffect(() => {
    apiSuccess && resetTimer()
  }, [apiSuccess])

  const resetTimer = () => {
    dispatch(slice.actions.setTotalTimeSpend(null));
    setSuccessBeep(true)
    if (checkCountdown) {
      setCountDown(false);
      setShowCountDown(true);
      setRestartTimer(true);
    } else {
      if (checkShowCountDown) {
        setShowCountDown(false);
      }
      setTimeout(() => {
        setShowCountDown(true);
        setRestartTimer(true);
      }, 500);

    }
  }

  const redirectAfterSuccess = () => {
    setSuccessOpen(false);
    dispatch(slice.actions.clearSuccess());
    let body = { ...success.call_back_url_params, apiSessionStorage: sessionStorage };
    dispatch(actions.generic_call(baseAPIURL, success.call_back_url, body, 'post', token));
  }

  const redirectAfterSessionExpire = () => {
    dispatch(slice.actions.setPage('login'));
  }
  // on clicking skip this pick
  const onSkipClick = (callback_url, callbackparam) => {
    let body = { ...callbackparam, apiSessionStorage: sessionStorage };
    dispatch(actions.generic_call(baseAPIURL, callback_url, body, 'post', token));
  }

  // on clicking drop this pick
  const onDropClick = (callback_url, callbackparam) => {
    let body = { ...callbackparam, apiSessionStorage: sessionStorage };
    dispatch(actions.generic_call(baseAPIURL, callback_url, body, 'post', token));
  }

  // On selecting drop package list
  const onClickDropPackageList = () => {
    setPackageList(true)
  }

  const handleSubheader = () => {
    const { onclick_bottom_popup } = scan1.pallet;
    if (onclick_bottom_popup && onclick_bottom_popup.length) {
      setShowBottomList(onclick_bottom_popup);
    }
  }

  const exitClickPopup = (val) => {
    setExitPopupVal(val);
  }

  const routeOnExit = (callbackparam, callback_url) => {
    setExitPopupVal(false);
    let body = { ...callbackparam, apiSessionStorage: sessionStorage };
    dispatch(actions.generic_call(baseAPIURL, callback_url, body, 'post', token));
  }

  const onSubHeaderDescClick = (e) => {
    if (e.length > 24) {
      setBottomSlider(true)
      setBottomSliderVal(null)
      setBottomSliderVal(e)
    }
  }

  const closeHandle = () => {
    setBottomSlider(false)
  }

  return (
    <>
      {!checkPackageList &&
        <>
          <AppHeader
            pauseStatus={checkPause}
            checkCountdown={checkCountdown}
            menuIconVisible={false}
            backIconVisible={false}
            textVisible={true}
            text={scan1.title}
            waveDesc={scan1?.description}
            onHeaderDescClick={onSubHeaderDescClick}
            warehouseName={scan1?.warehouse_selector_button_text}
            warehouseEnable={scan1?.warehouse_list?.length > 0 ? true : false}
          />
          <ScanScreenSubHeader
            title1={scan1?.item?.title}
            value1={scan1?.item?.value}
            title2={scan1?.pallet?.title}
            value2={scan1?.pallet?.value}
            title3={scan1?.lot_title ? scan1?.lot_title?.title : null}
            value3={scan1?.lot_title ? scan1?.lot_title?.value : null}
            remainValue={{ total: scan1?.qty_requested_pick, remaining: scan1?.qty_remaining }}
            remainText={scan1?.remaining_text}
            onSubHeaderClick={handleSubheader}
          />
          <div className="cpt-10 cpl-15 cpr-15 cpb-15 backgroundColor-Type5 mainPageType2">
            <div className="wfScreen">
              <div className="row">
                {hideArea ? (
                  <>
                    {scan1.upper_left &&
                      <div className="col-sm-6 col-xs-6 col-6 cpr-5">
                        <PickLocationsTile text={scan1.upper_left.title} value={scan1.upper_left.value} />
                      </div>}
                    {scan1.upper_right &&
                      <div className="col-sm-6 col-xs-6 col-6 cpl-2">
                        <PickLocationsTile text={scan1.upper_right.title} value={scan1.upper_right.value} />
                      </div>}
                    {scan1.green_box_text &&
                      <div className="col-sm-12 col-xs-12 col-12 cpt-10 cpb-10">
                        <SuccessAlert text={scan1.green_box_text} />
                      </div>}
                  </>
                ) : null}
                <div className="col-sm-12 col-xs-12 col-12">
                  <MainScanTile
                    unboundedText={scan1.blue_text}
                    unboundedText2={scan1.blue_text2}
                    boundedText={scan1.blue_dotted_text}
                    InputPlaceholder={scan1.scan_text}
                    palletQtyAndLocation={false}
                    DefaultValue={showdefaultVal} // default_scan_value
                    throw_type={scan1?.throw_type}
                    button_type={scan1?.button_type}
                    onScanHandler={scanHandler}
                    scanValue={setScanVal}
                    inputRef={inputRef}
                    onKeyUp={scanHandler}
                    scanText={scanVal}
                  />
                </div>
                {scan1?.item_on_hand &&
                  <Table
                    title={scan1?.item_on_hand?.table_title}
                    tableData={scan1?.item_on_hand?.table_body}
                  />
                }
                {(scan1.framed_footer_box_text) && (scan1.framed_footer_box_right_justified) &&
                  <div className="col-sm-12 col-xs-12 col-12 mt-2">
                    <Section left={scan1.framed_footer_box_text} right={scan1.framed_footer_box_right_justified} />
                  </div>}
                {scan1.footer_box_text && scan1.footer_box_right_justified &&
                  <div className="col-sm-6 col-xs-6 col-6 cpr-5 mt-4">
                    <PickLocationsTile text={scan1.footer_box_text} value={scan1.footer_box_right_justified} drop={true} />
                  </div>}
                {scan1.footer_right_box_text && scan1.footer_right_box_right_justified &&
                  <div className="col-sm-6 col-xs-6 col-6 cpl-2 mt-4">
                    <PickLocationsTile text={scan1.footer_right_box_text} value={scan1.footer_right_box_right_justified} drop={true} />
                  </div>}

                <div className="d-flex justify-content-center mt-3 gap-2">
                  {scan1?.showSkip &&
                    <div className="d-flex gap-1" onClick={() => onSkipClick(scan1?.showSkip?.call_back_url, scan1?.showSkip?.call_back_url_params)}>
                      <img src={process.env.PUBLIC_URL + '/Assets/icons/skip.svg'} />
                      <p className="textColor-Type1 mb-0 font-xsmall-1 cml-5">{scan1?.showSkip?.title}</p>
                    </div>}
                  {hideArea && scan1?.dropPackage ? (
                    <div className="d-flex gap-1" onClick={() => onDropClick(scan1?.dropPackage?.call_back_url, scan1?.dropPackage?.call_back_url_params)}>
                      <img src={process.env.PUBLIC_URL + '/Assets/icons/Drop.png'} alt="drop" />
                      <p className="textColor-Type1 mb-0 font-xsmall-1 cml-5">{scan1?.dropPackage?.title}</p>
                    </div>
                  ) : null}
                </div>

                {scan1?.dropPackageList &&
                  <div className="row justify-content-center footer fixed-bottom cmb-20 cml-0">
                    <div className={" d-flex align-items-center justify-content-center cpt-10 cpb-10 col-6 dropped-pkg-holder enableTile"} onClick={() => onClickDropPackageList()}>
                      <img className="cpr-7" src={process.env.PUBLIC_URL + '/Assets/icons/Drop.png'} alt="swapicon"></img>
                      <label className="drop-title">{scan1?.dropPackageList?.title}</label>
                    </div>
                  </div>}

                {checkPause &&
                  <PauseTimer
                    totalPauseTime={setTotalPauseTime} />}
                {userType != 'v1' ?
                  (
                    <div className={`footer fixed-bottom ${keyboardStatus && 'focused'}`}>
                      {((!checkCountdown && checkShowCountDown) || (!scan1.rfTimerValues)) &&
                        <TimerFooter
                          reprint_data={scan1.reprint_btn}
                          pick_ticket_id={scan1.pick_ticket_id}
                          onreprintclick={printCartonLabelsByPickTicketId}
                          onExitClick={onExitClickHandler}
                          actualTimer={scan1.rfTimerValues}
                          expiryTimestamp={timer}
                          checkCountdown={setCountDown}
                          checkPause={setPause}
                          restartTimer={checkRestartTimer}
                          totalTime={setTotalTime}
                          totalPauseTime={totalPauseTime}
                          // this will send the boolean value
                          enableExit={scan1.hasOwnProperty('exit_button_response')}
                        />}
                      {scan1.rfTimerValues &&
                        checkCountdown &&
                        <TimerFooterCountup
                          reprint_data={scan1.reprint_btn}
                          pick_ticket_id={scan1.pick_ticket_id}
                          onreprintclick={printCartonLabelsByPickTicketId}
                          onExitClick={onExitClickHandler}
                          checkPause={setPause}
                          totalTime={setTotalTime}
                          totalPauseTime={totalPauseTime}
                          // this will send the boolean value
                          enableExit={scan1.hasOwnProperty('exit_button_response')}
                        />}
                    </div>
                  ) : null}

                {(((checkCountdown) && (!checkPause) && (timerdetail?.cutoff_2_time > 0) && (timerdetail?.is_beep_enable === "Y")) || ((error?.code === 409))) &&
                  <AudioPlayer
                    sound={error?.code === 409 ? sound3 : sound1}
                    loop={error?.code === 409 ? false : true}
                    muted={(error?.code === 409) ? false : (totalTimeSpend > timerdetail?.cutoff_2_time ? false : true)}
                  />}

                {/* {(scan1?.beep_code === 200 && checkSuccessBeep) &&
                  <AudioPlayer
                    sound={sound2}
                    loop={false}
                    muted={false} 
                  />} */}
                {checkPause &&
                  <Backdrop sx={{ color: "#fff" }} open={true} />}
              </div>
              {scan1?.mini_exit_btn && hideArea &&
                <div className="d-flex col-sm-6 col-xs-6 cpl-2">
                  <div className={"ExitTile backgroundColor-Type3 font-small"} onClick={() => exitClickPopup(true)}>
                    <span className="font-medium textColor-Type8">{scan1?.mini_exit_btn?.btn_text}</span>
                  </div>
                </div>
              }
            </div>
          </div>
          {
            <BottomSlide open={showExitPopupVal} closeHandle={() => exitClickPopup(false)}>
              <div className="w-100">
                <h5 className="text-center my-4">{scan1?.mini_exit_btn?.blue_text}</h5>
                <div className="d-flex gap-2 p-2">
                  <AppButton displayText={scan1?.mini_exit_btn?.btn_option[0].title} onClick={() => routeOnExit(scan1?.mini_exit_btn?.btn_option[0].on_click_url_params, scan1?.mini_exit_btn?.btn_option[0].on_click_url)} />
                  <AppButton displayText={scan1?.mini_exit_btn?.btn_option[1].title} onClick={() => exitClickPopup(false)} isOutlined={true} />
                </div>
              </div>
            </BottomSlide>
          }

          <BottomSheet open={showBottomList.length > 0} closeHandle={() => setShowBottomList([])}>
            <table id="order-table" className="w-100">
              {showBottomList.map(row => (
                <tr>
                  <td>{row.index}</td>
                  <td>{row.order_numer}</td>
                  <td>{row.qty}</td>
                </tr>
              ))}
            </table>
          </BottomSheet>
          <BottomSlide
            children={checkBottomSliderVal}
            open={checkBottomSlider}
            closeHandle={closeHandle}
          />
        </>
      }
      {confirmOpen && <ConfirmationPopup open={confirmOpen} onClose={confirmationCloseHandler} data={checkReprintClick ? scan1.reprint_btn : scan1.exit_button_response} actionHandler={confirmationActionHandler} />}
      {errorOpen && <ErrorMessage open={errorOpen} onClose={errorCloseHandler} error={scanError ? scanErrorMessage : (checkPopupHolder ? checkPopupHolder : error)} />}
      {warningOpen && <WarningMessage open={warningOpen} warning={warning} />}
      {successOpen && <SuccessMessage open={successOpen} message={success?.popup_message} redirectAfterSuccess={redirectAfterSuccess} />}

      {checkPackageList &&
        <DroppedPackagesList
          setPackageList={setPackageList}
          dropPackageData={scan1?.dropPackageList?.detail}
        />
      }
    </>
  );
};

export default ScanScreenType1;