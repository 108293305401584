import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "../utility/debounce";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const MainScanTile4 = ({ blueTitle, unboundedText, unboundedText1, boundedText, throw_type, button_type, InputPlaceholder, InputPlaceholder1, onSearchHandler, onScanHandler, scanValue, inputRef, searchoptions, scanicon, scanText, debounceInput, onProgressItemClick, scanScreen }) => {
    const { keyboardType, progressiveArray, progressiveLoading } = useSelector(state => state.slice);
    const [inputMode, setInputMode] = useState('none');

    useEffect(() => setInputMode('none'), [unboundedText, boundedText, InputPlaceholder])

    useEffect(() => {
        console.log("Progressive Data: ", progressiveArray);
        console.log("Is Array: ", Array.isArray(progressiveArray));
    }, [progressiveArray]);

    const debounceSearch = debounce((searchText) => scanValue(searchText), 1500);

    const showKeyboard = () => {
        setInputMode('text');
        inputRef.current.inputMode = 'text';
    }

    return (
        <div className={"cmt-10 mainScanTile backgroundColor-Type3 relative"}>
            <div className={"backgroundColor-Gradient uperScaner-div-4"} >
                {blueTitle && <div className="uperSD-Text-conditionSC font-small pick-locations-title-1 w-100 cmt-10">{blueTitle}</div>}
                <div style={{ "padding": InputPlaceholder1 ? ("30px 10px 30px 10px") : (boundedText ? "10px 10px 20px 10px" : "50px 10px") }}>
                    <div className="uperSD-Text py-2">{unboundedText}
                        {unboundedText1 && <div className="textColor-Type2 font-small17">{unboundedText1}</div>}
                    </div>

                    {boundedText && <div className="uperSD-boundedText font-xlarge">{boundedText}</div>}
                </div>

            </div>
            <div className="backgroundColor-Type3 scanner-div cpt-15 cmb-15" style={{ "flexDirection": InputPlaceholder1 && "column" }}>
                <div className={`backgroundColor-Type4 scannerInput-4 field ${progressiveArray && progressiveArray.data ? '' : 'cmb-15'}`} style={{ "width": button_type ? "80%" : "95%" }}>
                    <input type={keyboardType}
                        autoFocus={true}
                        ref={inputRef}
                        className="backgroundColor-Type4 inputscan"
                        onPaste={(e) => {
                            e.preventDefault()
                            return false;
                        }}
                        onCopy={(e) => {
                            e.preventDefault()
                            return false;
                        }}
                        // value={scanText}
                        onKeyUp={onScanHandler}
                        placeholder={InputPlaceholder}
                        onClick={showKeyboard}
                        inputMode={inputMode}
                        onChange={(e) => scanValue(e.target.value)}
                        // this code is for progressive search
                        // onChange={(e) => debounceInput ? debounceSearch(e.target.value) : scanValue(e.target.value)}
                    />
                    {

                        <>{searchoptions &&
                            <img className="floatRight" src={process.env.PUBLIC_URL + '/Assets/icons/search.png'} alt="line" onClick={(e) => { onSearchHandler(e); }} />
                        }
                            {(searchoptions && scanicon) &&
                                <div className="Screen1line cmr-5 cml-5">
                                    <img className='cml-5' src={process.env.PUBLIC_URL + "/Assets/icons/line176.svg"} alt="line" />
                                </div>
                            }

                            {scanicon &&
                                <img className="floatRight" src={process.env.PUBLIC_URL + '/Assets/icons/noun_Barcode_2011116 2.svg'} alt="line" onClick={(e) => { onScanHandler(e); }} />
                            }
                        </>
                    }
                </div>
                {(button_type && !InputPlaceholder1) &&
                    <img className="floatRight cml-10 cmb-15" src={process.env.PUBLIC_URL + '/Assets/icons/B3.png'} alt="line" onClick={(e) => { onScanHandler(e) }} />
                }
                {InputPlaceholder1 &&
                    <div className=" backgroundColor-Type4 scannerInput-4 cmb-15 field" style={{ "width": button_type ? "80%" : "95%" }}>
                        <input type={keyboardType}
                            // onBlur={({ target }) => target.focus()
                            // id="readonly-input"
                            ref={inputRef}
                            className="backgroundColor-Type4 inputscan"
                            onPaste={(e) => {
                                e.preventDefault()
                                return false;
                            }}
                            onCopy={(e) => {
                                e.preventDefault()
                                return false;
                            }}
                            onClick={showKeyboard}
                            inputMode={inputMode}
                            onKeyUp={onScanHandler}
                            placeholder={InputPlaceholder1}
                            onChange={(e) => scanValue(e.target.value)}
                        />
                    </div>
                }
            </div>
            {/* this code is for progressive search */}
            {/* {progressiveArray && !progressiveArray.length ? <p className="text-center text-danger mb-0">No item found</p> : null}

            {progressiveLoading ? (
                <div id="progressive-search">
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress size={20} thickness={4} />
                    </Box>
                </div>
            ) : Array.isArray(progressiveArray) && progressiveArray.length ? (
                <div id="progressive-search">
                    {progressiveArray.map((item, i) => (
                        <p key={i} onClick={() => onProgressItemClick(item)}>{item?.[scanScreen.progessive_search.value]}</p>
                    ))}
                </div>
            ) : null} */}
        </div>
    )
}

// const Options = ({ selectedItem, dropdownOptions }) => {
//     return (

//         <div className="backgroundColor-Type3 search-dropdown">
//             <ul>
//                 {dropdownOptions.map(({ name }) => (

//                     <li className="options" key={name} onClick={() => selectedItem()}>
//                         {/* <img className={icon? "show": "hide"} src={process.env.PUBLIC_URL + '/Assets/icons/'+icon} alt="line"/> */}
//                         <label className="floatRight">{name}</label>
//                     </li>
//                 ))}
//             </ul>
//         </div>
//     )
// }
export default MainScanTile4;
