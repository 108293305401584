import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../Redux/mainRedux/mainActions";
import { slice } from '../Redux/mainRedux/mainSlice';
import WarningMessage from "../Components/warningMessage";

const HomeButton = (props) => {
    let { redirectData } = props;
    const dispatch = useDispatch();
    const { baseAPIURL, sessionStorage, token, home_btn, tile2, error, showLockUserPopup, iframeURL, userType } = useSelector(state => state.slice);
    const [homeBtnClicked, setHomeBtnClicked] = useState(false);

    const url = new URL(iframeURL);

    const showIframe = url.pathname !== '/';

    const onClickHandler = () => {
        console.log("home clicked");
        if (userType === 'v1') {
            let body = { identifier: redirectData?.identifier, apiSessionStorage: sessionStorage }
            dispatch(actions.generic_call(redirectData?.base_url, redirectData?.callback_url, body, 'post', token));
        } else {
            setHomeBtnClicked(true);
            let body = { apiSessionStorage: sessionStorage }
            dispatch(actions.generic_call('', 'pickpack/checkUserLock', body, 'post', token));
            setTimeout(() => {
                setHomeBtnClicked(false);
            }, 3500);
        }
    }

    useEffect(() => {
        if (home_btn?.status === "success" && homeBtnClicked) {
            goToHome();
        } else if (home_btn?.status === "rejected") {
            dispatch(slice.actions.setShowLockUserPopup(true))
            setTimeout(() => {
                dispatch(slice.actions.setShowLockUserPopup(false));
            }, 2000);
        }
    }, [home_btn])

    // redirect user to menu screen
    const goToHome = () => {
        // dispatch(slice.actions.setIframeURL(V2_BASE_URL));
        
        let body = { identifier: 'home_page', apiSessionStorage: sessionStorage }
        dispatch(actions.generic_call(baseAPIURL, 'getMenusFromSession', body, 'post', token));
        dispatch(slice.actions.clearHomeBtn());
        window.changeTitle(tile2.title);
    }

    return (
        <footer className="home-btn-footer homeButton">
            <div className={"formsubmit-container" + (showIframe ? '' : ' homeButtonDiv')}>
                <div className={"backgroundColor-Type3 ScanScreen1Footer" + (showIframe ? '' :
                    ' homeButtonBorder')}>
                    <div className="timer_inner" onClick={onClickHandler}>
                        <img src={process.env.PUBLIC_URL + '/Assets/icons/homeButton.svg'} alt="menu" />
                    </div>
                </div>
            </div>
            {showLockUserPopup && <WarningMessage open={showLockUserPopup} warning={home_btn} />}
        </footer>
    );
}

export default HomeButton;
