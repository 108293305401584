import React, { useEffect, useState } from "react";
import AppHeader from '../Components/appHeader'
import SubTitle from '../Components/subTitle';
import WorkFlowTile from '../Components/workFlowTile'
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../Redux/mainRedux/mainActions";
import { slice } from "../Redux/mainRedux/mainSlice";
import ErrorMessage from "../Components/errorMessage";
import HomeButton from "../Components/homeButton";
import { IframeWindow } from "../Components/IframeWindow";
import WarningMessage from "../Components/warningMessage";
import ClientSideList from "../Components/clientSideList";

const FulfillmentWorkFlowScreen = () => {
  const { baseAPIURL, tile2, token, warehouse, sessionStorage, error, iframeURL, home_btn, showLockUserPopup, userType } = useSelector(state => state.slice);
  const dispatch = useDispatch();
  const [errorOpen, setErrorOpen] = useState(false);
  const [warningOpen, setWarningOpen] = useState(false);
  const [headerTitle, setHeaderTitle] = useState(tile2?.title);
  const [warehouseClick, setWarehouseClick] = useState(false);
  const [clientTitle, setclientTitle] = useState(tile2?.warehouse_selector_button_text);
  let showIframe;
  if(iframeURL){
    const url = new URL(iframeURL);
    showIframe = url.pathname !== '/';
  }

  const tileTwoHandler = (e) => {
    // NOTE: here "e" is tile data you clicked.
    console.log(e);
    let body = { apiSessionStorage: sessionStorage };

    if (!e.disabled) {
      if (e.redirect_route) {
        const v2URL = new URL(e.redirect_route);
        const goToURL = `${iframeURL}${v2URL.pathname}${v2URL.search}`;
        // setting the goto url in to iframe.
        setHeaderTitle(e.display_text)
        dispatch(slice.actions.setIframeURL(goToURL));
        // window.location.href = `${e.redirect_route}`;
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(JSON.stringify(e))
        }
      } else if (e.call_back_url_params) {
        for (const [pramkey, pramValue] of Object.entries(e.call_back_url_params)) {
          body[pramkey] = pramValue;
        }
      } else {
        body = e.identifier ? { ...body, identifier: e.identifier, warehouse } : { ...body, warehouse };
      }
      const apiBaseUrl = (e.base_url) ? e.base_url : baseAPIURL;
      dispatch(actions.generic_call(apiBaseUrl, e.callback_url, body, 'post', token));
    }
  }

  window.changeTitle = setHeaderTitle;
  // back button handler
  const onBackClick = () => {
    localStorage.clear();

    const v2Url = new URL(iframeURL);
    // body for api call
    let body = {
      identifier: tile2?.back_button?.identifier,
      apiSessionStorage: sessionStorage,
      ...tile2.back_button.call_back_url_params,
    }

    if (userType === 'v2') {
      // check if the iframe is close.
      if (v2Url.pathname === '/') {
        dispatch(actions.generic_call(tile2?.back_button?.base_url, tile2?.back_button?.callback_url, body, 'post', token));
        return;
      }
      window.changeTitle(tile2.title);
      // close the iframe only if the the user session is not expired.
      dispatch(actions.checkUserSession(null, 'pickpack/checkUserLock', body, 'post', token));
      // dispatch(actions.checkUserSession(tile2?.back_button?.callback_url, body, 'post', token));
    } else if (userType === 'v1') {
      // window.changeTitle(tile2.title);
      // close the iframe only if the the user session is not expired.
      // dispatch(actions.checkUserSession('', 'pickpack/checkUserLock', body, 'post', token));
      // dispatch(actions.checkUserSession(tile2?.back_button?.callback_url, body, 'post', token));
      dispatch(actions.generic_call(
        tile2?.back_button?.base_url,
        tile2?.back_button?.callback_url,
        body, 'post', token
      ));
    }
  }

  // For Error Message
  const errorCloseHandler = () => {
    setErrorOpen(false);
    dispatch(slice.actions.clearError());
  };

  useEffect(() => {
    if (error) {
      setErrorOpen(true)
      if (error.code === 306) {
        setTimeout(() => {
          redirectAfterSessionExpire();
          localStorage.clear();
          setErrorOpen(false)
          dispatch(slice.actions.clearError());
        }, 2000);
      }
    }
    else {
      setErrorOpen(false)
    }
  }, [error]);

  const redirectAfterSessionExpire = () => {
    dispatch(slice.actions.setPage('login'));
  }

  useEffect(() => {
    // setting the header title on refresh
    setHeaderTitle(tile2?.title);
    setclientTitle(tile2?.warehouse_selector_button_text);
    // if (!tile2) getdata()
  }, [tile2])

  const getdata = () => {
    let body = { identifier: localStorage.getItem("Currentidentifier"), apiSessionStorage: localStorage.getItem("CurrentSession") }
    dispatch(actions.generic_call(localStorage.getItem("CurrentBaseURL"), localStorage.getItem("CurrentApi"), body, 'post', localStorage.getItem("Currenttoken")));
    dispatch(slice.actions.setToken(localStorage.getItem("Currenttoken")));
  }

      // Handle change in click for dropdown and backdrop
      const handleChange = () => {
        setWarehouseClick((prev) => !prev);
        // setOpen(!open);
      };
  

  return (
    tile2 &&
    <div>
        <ClientSideList 
        clientScreenHeader={tile2?.warehouse_page_title}
        clientdataHeader={tile2?.warehouse_table_header}
        clientdata={tile2?.warehouse_list}
        setclientTitle={setclientTitle}
        sidebarclick={warehouseClick?true:false} 
        setSidebarClose={setWarehouseClick}
        />
      <AppHeader
        menuIconVisible={false}
        backIconVisible={true}
        textVisible={true}
        onBackClick={onBackClick}
        text={headerTitle}
        warehouseName={clientTitle}
        warehouseClick={setWarehouseClick}
        onChange={handleChange}
        warehouseEnable={tile2?.warehouse_list?.length>0 ? true : false}
      />
      <IframeWindow />
      {!showIframe && tile2.sub_title && <SubTitle text={tile2.sub_title} value={tile2.sub_title ? '' : tile2.title} valueVisible={true} />}
      {!showIframe && <div className="cpt-20 cpb-20 cpl-20 cpr-20 backgroundColor-Type5 mainPageType1 FulfillmentScreen">
        <div className="row">
          {/* will recieve this title from API */}
          <span>{tile2?.FirstCount}</span>
          {tile2.tiles && tile2.tiles.map((tiles) => <WorkFlowTile onClick={tileTwoHandler} data={tiles} key={tiles.display_text} />)}
          {/* will recieve this title from API */}
          <span>{tile2?.SecondCount}</span>
          {/* will recieve Second Count tile array from API */}
          {tile2?.Secondtiles && tile2?.Secondtiles.map((tiles) => <WorkFlowTile onClick={tileTwoHandler} data={tiles} key={tiles.display_text} />)}
        </div>
      </div>
      }
      {errorOpen && <ErrorMessage open={errorOpen} onClose={errorCloseHandler} error={error} />}
      <HomeButton redirectData={tile2?.allow_home_button} />
      {showLockUserPopup && <WarningMessage open={showLockUserPopup} warning={home_btn} />}
    </div>
  );
}

export default FulfillmentWorkFlowScreen;