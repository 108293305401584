import { useDispatch, useSelector } from "react-redux";
import AppHeader from "../Components/appHeader";
import { useState } from "react";
import AppButton from "../Components/appButton";
import * as actions from "../Redux/mainRedux/mainActions";

export function DocumentOption() {
  const { documentOption, baseAPIURL, token, sessionStorage } = useSelector(state => state.slice);
  const dispatch = useDispatch();
  const [showImageView, setShowImageView] = useState(null);

  const onSelectionHandler = (payload) => {
    if (payload.on_click_url_params.type === 'view') {
      setShowImageView(payload.on_click_url_params);
    } else if(payload.on_click_url_params.type === 'delete') {
      console.log(payload);
      const body = {
        ...payload.on_click_url_params,
        apiSessionStorage: sessionStorage
      }
      dispatch(actions.generic_call(
        baseAPIURL,
        payload.on_click_url,
        body, 'post', token
      ));
    }
  }

  const backClickHandler = () => {
    let body = {}
    // body for back Button api call
    if(showImageView) {
      setShowImageView(null);
    } else if (documentOption?.back_button) {
      body['apiSessionStorage'] = sessionStorage;
      if (documentOption.back_button.call_back_url_params) {
        for (const [pramkey, pramValue] of Object.entries(documentOption.back_button.call_back_url_params)) {
          body[pramkey] = pramValue;
        }
      } else {
        body['identifier'] = documentOption?.back_button?.identifier
      }

      dispatch(actions.generic_call(
        documentOption?.back_button?.base_url,
        documentOption?.back_button?.callback_url,
        body, 'post', token
      ));
    }
  }

  return (
    <>
      <AppHeader
        menuIconVisible={false}
        backIconVisible={documentOption.back_button}
        textVisible={true}
        onBackClick={backClickHandler}
        text={documentOption?.title}
      />

      <main>
        {!showImageView ? (
          <div className="row g-1 py-4 px-3" id="document-option">
            <h5 className="text-center mb-3">
              {documentOption.blue_text}
            </h5>
            {documentOption.button_option.map((option) => {
              const { title, on_click_url, on_click_url_params, popup_message } = option;
              return (
                <div className="col-sm-6 col-xs-6 col-6" key={title}>
                  <input
                    type="button"
                    className={option.hasOwnProperty('type') && option.type === 'primary' ? "formsubmit backgroundColor-Gradient font-large" : "formsubmit font-large confirmNoBtn"}
                    value={title}
                    onClick={() => onSelectionHandler(option)}
                  />
                </div>
              )
            })}
          </div>
        ) : (
          <>
            <div className="px-2 mt-5">
              <img
                src={`data:image/png;base64, ${showImageView.base64String}`}
                className="w-100" style={{ borderRadius: '1rem', maxHeight: '460px', objectFit: 'cover', objectPosition: 'center' }}
              />
              {/* <iframe
                src={`data:application/pdf;base64, ${showImageView.base64String}`}
                type="application/pdf"
                className="w-100"
                style={{
                  borderRadius: '1rem',
                  maxHeight: '460px',
                  objectFit: 'cover',
                  objectPosition: 'center'
                }}
              ></iframe> */}
            </div>

            <div className="d-flex mx-5 mt-4 gap-2">
              <AppButton displayText="Done" onClick={() => setShowImageView(null)} />
              <AppButton displayText="Print" onClick={() => {}} isOutlined={true} disabled={true} />
            </div>
          </>
        )}
      </main>
    </>
  )
}