import AppHeader from "../Components/appHeader";
import TimerFooter from "../Components/timerFooter";
import EnterRemainingQty from "../Components/EnterRemainingQty";
import ScanScreenSubHeader from "../Components/ScanScreenSubHeader";
import SuccessAlert from "../Components/SuccessAlert";
import Section from "../Components/section";

const entermethod = e => {
  e.preventDefault()
  if (e.key === "Enter") {
    console.log(e.key);
  }
}

const RemainingQty = () => {
  return (
    <>
      <AppHeader
        menuIconVisible={false}
        backIconVisible={true}
        textVisible={true}
        text="Pick & Throw: PT-502808"
      />
      <ScanScreenSubHeader
        title1="Item Code"
        value1="HARJT0514.3"
        title2="Pallet"
        value2="00121270000003343295"
        remainValue="6"
        remainText="Remaining quantity to be picked"
      />

      <div className="cmt-10 cpl-15 cpr-15 cpb-15 backgroundColor-Type2 mainPageType2">
        <div className="wfScreen">
          <div className="row">
            <div className="col-sm-12 col-xs-12 col-12">
              <SuccessAlert text="Pick has been successfully completed" />
            </div>
            <div className="col-sm-12 col-xs-12 col-12">
              <EnterRemainingQty onKeyUp={entermethod} unboundedText="Count the quantity remaining on the pallet" InputPlaceholder="Enter quantity remaining" />
            </div>
            <div className="col-sm-12 col-xs-12 col-12 mt-2">
              <Section left="Placed Carton" right="0/2" />
            </div>
          </div>
          <TimerFooter />
        </div>
      </div>
    </>
  );
};

export default RemainingQty;
