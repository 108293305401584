
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppHeader from "../Components/appHeader";
import * as actions from "../Redux/mainRedux/mainActions";


const DroppedPackagesList = (props) => {
    let {setPackageList, dropPackageData} = props;


    const onBackClick = () => {
        setPackageList(false)
    }
    return (
        <div>
            <AppHeader
                menuIconVisible={false}
                backIconVisible={true}
                textVisible={true}
                onBackClick={onBackClick}
                text={dropPackageData?.title}
            />

        <div className="Inquiry cpl-8 cpr-8 cpb-8 cpt-15">
        <div className="title font-large cpt-10 cmr-10 cml-10">
        <div>{dropPackageData?.table_header?.locations ? dropPackageData?.table_header?.locations : '-'}</div>
        <div>{dropPackageData?.table_header?.pallets ? dropPackageData?.table_header?.pallets : '-'}</div>
        <div>{dropPackageData?.table_header?.cartons ? dropPackageData?.table_header?.cartons : '-'}</div>
        </div>

        {dropPackageData?.table_data && dropPackageData?.table_data.map((packages, index) => (
                  <div key={index} className="detail bg-white font-large cpb-10 cmb-6 cmr-10 cml-10 cpt-10">
                  <div>{packages.locations ? packages.locations : '-'}</div>
                  <div>{packages.pallets ? packages.pallets : '-'}</div>
                  <div>{packages.cartons ? packages.cartons : '-'}</div>
                  </div>
        ))}

        </div>
        </div>
    )
}

export default DroppedPackagesList;