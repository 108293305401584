import React, { useEffect, useState, useRef } from "react";
import AppHeader from "../Components/appHeader";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationPopup from "../Components/ConfirmationPopup";
import * as actions from "../Redux/mainRedux/mainActions";
import { slice } from "../Redux/mainRedux/mainSlice";
import PasswordPopup from "../Components/PasswordPopup";
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

const Settings = () => {
    const { home, menuResponse, token, sessionStorage, warehouse, keyboardType, bluetooth, isBluetoothOn } = useSelector(state => state.slice);
    const dispatch = useDispatch();
    // creating a reffrence which will true on initial mount.
    const initialMount = useRef(true);

    // const [bluetooth, setBluetooth] = useState(false);
    //const [isBluetoothOn, setIsBluetoothOn] = useState(null)

    window.checkBluetooth = function (bluetoothValue) {
        if (bluetoothValue) {
            console.log(bluetoothValue)
            //setIsBluetoothOn(bluetoothValue);
            //setBluetooth(true)
            dispatch(slice.actions.setBluetooth(bluetoothValue))
            // dispatch(slice.actions.setBluetooth(true))
        }
        else if (!bluetoothValue) {
            // setIsBluetoothOn(bluetoothValue);
            //setBluetooth(false)
            dispatch(slice.actions.setBluetooth(bluetoothValue))
            // dispatch(slice.actions.setBluetooth(false))
        }
    }


    useEffect(() => {
        if(initialMount.current) {
            // on initial mount set that to false after words.
            initialMount.current = false;
        } else {
            // this will run every time when bluetooth state changes except initial render. 
            if (window.ReactNativeWebView && bluetooth) {
                console.log(bluetooth)
                window.ReactNativeWebView.postMessage(JSON.stringify({ bluetoothState: bluetooth }))
            }else if (window.ReactNativeWebView && !bluetooth) {
                window.ReactNativeWebView.postMessage(JSON.stringify({ bluetoothState: bluetooth }))
            }
        }
    }, [bluetooth])

    const handleBluetooth = () => {
        // setBluetooth(prev => !prev)
        dispatch(slice.actions.setBluetooth(!bluetooth))
    }

    // const handlePopup = () => {
    //     setOpenPopup(prev => !prev)

    // }
    // const confirmationCloseHandler = () => {
    //     setOpenPopup(false);
    // };

    const onBackClick = () => {
        // localStorage.clear();
        // let body = { identifier: tile2?.back_button?.identifier, apiSessionStorage:sessionStorage }
        // dispatch(actions.generic_call(tile2?.back_button?.callback_url, body, 'post', token));
        dispatch(slice.actions.setPage('tile1'))

    }

    const onClickHandler = (display_text,) => {
        // let body = { identifier: display_text.toLowerCase(), apiSessionStorage: sessionStorage }
        // dispatch(actions.generic_call(callback_url, body, 'post', token));
        console.log(display_text)
        dispatch(slice.actions.setPage(display_text))
    }

    return (
        <div ref={initialMount}>
            <AppHeader
                pauseStatus={false}
                checkCountdown={false}
                menuIconVisible={false}
                backIconVisible={true}
                onBackClick={onBackClick}
                textVisible={true}
                text={"Settings"}
                warehouseName={""}
                warehouseEnable={false}
            />
            <div className="row setting-outer-padding">
                <div className="col-sm-12 col-xs-12 col-12 ">
                    <p className="font-xsmall-setting" ><small>General</small></p>
                    <div className="d-flex justify-content-between " onClick={() => onClickHandler('about')} >
                        <p className="font-mediumBold mb-0">About</p>
                        <img src={process.env.PUBLIC_URL + "/Assets/icons/Vector.svg"} alt="line" />
                    </div>
                    {/* <hr></hr>
                    <p className="font-xsmall-setting" ><small>Authentication</small></p>
                    <p className="font-mediumBold mb-2" onClick={handlePopup}>Don’t save credentials</p>
                    <div onClick={() => onClickHandler('password', 'password')} className="d-flex justify-content-between">
                        <p className="font-mediumBold mb-0">Save credentials, use password</p>
                        <img src={process.env.PUBLIC_URL + "/Assets/icons/Vector.svg"} alt="line" />
                    </div> */}
                    <hr></hr>
                    <p className="font-xsmall-setting" ><small>Default Keyboard</small></p>
                    <div className="d-flex justify-content-between mb-2 align-items-center" onClick={() => dispatch(slice.actions.setKeyboardType("text"))}>
                        <p className="font-mediumBold mb-0">QWERTY</p>
                        <label>
                            <input className="form-check-input" type="radio" checked={keyboardType === 'text'} value="text"
                            />
                            <img src={process.env.PUBLIC_URL + "/Assets/icons/Check.svg"} alt="line" />
                        </label>

                    </div>
                    <div className="d-flex justify-content-between align-items-center " onClick={() => dispatch(slice.actions.setKeyboardType("number"))}>
                        <p className="font-mediumBold mb-0">Numeric</p>
                        <label>
                            <input className="form-check-input" type="radio" name="flexRadioDefault" checked={keyboardType === 'number'} value="number" />
                            <img src={process.env.PUBLIC_URL + "/Assets/icons/Check.svg"} alt="line" />
                        </label>
                    </div>
                    <hr></hr>
                    <p className="font-xsmall-setting" ><small>Device Interfaces</small></p>
                    <div className="d-flex justify-content-between align-items-center">
                        <p className="font-mediumBold mb-0">Allow Bluetooth Keyboard</p>
                        {/* <div className="form-check form-switch">
                            <button type="button" className="btn btn-sm btn-toggle active" data-toggle="button" aria-pressed="true" autocomplete="off">
                                <div className="handle"></div>
                            </button>

                        </div> */}
                        <div>
                            <AntSwitch checked={bluetooth} onChange={handleBluetooth} inputProps={{ 'aria-label': 'ant design' }} />
                        </div>
                    </div>
                </div>
            </div>


        </div>

    )
}
export default Settings;