import AppHeader from "../Components/appHeader";
import TimerFooter from "../Components/timerFooter";
import ConfirmationTile from "../Components/ConfirmationTile";
import ScanScreenSubHeader from "../Components/ScanScreenSubHeader";
import SuccessAlert from "../Components/SuccessAlert";

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../Redux/mainRedux/mainActions";
import { slice } from "../Redux/mainRedux/mainSlice";
import ErrorMessage from "../Components/errorMessage";

const Confirmation = () => {
  const { baseAPIURL, confirmationOption, token, warehouse, sessionStorage, error } = useSelector(state => state.slice);
  const dispatch = useDispatch();
  const [selectionVal, setSelectionVal] = useState(null)
  const [errorOpen, setErrorOpen] = useState(false);

  const selectionHandler = (e) => {
    console.log('test e', e)
    if (e) {
       let body = { ...e.on_click_url_params, apiSessionStorage:sessionStorage};

      // // Temperary solution untill permanent structure
       dispatch(actions.generic_call(baseAPIURL, e.on_click_url, body, 'post', token, e.on_click_url==="pickpack/returnExtraQtyPage" ? 'scan1' : 'tile2'));
    }
  }

  const printCartonLabelsByPickTicketId = () => {
    let body = { pick_ticket_id: confirmationOption.pick_ticket_id, apiSessionStorage: sessionStorage}
    dispatch(actions.generic_call(baseAPIURL, 'pickpack/printCartonLabelsByPickTicketId', body, 'post', token, ''));
  }
  
    // For Error Message
    const errorCloseHandler = () => {
      setErrorOpen(false);
      dispatch(slice.actions.clearError());
    };
  
    useEffect(() => {
      if (error) setErrorOpen(true)
      else setErrorOpen(false)
    }, [error]);

  return (
    <>
      <AppHeader
        menuIconVisible={false}
        backIconVisible={false}
        textVisible={true}
        text={confirmationOption.title}
      />
      <ScanScreenSubHeader
       title1={confirmationOption?.item?.title}
       value1={confirmationOption?.item?.value}
       title2={confirmationOption?.pallet?.title}
       value2={confirmationOption?.pallet?.value}
       remainValue={{ total: confirmationOption?.qty_requested_pick, remaining: confirmationOption?.qty_remaining }}
       remainText={confirmationOption?.remaining_text}
      />

      <div className="cmt-10 cpl-15 cpr-15 cpb-15 backgroundColor-Type5 mainPageType2">
        <div className="wfScreen">
          <div className="row">
            <div className="col-sm-12 col-xs-12 col-12">
              <SuccessAlert text={confirmationOption.green_box_text} />
            </div>
            <div className="col-sm-12 col-xs-12 col-12">
              <ConfirmationTile 
              unboundedText={confirmationOption.blue_text} 
              buttonOptions={confirmationOption.btn_option}
              onSelectionHandler={selectionHandler}
              selectionValue={setSelectionVal} />
            </div>
          </div>
          <TimerFooter pick_ticket_id={confirmationOption.pick_ticket_id} onreprintclick={printCartonLabelsByPickTicketId} /> 
        </div>
      </div>
      {errorOpen && <ErrorMessage open={errorOpen} onClose={errorCloseHandler} message={error.detail} />}
    </>
  );
};

export default Confirmation;
