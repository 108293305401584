import React, { useState, useEffect, useRef } from "react";
import AppHeader from "../Components/appHeader";
import MainScanTile4 from "../Components/mainScanTile4";
import { slice } from "../Redux/mainRedux/mainSlice";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../Redux/mainRedux/mainActions";
import ErrorMessage from "../Components/errorMessage";
import WarningMessage from "../Components/warningMessage";
import ConfirmationPopup from "../Components/ConfirmationPopup";
import TimerFooter from "../Components/timerFooter";
import TimerFooterCountup from "../Components/timerFooterCountup";
import PauseTimer from "../Components/pauseTimer";
import AudioPlayer from "../Components/audioPlayer";
import Backdrop from "@mui/material/Backdrop";
import ToggleButton from "../Components/toggleButton";
import ScanScreenSubHeader from "../Components/ScanScreenSubHeader";
import AppButton from "../Components/appButton";
import List from "../Components/list";
import MainScanTile4WithDate from "../Components/mainScanTile4WithDate";
import BottomSlide from "../Components/bottomSlide"
import PickLocationsTile from "../Components/PicklocationsTile"
import HomeButton from "../Components/homeButton";
import Section from "../Components/section";
import SuccessMessage from "../Components/successMessage";
import ScanTwoFields from "../Components/ScanTwoFields";
import { isRequestPending } from "../Redux/mainRedux/mainCrud";
import { hideKeyboard } from '../utility/hideKeyboard';

const ScanScreenType4 = () => {
  const { baseAPIURL, scan4, token, warehouse, sessionStorage, apiSuccess, error, warning, success, progressiveArray } = useSelector(state => state.slice);
  const dispatch = useDispatch();
  const [scanVal, setScanVal] = useState('')
  const [errorOpen, setErrorOpen] = useState(false);
  const [warningOpen, setWarningOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [checkReprintClick, setReprintClick] = useState(false);
  const [checkPopupHolder, setPopupHolder] = useState(null);
  const [scanError, setScanError] = useState(false);
  const [checkSearchClick, setSearchClick] = useState(false);
  const [checkBottomSlider, setBottomSlider] = useState(false);
  const [checkBottomSliderVal, setBottomSliderVal] = useState(null);
  const [checkSuccessBeep, setSuccessBeep] = useState(false);
  const [checkBackButton, setBackButton] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [hideArea, setHideArea] = useState(false);
  const [showExitPopupVal, setExitPopupVal] = useState(false);
  const [transferCustomFields, setTransferCustomFields] = useState({ customReference1: '', customReference2: '' })

  let sound1 = "/Assets/audio/beep90.mp3";
  let sound2 = "/Assets/audio/goodscan.mp3";
  let sound3 = "/Assets/audio/badscan.mp3";
  let scanErrorMessage = {
    'detail': scan4?.scan_error_message ? scan4?.scan_error_message : 'Barcode not recognized. Please scan again properly.'
  }
  const inputRef = useRef();
  const inputRef1 = useRef();
  const scanHandler = (e) => {
    e.preventDefault()
    if ((e.key === "Enter") || e.type === "click") {
      // this code is for progressive search
      // if ((e.key === "Enter" && !scan4?.search_object) || e.type === "click") {
      // hideKeyboard();
      if (scanVal || scan4?.empty_scan && (!scan4?.search_object && !progressiveArray && !Array.isArray(progressiveArray))) {
        setSuccessBeep(false)
        dispatch(slice.actions.setApiSuccess(false))
        let body = { ...scan4.call_back_url_params, scan_value: scanVal, apiSessionStorage: sessionStorage };
        setScanVal(null)
        hideKeyboard();
        inputRef.current.value = "";
        setBackButton(false);
        dispatch(actions.generic_call(baseAPIURL, scan4.callback_url, body, 'post', token));
      } else {
        // hideKeyboard();
        setScanError(true);
        setErrorOpen(true);
      }
    }
  }

  window.scrollEnable = function () {
    setHideArea(true)
  }

  window.scrollDisable = function () {
    setHideArea(false)
  }

  useEffect(() => {
    // if search text & search_object is defined
    if (scanVal && scan4?.search_object) {
      // this code is for progressive search
      // let body = {
      //   scan_value: inputRef.current?.value,
      //   apiSessionStorage: sessionStorage,
      // };
      // if (scan4?.progessive_search?.call_back_url_params) {
      //   for (let [key, value] of Object.entries(scan4?.progessive_search?.call_back_url_params)) {
      //     body[key] = value;
      //   }
      // }
      // dispatch(actions.checkProgressiveSearch(
      //   baseAPIURL,
      //   scan4?.progessive_search?.callback_url,
      //   body, 'post', token
      // ));
      // hideKeyboard();
    } else {
      dispatch(slice.actions.setProgressiveArray(null))
    }
  }, [scanVal]);

  const searchHandler = e => {
    setSearchClick(true)
    setBackButton(true)

  }

  const printCartonLabelsByPickTicketId = () => {
    // let body = { pick_ticket_id: next_pick.pick_ticket_id, apiSessionStorage: sessionStorage }
    // dispatch(actions.generic_call('pickpack/printCartonLabelsByPickTicketId', body, 'post', token));
    setReprintClick(true);
    setConfirmOpen(true);
  }

  // For Error Message
  const errorCloseHandler = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ name: 'hide_keyboard' }))
    }
    setErrorOpen(false);
    if (error?.call_back_url && error?.call_back_url_params) redirectAfterError(error)
    dispatch(slice.actions.clearError());
    setScanError(false);
  };

  const onExitClickHandler = () => {
    setReprintClick(false);
    setConfirmOpen(true);
  }

  const confirmationCloseHandler = () => {
    setConfirmOpen(false);
  };

  const confirmationActionHandler = (data) => {
    setConfirmOpen(false);
    if (data.on_click_url) {
      if (data.title === "No" && data?.popup_message) {
        setPopupHolder(data)
        setErrorOpen(true);
        setTimeout(() => {
          redirectAfterConfirmation(data);
          // setErrorOpen(false);
          // dispatch(slice.actions.clearError());
          // setPopupHolder(null)
        }, 2000);
      } else {
        redirectAfterConfirmation(data);
      }
    }
  };

  const redirectAfterConfirmation = (data) => {
    let body = { ...data.on_click_url_params, apiSessionStorage: sessionStorage };
    dispatch(actions.generic_call(baseAPIURL, data.on_click_url, body, 'post', token));
  }

  // This will only come when there is RLLP is already created or call back URL is comes in error block
  const redirectAfterError = (data) => {
    let body = { ...data.call_back_url_params, apiSessionStorage: sessionStorage };
    dispatch(actions.generic_call(baseAPIURL, data.call_back_url, body, 'post', token));
  }

  useEffect(() => {
    // check error
    if (error) {
      setErrorOpen(true)
      if (error.code === 306) {
        setTimeout(() => {
          redirectAfterSessionExpire();
          localStorage.clear();
          setErrorOpen(false)
          dispatch(slice.actions.clearError());
        }, 2000);
      }
    } else {
      setErrorOpen(false)
    }

    // check success
    if (success) {
      setSuccessOpen(true)
      setSuccessBeep(true)

    } else setSuccessOpen(false)
    // check warning
    if (warning) {
      setWarningOpen(true)
      setTimeout(() => {
        setWarningOpen(false);
        dispatch(slice.actions.clearWarning());
      }, 2000);
    } else {
      setWarningOpen(false)
    }

  }, [error, success, warning]);


  const redirectAfterSessionExpire = () => {
    dispatch(slice.actions.setPage('login'));
  }

  const backClickHandler = () => {
    window.source.cancel('Operation canceled by the user.');
    // body for back Button api call
    let body = {}
    if (checkBackButton) {
      setSearchClick(false)
      setBackButton(false)
    } else if (scan4?.back_button) {
      body['apiSessionStorage'] = sessionStorage;
      if (scan4.back_button.call_back_url_params) {
        for (const [pramkey, pramValue] of Object.entries(scan4.back_button.call_back_url_params)) {
          body[pramkey] = pramValue;
        }
      } else {
        body['identifier'] = scan4?.back_button?.identifier
      }

      dispatch(actions.generic_call(
        scan4?.back_button?.base_url,
        scan4?.back_button?.callback_url,
        body, 'post', token
      ));
    }
  }

  const rowClickHandler = (data) => {
    setSearchClick(false)
    setBackButton(false)
    // check Scan Value change
    if (data && !isRequestPending) {
      setScanVal(data.value)
      setSuccessBeep(false)
      dispatch(slice.actions.setApiSuccess(false))
      let body = {
        ...scan4.call_back_url_params,
        scan_value: data.value || data[scan4.progessive_search.key],
        apiSessionStorage: sessionStorage
      };
      hideKeyboard();
      dispatch(actions.generic_call(baseAPIURL, scan4.callback_url, body, 'post', token));
      dispatch(slice.actions.setProgressiveArray(null));
      setScanVal(null);
      inputRef.current.value = ""
    }
  }

  const getSubmitHandler = (data) => {
    // Api will hit with Date and comments fields data
    let body;
    if (scan4?.date_screen) {
      let currentDate = new Intl.DateTimeFormat(['ban', 'id'], { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date());
      body = { ...data.call_back_url_params, dateReceived: inputRef.current.value ? inputRef.current.value : currentDate, comments: inputRef1.current.value, apiSessionStorage: sessionStorage };
    } else {
      body = {
        ...data.call_back_url_params,
        ...transferCustomFields,
        apiSessionStorage: sessionStorage
      }
      if (inputRef.current) {
        body = { ...data.call_back_url_params, scan_value: inputRef.current.value, apiSessionStorage: sessionStorage };
      }
    }
    console.log(body);
    dispatch(actions.generic_call(baseAPIURL, data.callback_url, body, 'post', token));
    setTransferCustomFields({ customReference1: '', customReference2: '' })
    setBackButton(false);
  }

  const onSubHeaderClick = (e) => {
    if (e.length > 24) {
      setBottomSlider(true)
      setBottomSliderVal(null)
      setBottomSliderVal(e)
    }
  }

  const closeHandle = () => {
    setBottomSlider(false)
  }

  const redirectAfterSuccess = () => {
    setSuccessOpen(false);
    dispatch(slice.actions.clearSuccess());
    let body = { ...success.call_back_url_params, apiSessionStorage: sessionStorage };
    dispatch(actions.generic_call(success.base_url, success.call_back_url, body, 'post', token));
  }

  const exitClickPopup = (val) => {
    setExitPopupVal(val);
  }

  const routeOnExit = (callbackparam, callback_url) => {
    setExitPopupVal(false);
    let body = { ...callbackparam, apiSessionStorage: sessionStorage };
    dispatch(actions.generic_call(baseAPIURL, callback_url, body, 'post', token));
  }

  const onSubHeaderDescClick = (e) => {
    if (e.length > 24) {
      setBottomSlider(true)
      setBottomSliderVal(null)
      setBottomSliderVal(e)
    }
  }

  // remove duplicated values form searchObject
  const removeDuplicates = [
    ...new Map(
      scan4?.search_object?.value.map((m) => [m.value, m]
      ))
      .values()
  ]

  return (
    <>
      <AppHeader
        menuIconVisible={false}
        backIconVisible={scan4?.back_button ? true : (checkBackButton)}
        textVisible={true}
        onBackClick={backClickHandler}
        text={scan4?.title}
        waveDesc={scan4?.description}
        onHeaderDescClick={onSubHeaderDescClick}
        warehouseName={scan4?.warehouse_selector_button_text}
        warehouseEnable={scan4?.warehouse_list?.length > 0 ? true : false}
      />
      {/* Will use when recieve response for sub header */}
      {!scan4?.sub_header &&
        <ScanScreenSubHeader
          title1={scan4?.item?.title}
          value1={scan4?.item?.value}
          title2={scan4?.pallet?.title}
          value2={scan4?.pallet?.value}
          title3={scan4?.lot_title ? scan4?.lot_title?.title : null}
          value3={scan4?.lot_title ? scan4?.lot_title?.value : null}
          onSubHeaderClick={onSubHeaderClick}
          gridLayout={scan4?.gridLayout}
        />
      }

      <div className="cpt-10 cpl-15 cpr-15 cpb-15 backgroundColor-Type5">
        <div className="wfScreen">
          <div className="row">
            {!checkSearchClick &&
              <>
                {scan4?.upper_left &&
                  <div className="col-sm-6 col-xs-6 col-6 cpr-5">
                    <PickLocationsTile text={scan4?.upper_left?.title} value={scan4?.upper_left?.value} bordercolor={scan4?.upper_left?.border_color} bgcolor={scan4?.upper_left?.bg_color} />
                  </div>}
                {scan4?.upper_right &&
                  <div className="col-sm-6 col-xs-6 col-6 cpl-2">
                    <PickLocationsTile text={scan4?.upper_right?.title} value={scan4?.upper_right?.value} bordercolor={scan4?.upper_right?.border_color} bgcolor={scan4?.upper_right?.bg_color} />
                  </div>}
                {scan4?.upper_left_bottom && (
                  <div className="col-sm-6 col-xs-6 col-6 cpr-5 cpt-5">
                    <PickLocationsTile text={scan4?.upper_left_bottom?.title} value={scan4?.upper_left_bottom?.value} />
                  </div>
                )}
                {scan4?.upper_right_bottom && (
                  <div className="col-sm-6 col-xs-6 col-6 cpl-2 cpt-5">
                    <PickLocationsTile text={scan4?.upper_right_bottom?.title} value={scan4?.upper_right_bottom?.value} />
                  </div>
                )}
              </>
            }
            {/* will show when date and comments fields are not required and will also hide when user click on back button of List component */}
            {(!checkSearchClick && !scan4?.date_screen) &&
              <div className="col-sm-12 col-xs-12 col-12">
                {
                  !scan4?.custom_fields ? (
                    <MainScanTile4
                      blueTitle={scan4?.blue_title}
                      unboundedText={scan4?.blue_text}
                      unboundedText1={scan4?.blue_text1}
                      boundedText={scan4?.blue_dotted_text}
                      InputPlaceholder={scan4?.scan_text}
                      InputPlaceholder1={''}
                      inputEl={'ada'}
                      searchoptions={scan4?.search_object}
                      scanicon={scan4?.scan_icon}
                      button_type={scan4?.button_type}
                      onScanHandler={scanHandler}
                      onSearchHandler={searchHandler}
                      scanValue={setScanVal}
                      inputRef={inputRef}
                      onKeyUp={scanHandler}
                      scanText={scanVal}
                      scanScreen={scan4}
                      debounceInput={false}
                      // this code is for progressive search
                      // debounceInput={scan4?.progessive_search ? true : false}
                      onProgressItemClick={rowClickHandler}
                    />
                  ) : (
                    <ScanTwoFields
                      unboundedText={scan4?.blue_text}
                      boundedText={scan4?.blue_dotted_text}
                      Input1Placeholder={scan4?.scan_text}
                      Input2Placeholder={scan4?.scan_text_second}
                      searchoptions={scan4?.search_object}
                      scanicon={scan4?.scan_icon}
                      button_type={scan4?.button_type}
                      scanValue={setScanVal}
                      onKeyUp={scanHandler}
                      scanText={scanVal}
                      onProgressItemClick={rowClickHandler}
                      customFields={transferCustomFields}
                      updateFields={setTransferCustomFields}
                    />
                  )
                }
              </div>
            }
            {(scan4?.framed_footer_box_text) && (scan4?.framed_footer_box_right_justified) &&
              <div className="col-sm-12 col-xs-12 col-12 mt-2">
                <Section left={scan4?.framed_footer_box_text} right={scan4?.framed_footer_box_right_justified} />
              </div>}
            {/* will show when date and comments fields are not required and will show when user click on it's search icon */}
            {(checkSearchClick && !scan4?.date_screen) &&
              <List
                showsearch={false}
                listtitle={scan4?.search_object.title}
                listdata={removeDuplicates}
                onRowClick={rowClickHandler}
                serchLoading={scan4?.search_loading}
                lookupField='scan4'
              />
            }
            {/* will show when date and comments fields are required */}
            {scan4?.date_screen &&
              <div className="col-sm-12 col-xs-12 col-12">
                <MainScanTile4WithDate
                  unboundedText={scan4?.blue_text}
                  DatePlaceholder={'Select Date'}
                  CommentsPlaceholder={scan4?.scan_text}
                  inputRef={inputRef}
                  inputRef1={inputRef1}
                />
              </div>
            }

            {((scan4?.beep_code === 200 && checkSuccessBeep) || (error?.code === 409)) &&
              <AudioPlayer
                sound={scan4?.beep_code === 200 ? sound2 : (error?.code === 409 ? sound3 : '')}
                loop={false}
                muted={false}
              />
            }
          </div>
          {scan4?.mini_exit_btn && hideArea &&
            <div className="d-flex col-sm-6 col-xs-6 cpl-2">
              <div className={"ExitTile backgroundColor-Type3 font-small"} onClick={() => exitClickPopup(true)}>
                <span className="font-medium textColor-Type8">{scan4?.mini_exit_btn?.btn_text}</span>
              </div>
            </div>
          }
        </div>
      </div>
      {
        <BottomSlide open={showExitPopupVal} closeHandle={() => exitClickPopup(false)}>
          <div className="w-100">
            <h5 className="text-center my-4">{scan4?.mini_exit_btn?.blue_text}</h5>
            <div className="d-flex gap-2 p-2">
              <AppButton displayText={scan4?.mini_exit_btn?.btn_option[0].title} onClick={() => routeOnExit(scan4?.mini_exit_btn?.btn_option[0].on_click_url_params, scan4?.mini_exit_btn?.btn_option[0].on_click_url)} />
              <AppButton displayText={scan4?.mini_exit_btn?.btn_option[1].title} onClick={() => exitClickPopup(false)} isOutlined={true} />
            </div>
          </div>
        </BottomSlide>
      }
      <BottomSlide
        children={checkBottomSliderVal}
        open={checkBottomSlider}
        closeHandle={closeHandle}
      />
      <div className="cml-30 cmr-30 col-sm-12 col-xs-12 fixed-bottom index cmb-30">

        {/* Will show once button response recieve from backend */}
        <div className="px-3">
          {(!checkSearchClick && scan4?.complete_button && !hideArea) ?
            <AppButton
              displayText={scan4?.complete_button?.button_text}
              isOutlined={scan4?.complete_button?.isOutlined}
              onClick={() => getSubmitHandler(scan4?.complete_button)}
            /> : null
          }
          {
            scan4?.next_button && !hideArea ? (
              <AppButton
                displayText={scan4?.next_button?.button_text}
                isOutlined={false}
                onClick={() => getSubmitHandler(scan4?.next_button)}
              />
            ) : null
          }
        </div>
      </div>
      {scan4?.allow_home_button && !checkBackButton && !hideArea ? (
        <HomeButton redirectData={scan4?.allow_home_button} />
      ) : null
      }

      {confirmOpen && <ConfirmationPopup open={confirmOpen} onClose={confirmationCloseHandler} data={checkReprintClick ? scan4.reprint_btn : scan4.exit_button_response} actionHandler={confirmationActionHandler} />}
      {errorOpen && <ErrorMessage open={errorOpen} onClose={errorCloseHandler} error={scanError ? scanErrorMessage : (checkPopupHolder ? checkPopupHolder : error)} />}
      {warningOpen && <WarningMessage open={warningOpen} warning={warning} />}
      {successOpen && <SuccessMessage open={successOpen} message={success.popup_message} redirectAfterSuccess={redirectAfterSuccess} />}
    </>
  );
};

export default ScanScreenType4;
