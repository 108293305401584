import { useDispatch, useSelector } from "react-redux";

const AppHeader = (props) => {
  let { pauseStatus, checkCountdown, menuIconVisible, sidebarclick, backIconVisible, textVisible, text, waveDesc, warehouseName, warehouseClick, onChange, onBackClick, warehouseEnable, onHeaderDescClick } = props;
  const { timerdetail, totalTimeSpend } = useSelector(state => state.slice);

  return (
    <div className={totalTimeSpend>timerdetail?.cutoff_1_time && totalTimeSpend<timerdetail?.cutoff_2_time && checkCountdown ? "row p-2 w-100 m-0 headerMain backgroundColor-Type7":(totalTimeSpend>timerdetail?.cutoff_2_time && !pauseStatus && checkCountdown?"row p-2 w-100 m-0 headerMain BG_Flashing":"row p-2 w-100 m-0 headerMain backgroundColor-Type3")}>
      <div style={{ "display": "flex", "flexDirection": "column", "justifyContent": "space-between" }}>
      <div className="headerMain2 gap-4">
        <div className="left">
          <img className={menuIconVisible? "show": "d-none"} src={process.env.PUBLIC_URL + '/Assets/icons/Menu Icon.svg'} onClick={sidebarclick} alt="menu"></img>
          <img className={backIconVisible? "show": "d-none"} src={process.env.PUBLIC_URL + '/Assets/icons/BAck.svg'} onClick={onBackClick} alt="backicon"></img>
          <label style={{"fontSize":text?.length>20 && "12px"}} className={textVisible? "show font-large font-weight-bold": "d-none font-large"}>{text}</label>
        </div>
        { warehouseName &&
          <div className={"cpl-15 cpr-15 cpb-2 warehouse-holder" +(warehouseEnable ? ' enableTile' : ' disableTile')} onClick={onChange} onChange={(e) => warehouseClick(e.target.value)}>
          <img className="cpr-7" src={process.env.PUBLIC_URL + '/Assets/icons/swap.svg'} alt="swapicon"></img>
          <label style={{"fontSize":warehouseName?.length>10 && "10px"}} className="warehouse">{warehouseName}</label>
          </div>
        }
        
      </div>
      {waveDesc &&
            <div style={{ "width": "100%"}}>
             <div style={{ "display": "flex", "justifyContent": "space-between", "alignItems": "center" }}>
           <div className="cmr-5 font-large font-weight-bold mb-0 wrapText" style={{ "width": "100%" }}>{waveDesc.title}
           <span className=" cml-5 font-large font-weight-bold" onClick={(e) => { onHeaderDescClick(waveDesc.value) }}>
            {waveDesc.value}
            </span>
            </div>
           </div>
            </div>
            }
      </div>

    </div>
  );
};

export default AppHeader;
