import Box from '@mui/material/Box';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import { useEffect, useState } from 'react';

// Inspired by the former Facebook spinners.
export default function CustomizedProgressBars({ value, complete }) {
  return (
    <Box sx={{ position: 'relative' }}>
      {/* this will only show when the remaining is 0 there picking is compeleted */}
      {complete === 0 ? (
        <img
          style={{ position: "absolute", top: "7px", left: "7px" }}
          src={process.env.PUBLIC_URL + '/Assets/icons/Vector.png'}
          alt="line"
        />
      ) : null}

      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) => theme.palette.grey[300],
        }}
        size={30}
        thickness={4}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        disableShrink
        sx={{
          color: "#48D2A0",
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={30}
        thickness={4}
        value={value}
      />
    </Box>
  );
}
