import React, { useEffect, useState, useRef } from "react";
import AppHeader from "../Components/appHeader";
import ScanScreenSubHeader from "../Components/ScanScreenSubHeader";
import TimerFooter from "../Components/timerFooter";
import EnterQuantityTile from "../Components/enterQuantityTile";
import PickLocationsTile from "../Components/PicklocationsTile";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../Redux/mainRedux/mainActions";
import { slice } from "../Redux/mainRedux/mainSlice";
import ErrorMessage from "../Components/errorMessage";
import DroppedPackagesList from "../Components/droppedPackagesList";
import AppButton from "../Components/appButton";
import BottomSlide from "../Components/bottomSlide";
import { hideKeyboard } from '../utility/hideKeyboard';

const DropPackage = () => {
  const { baseAPIURL, drop, token, sessionStorage, apiSuccess, error, warning, success, keyboardStatus, userType } = useSelector(state => state.slice);
  const dispatch = useDispatch();
  const [scanVal, setScanVal] = useState(null)
  const [errorOpen, setErrorOpen] = useState(false);
  const [warningOpen, setWarningOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [scanError, setScanError] = useState(false);
  const [hideArea, setHideArea] = useState(true)
  const [checkPopupHolder, setPopupHolder] = useState(null);
  const [checkPackageList, setPackageList] = useState(false)
  const [showExitPopupVal, setExitPopupVal] = useState(false);
  const [checkBottomSlider, setBottomSlider] = useState(false);
  const [checkBottomSliderVal, setBottomSliderVal] = useState(null);

  let scanErrorMessage = {
    'detail': 'Barcode not recognized. Please scan again properly.'
  }

  const scanHandler = e => {
    e.preventDefault()
    if (e.key === "Enter" || e.type === "click") {
      hideKeyboard();
      if (inputRef1.current.value && inputRef2.current.value) {
        dispatch(slice.actions.setApiSuccess(false))
        let body = { ...drop.call_back_url_params, totalPalletCount: inputRef1.current.value, totalCartonCount: inputRef2.current.value, apiSessionStorage: sessionStorage };
        inputRef1.current.value = "";
        inputRef2.current.value = "";
        // inputRef.current.focus();
        // window.ReactNativeWebView.postMessage(JSON.stringify({ name: 'hide_keyboard' }))
        hideKeyboard();
        dispatch(actions.generic_call(baseAPIURL, drop.call_back_url, body, 'post', token));
      } else {
        hideKeyboard();
        setScanError(true);
        setErrorOpen(true);
      }
    }
  }
  const inputRef1 = useRef();
  const inputRef2 = useRef();
  // useEffect(() => {
  //   inputRef.current.focus();
  window.scrollEnable = function () {
    setHideArea(false)
  }
  window.scrollDisable = function () {
    setHideArea(true)
  }

  // For Error Message
  const errorCloseHandler = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ name: 'hide_keyboard' }))
    }
    if (error.call_back_url && error.call_back_url_params) redirectAfterError(error)
    dispatch(slice.actions.clearError());
  };
  // This will only come when there is RLLP is already created or call back URL is comes in error block
  const redirectAfterError = (data) => {
    let body = { ...data.call_back_url_params, apiSessionStorage: sessionStorage };
    dispatch(actions.generic_call(baseAPIURL, data.call_back_url, body, 'post', token));
  }

  // On selecting drop package list
  const onClickDropPackageList = () => {
    setPackageList(true)
  }

  const exitClickPopup = (val) => {
    setExitPopupVal(val);
  }

  const routeOnExit = (callbackparam, callback_url) => {
    setExitPopupVal(false);
    let body = { ...callbackparam, apiSessionStorage: sessionStorage };
    dispatch(actions.generic_call(baseAPIURL, callback_url, body, 'post', token));
  }

  const onSubHeaderDescClick = (e) => {
    if (e.length > 24) {
      setBottomSlider(true)
      setBottomSliderVal(null)
      setBottomSliderVal(e)
    }
  }

  const closeHandle = () => {
    setBottomSlider(false)
  }

  return (
    <>
      {!checkPackageList &&
        <><AppHeader
          menuIconVisible={false}
          backIconVisible={false}
          textVisible={true}
          text={drop.title}
          waveDesc={drop?.description}
          onHeaderDescClick={onSubHeaderDescClick}
          warehouseName={drop?.warehouse_selector_button_text}
          warehouseEnable={drop?.warehouse_list?.length > 0 ? true : false}
        />
          <ScanScreenSubHeader
            title1={drop?.item?.title}
            value1={drop?.item?.value}
            title2={drop?.pallet?.title}
            value2={drop?.pallet?.value}
            remainValue={{ total: drop?.qty_requested_pick, remaining: drop?.qty_remaining }}
            remainText={drop?.remaining_text} /><div className="cpt-10 cpl-15 cpr-15 cpb-15 backgroundColor-Type5 mainPageType2">
            <div className="wfScreen">
              <div className="row">
                {drop.upper_left &&
                  <div className="col-sm-6 col-xs-6 col-6 cpr-5">
                    <PickLocationsTile text={drop.upper_left.title} value={drop.upper_left.value} />
                  </div>}
                {drop.upper_right &&
                  <div className="col-sm-6 col-xs-6 col-6 cpl-2">
                    <PickLocationsTile text={drop.upper_right.title} value={drop.upper_right.value} />
                  </div>}
                <div className="col-sm-12 col-xs-12 col-12">
                  <EnterQuantityTile
                    unboundedText1={drop.blue_text}
                    unboundedText={drop.blue_dotted_text_first}
                    boundedText=""
                    InputPlaceholder={drop.scan_text.first_scan_text}
                    InputPlaceholder1={drop.scan_text.second_scan_text}
                    onScanHandler={scanHandler}
                    scanValue={setScanVal}
                    inputRef1={inputRef1}
                    inputRef2={inputRef2}
                    onKeyUp={scanHandler}
                    scanText={scanVal} />
                </div>
              </div>
              {drop?.mini_exit_btn && hideArea &&
                <div className="d-flex col-sm-6 col-xs-6 cpl-2">
                  <div className={"ExitTile backgroundColor-Type3 font-small"} onClick={() => exitClickPopup(true)}>
                    <span className="font-medium textColor-Type8">{drop?.mini_exit_btn?.btn_text}</span>
                  </div>
                </div>
              }
            </div>
          </div>
          {
            <BottomSlide open={showExitPopupVal} closeHandle={() => exitClickPopup(false)}>
              <div className="w-100">
                <h5 className="text-center my-4">{drop?.mini_exit_btn?.blue_text}</h5>
                <div className="d-flex gap-2 p-2">
                  <AppButton displayText={drop?.mini_exit_btn?.btn_option[0].title} onClick={() => routeOnExit(drop?.mini_exit_btn?.btn_option[0].on_click_url_params, drop?.mini_exit_btn?.btn_option[0].on_click_url)} />
                  <AppButton displayText={drop?.mini_exit_btn?.btn_option[1].title} onClick={() => exitClickPopup(false)} isOutlined={true} />
                </div>
              </div>
            </BottomSlide>
          }
          <BottomSlide
            children={checkBottomSliderVal}
            open={checkBottomSlider}
            closeHandle={closeHandle}
          />
          {drop?.dropPackageList &&
            <div className="row justify-content-center footer fixed-bottom cmb-20 cml-0">
              <div className={" d-flex align-items-center justify-content-center cpt-10 cpb-10 col-6 dropped-pkg-holder enableTile"} onClick={() => onClickDropPackageList()}>
                <img className="cpr-7" src={process.env.PUBLIC_URL + '/Assets/icons/Drop.png'} alt="swapicon"></img>
                <label className="drop-title">{drop?.dropPackageList?.title}</label>
              </div>
            </div>}
        </>
      }

      {error && (
        <ErrorMessage
          open={error !== null}
          onClose={errorCloseHandler}
          error={error}
        />
      )}

      {checkPackageList &&
        <DroppedPackagesList
          setPackageList={setPackageList}
          dropPackageData={drop?.dropPackageList?.detail}
        />
      }
    </>
  );
};
export default DropPackage;