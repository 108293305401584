import Slide from '@mui/material/Slide';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';

// close handle should function pased.
export function BottomSheet({ children, open = false, closeHandle, headerText, openHandle }) {
   // const [isOpen, setIsOpen] = useState(open);

   return (
      <>
         <Fade in={open} onClick={() => closeHandle(false)}>
            <div className="sheet-overlay"></div>
         </Fade>
         <Slide direction="up" in={open} mountOnEnter unmountOnExit>
            <footer id="bottom-sheet">
               {headerText ? (
                  <header className='border-bottom pb-3 font-large2'>
                     <span className='notch'></span>
                     <p className='m-0'>{headerText}</p>
                  </header>
               ) : null}
               {children}
            </footer>
         </Slide>
      </>
   );
}