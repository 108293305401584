import Slide from '@mui/material/Slide';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';

// close handle should function pased.
export default function BottomSlide({ children, open = false, closeHandle, openHandle }) {
   // const [isOpen, setIsOpen] = useState(open);

   return (
      <>
         <Fade in={open} onClick={() => closeHandle(false)}>
            <div className="sheet-overlay"></div>
         </Fade>
         <Slide direction="up" in={open} mountOnEnter unmountOnExit>
            <footer id="bottom-slide" className='font-large2'>
               <div className='col-12 alignEnd'>
                  <div><CloseIcon onClick={() => closeHandle(false)} /></div>
               </div>
               {children}
            </footer>
         </Slide>
      </>
   );
}