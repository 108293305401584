import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { slice } from "../Redux/mainRedux/mainSlice";
import AppHeader from "./appHeader";
import SideBar from "./sideBar";

import * as actions from "../Redux/mainRedux/mainActions";

const AccountSelectionScreen = () => {
    const { baseAPIURL, menuResponse, home, userCredentials } = useSelector(state => state.slice);
    const [showBackdrop, setShowBackdrop] = useState(false);
    const [showSidebar, setShowSidebar] = useState(false);
    const dispatch = useDispatch();

    const openSidebar = () => {
        setShowSidebar(true);
        setShowBackdrop(true);
    }

    const onAccountClick = (account) => {
        let body = { 
            username: userCredentials.userValue.value,
            password: userCredentials.passwordValue.value,
            environment: account.environment_id,
        };
        dispatch(actions.generic_call(baseAPIURL, 'setEnvironment', body, 'post', null, 'clients'));
        dispatch(slice.actions.setPage('clientSelection'));
    }

    return (
        <div>
            <div style={{ overflowY: "scroll", height: "100vh" }}>
                <SideBar
                    menuResponsedata={menuResponse}
                    sidebarstatus={showSidebar}
                    sidebarclick={setShowSidebar}
                    setBackdropClose={setShowBackdrop}
                    setSidebarClose={showBackdrop}
                />
                <AppHeader
                    menuIconVisible={false}
                    backIconVisible={false}
                    sidebarclick={openSidebar}
                    textVisible={true}
                    text="Select Account"
                    onBackClick={() => { }}
                />
                <table className="table" style={{ overflow: "scroll"}} >
                    <thead className="font-large font-large">
                        <tr className="textalignCenter">
                            <th className="tableColumn textalignCenter cpt-15 cpb-15">Account Selection</th>
                        </tr>
                    </thead>

                    <tbody>
                        {home && home?.environment.map((item, index) => (
                            <tr className="textalignCenter" key={index}>
                                <td className="tableColumn cpt-10 cpb-10" onClick={() => onAccountClick(item)}>{item.description ? item.description : '-'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default AccountSelectionScreen;