import { Logout } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as actions from "../Redux/mainRedux/mainActions";
import { slice } from "../Redux/mainRedux/mainSlice";

const SideBar = ({ menuResponsedata, sidebarstatus, sidebarclick, setBackdropClose, setSidebarClose }) => {
  const { baseAPIURL, menuResponse, token, sessionStorage } = useSelector(state => state.slice);
  const dispatch = useDispatch();

  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => {
    setSidebar(false)
    sidebarclick(false)
    setBackdropClose(false)
  }

  useEffect(() => {
    sidebarstatus && setSidebar(true)
    if (!setSidebarClose) {
      setSidebar(false)
      sidebarclick(false)
      setBackdropClose(false)
    }
  }, [sidebarstatus, setSidebarClose])


  const onClickHandler = (display_text, callback_url, identifier, disabled) => {
        // for setting screen 
    if(display_text==="Settings")
    {
      dispatch(slice.actions.setPage(display_text))
    }else{
      if(!disabled){
        let body = { 
          identifier: (identifier ? identifier.toLowerCase() : display_text.toLowerCase()), 
          apiSessionStorage: sessionStorage }
        dispatch(actions.generic_call(baseAPIURL, callback_url, body, 'post', token));
      }
    } 
    setSidebar(false);
    window.handleClose();
  }

  var getNameInitials = function (string) {
   if(string)
   {
    var names = string?.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
   }

  }

  return <>
    {/* <IconContext.Provider value={{ color: "#FFF" }}> */}
    {/* All the icons now are white */}
    {/* <div className="navbar">
      </div> */}
    <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
      <ul className="nav-menu-items">
        <li className="navbar-toggle">
          <div className="menu-bars">
            <img src={process.env.PUBLIC_URL + '/Assets/icons/Cross.svg'} onClick={showSidebar} alt="cross"></img>
          </div>
          <div className="menu-subbars cmt-10">
            <div className="menu-avatar cmb-15 ">
              {/* <img src={process.env.PUBLIC_URL + '/Assets/icons/221.png'} alt="cross"></img> */}
              <label>{getNameInitials(menuResponsedata?.profile_info?.first_name)}</label>
            </div>
            <label className="title">{menuResponsedata?.profile_info?.first_name}</label>
            <label className="detail">{menuResponsedata?.profile_info?.role}</label>
          </div>
        </li>

        {menuResponsedata.first_tile.map(({ display_text, callback_url, identifier, disabled}) => (
          <li key={display_text} className={disabled ? "disableTile nav-text" : "nav-text"} onClick={() => onClickHandler(display_text, callback_url, identifier, disabled)}>
            <span>{display_text}</span>
          </li>
        ))}
        <div className="menu_bd cmt-10 cmb-10"></div>
        {menuResponsedata.second_tile.map(({ display_text, callback_url, identifier, disabled}) => (
          <li key={display_text} className={disabled ? "disableTile nav-text" : "nav-text"} onClick={() => onClickHandler(display_text, callback_url, identifier, disabled)}>
            <span>{display_text}</span>
          </li>
        ))}

        <li className="navbar-toggle">
          <div className="menu-bars logout cmb-30" onClick={() => onClickHandler(menuResponsedata.logout.display_text, menuResponsedata.logout.callback_url, menuResponsedata.logout.identifier)}>
            <img src={process.env.PUBLIC_URL + '/Assets/icons/power.svg'} alt="power"></img>
            <span> Log Out</span>
          </div>
        </li>
      </ul>
    </nav>
    {/* </IconContext.Provider> */}
  </>

}

export default SideBar;