const customStyle = {
  padding: '75px 10px'
}

const ConfirmationTile = ({
  unboundedText,
  buttonOptions,
  onSelectionHandler,
  SelectionValue,
}) => {
  return (
    <div className="backgroundColor-Type3 mainScanTile">
      <div className={`backgroundColor-Gradient uperScaner-div`} style={customStyle}>
        <div className="uperSD-Text font-large2">{unboundedText}</div>
      </div>
      <ConfirmationButtons
        buttonOptions={buttonOptions}
        onSelectionHandler={onSelectionHandler}
        SelectionValue={SelectionValue}
      />
    </div>
  );
};

export default ConfirmationTile;

const ConfirmationButtons = ({ buttonOptions, onSelectionHandler }) => {
  return (
    <div className="row g-1 py-4 px-3">
      {buttonOptions.map(({ title, on_click_url, on_click_url_params, popup_message }) => (
        <div className="col-sm-6 col-xs-6 col-6" key={title}>
          <input
            type="button"
            className={title === "Yes" ? "formsubmit backgroundColor-Gradient font-large" : "formsubmit font-large confirmNoBtn"}
            value={title}
            onClick={() => onSelectionHandler({ title, on_click_url, on_click_url_params, popup_message })}
          />
        </div>
      ))}
    </div>
  );
};
