import * as requestFromServer from "./mainCrud";
import { slice, V2_BASE_URL, setValue } from "./mainSlice";

// This api call will use where we don't want our page to rerender from api response
export const without_PageSetting = (baseURL, url, body, method, token, action) => dispatch => {
    dispatch(slice.actions.setIsLoading(true))
    return requestFromServer
        .Call_API(baseURL, url, body, method, token)
        .then(response => {
            // Storing API Session from response to variable
            const apiSession = response.data?.apiSessionStorage;
            // Storing API Session from response
            dispatch(slice.actions.setSessionStorage(apiSession));
            // don't need to set any response 
            dispatch(slice.actions.setIsLoading(false))
        })
        .catch(error => {
            dispatch(slice.actions.setIsLoading(false))
            if (error && error.code == 'ERR_NETWORK') {
                dispatch(slice.actions.setError({ variable: `${action}Error`, error: { detail: error.message } }));
            } else {
                dispatch(slice.actions.setError({ variable: `${action}Error`, error: error.response.data.error }));
            }
        });
}

export const checkProgressiveSearch = (baseURL, url, body, method, token, action) => dispatch => {
    dispatch(slice.actions.setProgressiveLoading(true))
    return requestFromServer
        .Call_API(baseURL, url, body, method, token)
        ?.then(response => {
            // empting the progressive serach array 
            dispatch(slice.actions.setProgressiveArray([]))
            // set Search option array
            dispatch(slice.actions.setProgressiveArray(response.data.data))
            dispatch(slice.actions.setProgressiveLoading(false));
        })
        .catch(error => {
            dispatch(slice.actions.setProgressiveLoading(false));
            if (error && error.code == 'ERR_NETWORK') {
                dispatch(slice.actions.setError({ variable: `${action}Error`, error: { detail: error.message } }));
            } else {
                dispatch(slice.actions.setError({ variable: `${action}Error`, error: error.response.data.error }));
            }
        });
}

export const updateLookup = (url, calback, body, method, token, field, type) => dispatch => {
    type === 'search' ? dispatch(slice.actions.setIsLoading(true)) : window.setProgressiveLoading(true);
    return requestFromServer
        .Call_API(url, calback, body, method, token)
        ?.then(response => {
            // set Search option array
            dispatch(slice.actions.updateLookupList({
                type,
                field,
                data: response.data.data.search_object.value
            }))
            dispatch(slice.actions.setProgressiveLoading(false))
            dispatch(slice.actions.setIsLoading(false))
            window.setProgressiveLoading(false)
        })
        .catch(error => {
            dispatch(slice.actions.setProgressiveLoading(false))
            dispatch(slice.actions.setIsLoading(false))
            window.setProgressiveLoading(false)
            dispatch(slice.actions.updateLookupList({
                type,
                field,
                data: []
            }))
        });
}

export const vlidateTOTP = (body, method, token, action) => dispatch => {
    const totpPayload = { code: body.code, username: body.userCredentials.userValue.value };
    const loginPayload = {
        username: body.userCredentials.userValue.value,
        password: body.userCredentials.passwordValue.value
    }
    return requestFromServer
        .Call_API(null, 'validateTotp', totpPayload, method, token)
        .then(response => {
            // console.log(userCredentials);
            const { data } = response.data;
            // if totp is not valid
            if (data.isValid) {
                dispatch(slice.actions.clearError());
                // console.log(body.userCredentials);
                // dispatch(generic_call('login', loginPayload, 'post', null, 'home'));
                dispatch(slice.actions.setPage('accountSelection'))
            } else {
                dispatch(slice.actions.setError({ variable: `${action}Error`, error: { etadil: { ...response.data.data } } }));
            }
        })
        .catch(error => {
            if (error && error.code == 'ERR_NETWORK') {
                dispatch(slice.actions.setError({ variable: `${action}Error`, error: { detail: error.message } }));
            } else {
                dispatch(slice.actions.setError({ variable: `${action}Error`, error: error.response.data.error }));
            }
        });
}

export const checkUserSession = (url, body, method, token, action) => dispatch => {
    dispatch(slice.actions.setIsLoading(true))
    return requestFromServer
        .Call_API(url, body, method, token)
        .then(response => {
            if (response.data.data.attributes.action === "home_btn") {
                dispatch(slice.actions.setHomeBtn(response.data.data.attributes));
            }
            // dispatch(slice.actions.setHomeBtn())
            if (response && response.data.data.attributes.status !== "rejected") {
                // this will close the iframe by resetting the url to base url.
                dispatch(slice.actions.setIframeURL(V2_BASE_URL));
            } else {
                dispatch(slice.actions.setShowLockUserPopup(true));
                setTimeout(() => {
                    dispatch(slice.actions.setShowLockUserPopup(false));
                }, 2000)
            }
            dispatch(slice.actions.setIsLoading(false));
        })
        .catch(error => {
            dispatch(slice.actions.setIsLoading(false));
            if (error && error.code == 'ERR_NETWORK') {
                dispatch(slice.actions.setError({ variable: `${action}Error`, error: { detail: error.message } }));
            } else {
                dispatch(slice.actions.setError({ variable: `${action}Error`, error: error.response.data.error }));
            }
        });
}

export const generic_call = (baseURL, url, body, method, token, action) => dispatch => {
    dispatch(slice.actions.setIsLoading(true))
    // clearing progressive array
    dispatch(slice.actions.setProgressiveArray(null));
    return requestFromServer
        .Call_API(baseURL, url, body, method, token)
        ?.then(response => {
            // sending network data to hardware app.
            console.log(action, window.ReactNativeWebView);
            if (window.ReactNativeWebView && action && action !== 'tile2' && action !== 'warehouseChange') {
                window.ReactNativeWebView.postMessage(JSON.stringify(response.data));
            }
            const data = response.data?.data;
            // Storing API Session from response to variable
            const apiSession = response.data?.apiSessionStorage;
            // Storing base API URL
            response.data?.base_url && dispatch(slice.actions.setBaseAPIURL(response.data?.base_url));
            // Storing base URL for V2 links
            response.data?.v2_link && setValue(response.data?.v2_link)
            // Storing token from response to variable
            // url === "login" && dispatch(slice.actions.setToken(response.data?.token));
            if (url === "login" || url === "setEnvironment") {
                dispatch(slice.actions.setToken(response.data?.token));
            }

            // Clear Error message
            dispatch(slice.actions.clearError())
            // Storing API Data from response
            dispatch(slice.actions.setResponse({ variable: action ? action : data?.attributes?.action, response: data?.attributes }));
            data.menu_block && dispatch(slice.actions.setMenuResponse(data?.menu_block));
            data?.sourceOfRecord && dispatch(slice.actions.setUserType(data?.sourceOfRecord));

            // clearing progressive array
            dispatch(slice.actions.setProgressiveArray(null));
            dispatch(slice.actions.setSourceOfRecord(data.sourceOfRecord));

            if (data.sourceOfRecord === 'v2') {
                // Storing Menu Response
                data?.page && dispatch(slice.actions.setPage(data?.page));
            } else if (data.attributes?.mfa_response?.status === 'success' && data.attributes?.mfa_response?.mfa_type === 'TOTP') {
                dispatch(slice.actions.setPage('TwoFactorAuth'));
                dispatch(slice.actions.setTotpData(response.data));
                // dispatch(checkMFASettings('checkMFASettings', body, method, null, 'home'));
            } else if (data.sourceOfRecord === 'v1' && !data.attributes?.clients) {
                // Storing Menu Response
                data?.page && dispatch(slice.actions.setPage(data?.page));

            }
            // Storing screen name from response for routing 
            data?.page && storedata(data?.page, baseURL, url, token, body?.identifier, body?.apiSessionStorage);
            // Storing warning data if receive 
            data?.attributes?.warning_block && dispatch(slice.actions.setWarning(data?.attributes?.warning_block));
            // Storing API Session from response
            dispatch(slice.actions.setSessionStorage(apiSession));
            // Storing warehouse data from response
            data.attributes?.warehouse_selector_button_text && dispatch(slice.actions.setWarehouse(data.attributes?.warehouse_selector_button_text));
            // Storing Complete Timer data from response
            //       console.log('rftimer',data.attributes?.rfTimerValues);
            dispatch(slice.actions.setTimerDetail(data?.attributes?.rfTimerValues));
            data?.attributes?.rfTimerValues && dispatch(slice.actions.setApiSuccess(true));
            // Storing Timer data from response
            let time = new Date();
            time = time.setSeconds(time.getSeconds() + data?.attributes?.rfTimerValues?.timer_value);
            // time = time.setSeconds(time.getSeconds() + 5 );
            dispatch(slice.actions.setTimer(time));
            dispatch(slice.actions.setIsLoading(false));
            if (response && response.data.data.attributes.status !== "rejected") {
                dispatch(slice.actions.setIframeURL(V2_BASE_URL));
            } else {
                dispatch(slice.actions.setShowLockUserPopup(true));
                setTimeout(() => {
                    dispatch(slice.actions.setShowLockUserPopup(false));
                }, 2000)
            }
        })
        .catch(error => {
            dispatch(slice.actions.setIsLoading(false))
            if (error && error.code == 'ERR_NETWORK') {
                dispatch(slice.actions.setError({ variable: `${action}Error`, error: { detail: error.message } }));
            } else {
                dispatch(slice.actions.setError({ variable: `${action}Error`, error: error.response.data.error }));
            }
        });
};

const storedata = (currentPage, currentBaseURL, currentApi, token, identifier, session) => {
    localStorage.setItem("CurrentPage", currentPage);
    localStorage.setItem("CurrentBaseURL", currentBaseURL);
    localStorage.setItem("CurrentApi", currentApi);
    localStorage.setItem("Currenttoken", token);
    localStorage.setItem("Currentidentifier", identifier);
    localStorage.setItem("CurrentSession", session);
    // sessionStorage.setItem("CurrentPayload", currentPayload);
}
