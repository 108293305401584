import React, { useEffect } from "react";
import Dialog from '@mui/material/Dialog';
import ConfirmationTile from "../Components/ConfirmationTile";

const ConfirmationPopup = (props) => {
  const { open, onClose, data, actionHandler } = props;

  useEffect(() => {
    return () => {
      const inputs = document.querySelectorAll('input');
      inputs.forEach((input) => input.focus());
    }
  }, []);

  return (
    <Dialog className="dialog" onClose={onClose} open={open}>
      <ConfirmationTile
        unboundedText={data.blue_text}
        buttonOptions={data.btn_option}
        onSelectionHandler={(data) => actionHandler(data)}
      />
    </Dialog>

  )
};
export default ConfirmationPopup;