import React, { useEffect, useState, useRef } from "react";
import AppHeader from "../Components/appHeader";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../Redux/mainRedux/mainActions";
import List from "../Components/list";
import HomeButton from "../Components/homeButton";
import AppButton from "../Components/appButton";
import { BottomSheet } from "../Components/BottomSheet";

const Inquiry = () => {
  const { baseAPIURL, inquiry, token, sessionStorage } = useSelector(state => state.slice);
  const [search, setSearch] = useState('');
  const [checkBottomSliderVal, setBottomSliderVal] = useState(null);
  const [openBottomSlider, setOpenBottomSlider] = useState(false);
  const [hideArea, setHideArea] = useState(false);
  const inputRef = useRef();
  const initialMount = useRef(true);

  const dispatch = useDispatch();

  useEffect(() => {
    if (initialMount.current) {
      initialMount.current = false;
    } else {
      let body = {
        value: search,
        apiSessionStorage: sessionStorage,
        is_json: true,
        ...inquiry.call_back_url_params,
        ...(inquiry.search_loading && inquiry.search_loading.call_back_url_params),
      }

      if (search) {
        dispatch(actions.updateLookup(
          baseAPIURL,
          inquiry?.search_loading.callback_url,
          body, 'post', token, 'inquiry', 'search'
        ));
      } else {
        body['value'] = null
        dispatch(actions.updateLookup(
          baseAPIURL,
          inquiry?.search_loading.callback_url,
          body, 'post', token, 'inquiry', 'search'
        ));
      }
    }
  }, [search]);

  window.scrollEnable = function () {
    setHideArea(true)
  }

  window.scrollDisable = function () {
    setHideArea(false)
  }

  const onInfoClick = info => {
    console.log(info);
    if (info && info?.info.call_back_url_params) {
      let body = {
        ...info.info.call_back_url_params,
        apiSessionStorage: sessionStorage
      };
      dispatch(actions.generic_call(baseAPIURL, info.info.callback_url, body, 'post', token));
      inputRef.current.value = ""
    }
  }

  const onRowClick = rowItem => {
    console.log(rowItem);
    if (rowItem && inquiry?.searchable) {
      let body = {
        apiSessionStorage: sessionStorage,
        ...rowItem.call_back_url_params,
      };

      dispatch(actions.generic_call(
        baseAPIURL,
        rowItem.callback_url || inquiry.callback_url,
        body, 'post', token
      ));
      inputRef.current.value = ""
    }
  }

  const backClickHandler = () => {
    let body = {}
    // body for back Button api call
    if (inquiry?.back_button) {
      body['apiSessionStorage'] = sessionStorage;
      if (inquiry.back_button.call_back_url_params) {
        for (const [pramkey, pramValue] of Object.entries(inquiry.back_button.call_back_url_params)) {
          body[pramkey] = pramValue;
        }
      } else {
        body['identifier'] = inquiry?.back_button?.identifier
      }

      dispatch(actions.generic_call(
        inquiry?.back_button?.base_url,
        inquiry?.back_button?.callback_url,
        body, 'post', token
      ));
    }
  }

  const submitHandler = (payload) => {
    console.log(payload);
    setOpenBottomSlider(true)
    setBottomSliderVal(null)
    setBottomSliderVal(payload.options)
  }

  const closeBottomSheet = () => {
    setOpenBottomSlider(false)
  }

  const uploadDocument = (payload) => {
    console.log(payload);
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView
        .postMessage(JSON.stringify({
          documentUpload: payload
        }))
    }
  }

  window.sendFile = function (file) {
    // file should be in base 64.
    console.log(file);
    if (inquiry?.complete_button) {
      const body = {
        ...inquiry?.complete_button?.call_back_url_params,
        apiSessionStorage: sessionStorage,
        base64String: file // base 64 string,
      };
      dispatch(actions.generic_call(
        baseAPIURL,
        inquiry?.complete_button.callback_url,
        body,
        'post',
        token
      ));
      setBottomSliderVal(false)
    }
  }

  return (
    <div ref={initialMount}>
      <AppHeader
        menuIconVisible={false}
        backIconVisible={inquiry?.back_button}
        textVisible={true}
        onBackClick={backClickHandler}
        text={inquiry?.title}
      />
      <List
        showsearch={inquiry?.searchable}
        listtitle={inquiry?.search_object.title}
        listdata={inquiry?.search_object.value}
        setSearchValue={setSearch}
        inputref={inputRef}
        ondetailclick={onInfoClick}
        onRowClick={onRowClick}
        serchLoading={inquiry?.search_loading}
        lookupField='inquiry'
      />

      {inquiry?.complete_button ? (
        <div className="mx-5">
          <AppButton
            displayText={inquiry?.complete_button?.button_text}
            isOutlined={false}
            onClick={() => submitHandler(inquiry?.complete_button)}
          />
        </div>
      ) : null}

      {checkBottomSliderVal ? (
        <BottomSheet headerText="Select Information" open={openBottomSlider} closeHandle={closeBottomSheet}>
          {checkBottomSliderVal.map((option, i) => (
            <div
              key={i}
              className="border-bottom py-2 fw-normal"
              onClick={() => uploadDocument(option)}
            >
              {option}
            </div>
          ))}
        </BottomSheet>
      ) : null}

      {inquiry?.allow_home_button && !hideArea ? (
        <HomeButton redirectData={inquiry?.allow_home_button} />
      ) : null}
    </div>
  );
};
export default Inquiry;