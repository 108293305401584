import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const ScanValidateTileWithCondition = ({ palletQtyAndLocation, unboundedText, boundedText, button_type, InputPlaceholder, DefaultValue, onScanHandler, scanValue, dropoptions, throw_type, inputRef, text1, count, setWarehouseSelection }) => {
    const { keyboardType } = useSelector(state => state.slice);
    const [showText, setShowText] = useState(false);
    const [inputMode, setInputMode] = useState('none');
    const [selectedItem, setselectedItem] = useState({
        name: dropoptions[0].title,
        color: dropoptions[0].color
    });

    const onClick = () => {
        setShowText(!showText);
    }

    function itemSelection(title, color, call_back_url, call_back_url_params) {
        setShowText(!showText);
        setselectedItem({ name: title, color: color });
        callOptionUrl(call_back_url, call_back_url_params);
    }

    useEffect(() => {
        setShowText(true)
        setTimeout(() => {
            setShowText(false)
        }, 10);

    }, [DefaultValue])

    useEffect(() => setInputMode('none'), [unboundedText, boundedText, InputPlaceholder])

    // Hit Api for selected option
    function callOptionUrl(callback, callbackparam) {
        console.log('options selected', callback, callbackparam);
        let warehousedata = {
            call_back_url: callback,
            call_back_url_params: callbackparam
        }
        console.log('warehouse', warehousedata);
        setWarehouseSelection(warehousedata)

        // let body = { identifier: callbackparam.identifier, put_id: callbackparam.put_id, pick_ticket_id: callbackparam.pick_ticket_id, apiSessionStorage: sessionStorage };
        // dispatch(actions.generic_call(callback, body, 'post', token));
    }
    //calling when tiles rerender
    useEffect(() => {
        if(!DefaultValue) {
            hideKeyboardHandler()
        }
        // reset dropdown value
        setselectedItem({
            name: dropoptions[0].title,
            color: dropoptions[0].color
        });
    }, [unboundedText, boundedText, throw_type, count])
    const hideKeyboardHandler = () => {
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ name: 'hide_keyboard_on' }))
        }
    }

    return (
        <div className="cmt-10 backgroundColor-Type3 mainScanTile">
            <div className={"backgroundColor-Gradient" + (palletQtyAndLocation ? ' uperScaner-div2' : ' uperScaner-div-3')}>
                {/* in the below line where {unboundedText} is placed cmb-17 class
                should appear if there is selectbox otherwise this class cmb-38 should appear */}
                <div className="uperSD-Text-conditionSC font-small pick-locations-title-1 w-100 cmb-17">{unboundedText}</div>
                <div className="d-flex justify-content-between align-items-center w-100 cpl-12 cpr-12 cmb-17">
                    <div className="d-block">
                        <div className="textColor-Type2 font-small17">{boundedText}</div>
                        <div className="textColor-Type2 font-small17">{text1}</div>
                    </div>
                    <div className="backgroundColor-Type6 border-radius-8">
                        <div className="scancount font-regular textColor-Type1 font-weight-bold">{count}</div>
                    </div>
                </div>
                <div onClick={onClick} className="backgroundColor-Disable dropdown-div">
                    <label className="textColor-Type1 dropdown-font">Select Item Condition</label>
                    <div className="dropdown-innerdiv">
                        <label className="dropdown-font" style={{ color: selectedItem.color }}>{selectedItem.name}</label>
                        <img className={showText ? "floatRight rotate" : "floatRight"} src={process.env.PUBLIC_URL + '/Assets/icons/right-arrow.svg'} alt="line" />
                    </div>
                </div>
                {showText ? <Options selectedItem={itemSelection} showText={showText} dropdownOptions={dropoptions} /> : <Scan Placeholder={InputPlaceholder} onScanHandler={onScanHandler} keyboardType={keyboardType} scanValue={scanValue} inputRef={inputRef} button_type={button_type} DefaultValue={DefaultValue} inputMode={inputMode} setInputMode={setInputMode} />}
            </div>
            {/* <div className="backgroundColor-Type3 scanner-div cmt-15 cmb-15">
                <div className="cmb-15 d-flex justify-content-between align-items-center w-100 cml-12 cmr-12">
            <div className="backgroundColor-Type3 scanner-div cmb-15">
                <div className="cmt-15 cmb-15 d-flex justify-content-between align-items-center w-100 cml-12 cmr-12">
                    <input type="text" className="backgroundColor-Type4 quantity-input font-small2 textColor-Type4" placeholder={InputPlaceholder} onChange={(e) => scanValue(e.target.value)} />
                    <img className="floatRight" src={process.env.PUBLIC_URL + '/Assets/icons/enter.png'} alt="line" onClick={onScanHandler} />
                </div>
            </div> */}
            {/* <div className="backgroundColor-Type3 scanner-div cmt-15 cmb-15">
                <div className=" backgroundColor-Type4 scannerInput cmb-15">
                    <input type="text" className="backgroundColor-Type4 scannerInput" placeholder={InputPlaceholder} onChange={(e) => scanValue(e.target.value)} />
                    <img className="floatRight" src={process.env.PUBLIC_URL + '/Assets/icons/noun_Barcode_2011116 2.svg'} alt="line" onClick={onScanHandler} />
                </div>
            </div> */}
        </div>
    )
}

const Options = ({ selectedItem, dropdownOptions }) => {
    return (
        <div className="backgroundColor-Type3 dropdown-optiondiv">
            <ul>
                {dropdownOptions.map(({ title, color, call_back_url, call_back_url_params }) => (
                    <li className="options" key={title} style={{ color: color }} onClick={() => selectedItem(title, color, call_back_url, call_back_url_params)}>
                        {/* <img className={icon ? "show" : "hide"} src={process.env.PUBLIC_URL + '/Assets/icons/' + icon} alt="line" /> */}
                        <label className="floatRight">{title}</label>
                    </li>
                ))}
            </ul>
        </div>
    )
}

const Scan = ({ Placeholder, keyboardType, scanValue, onScanHandler, inputRef, button_type, DefaultValue, inputMode, setInputMode }) => {
    const showKeyboard = () => {
        setInputMode('text');
        inputRef.current.inputMode = 'text';
    }

    return (
        <div className="backgroundColor-Type3 scanner-div cpt-15 cmb-15 cpb-10">
            <div className=" d-flex justify-content-between align-items-center w-100 cml-12 cmr-12">
                <div className=" backgroundColor-Type4 scannerInput quantity-inputwithCondition">
                    <input
                        autoFocus
                        type={keyboardType}
                        ref={inputRef}
                        className="font-family-inherit backgroundColor-Type4 font-small2"
                        onPaste={(e) => {
                            e.preventDefault()
                            return false;
                        }}
                        onCopy={(e) => {
                            e.preventDefault()
                            return false;
                        }}
                        onClick={showKeyboard}
                        inputMode={inputMode}
                        onKeyUp={onScanHandler}
                        defaultValue={DefaultValue}
                        placeholder={Placeholder}
                        // onClick={handleInputChange}
                        onChange={(e) => scanValue(e.target.value)}
                    />
                    {
                        !button_type &&
                        <img className="floatRight op_5" src={process.env.PUBLIC_URL + '/Assets/icons/noun_Barcode_2011116 2.svg'} alt="line" onClick={(e) => { onScanHandler(e) }} />
                    }
                </div>

            </div>
            {
                button_type &&
                <img className="floatRight cmr-10" src={process.env.PUBLIC_URL + '/Assets/icons/B3.png'} alt="line" onClick={(e) => { onScanHandler(e) }} />
            }
        </div>)
}
export default ScanValidateTileWithCondition;