import { keyboard } from "@testing-library/user-event/dist/keyboard";
import { useDispatch } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Password from "./Pages/pasword";
import Settings from "./Pages/settings";
import { slice } from "./Redux/mainRedux/mainSlice";
import RoutesNavigator from './Routes/routesNavigator';

const App = () => {
    const dispatch = useDispatch();
    const keyboardListner = (e) => {
        const rootElem = document.getElementById('root')
        const isKeyboardOpen = e.currentTarget.height <= 570;
        // console.dir(rootElem);
        if (isKeyboardOpen) {
            dispatch(slice.actions.setKeyboardStatus(true));
            if (rootElem) {
                rootElem.style.height = '470px';
                rootElem.style.overflowY = 'auto';
            }
        } else {
            dispatch(slice.actions.setKeyboardStatus(false));
            if (rootElem) {
                rootElem.style.height = 'auto';
                rootElem.style.overflowY = 'hidden';
            }
        }
    }

    window.visualViewport.addEventListener('resize', keyboardListner);

    return (
        <BrowserRouter>
            <RoutesNavigator />
        </BrowserRouter>
    )
}

export default App;
