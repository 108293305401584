import React, { useEffect, useState } from "react";
import AppHeader from "../Components/appHeader";
import MenuTile from "../Components/menuTile";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../Redux/mainRedux/mainActions";
import SideBar from "../Components/sideBar";
import ClientSideList from "../Components/clientSideList";
import Backdrop from "@mui/material/Backdrop";
import { slice } from "../Redux/mainRedux/mainSlice";
import ErrorMessage from "../Components/errorMessage";

const MenuScreen = () => {
  const { baseAPIURL, home, menuResponse, token, sessionStorage, warehouse, error } = useSelector(state => state.slice);
  const dispatch = useDispatch();
  const [sidebarclickVal, setSidebarclickVal] = useState(false)
  const [open, setOpen] = React.useState(false);
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [warehouseClick, setWarehouseClick] = useState(false);
  const [clientTitle, setclientTitle] = useState(home?.warehouse_selector_button_text);

  const onSelectTileHandler = (e) => {
    if (!e.disabled) {
      let body = e.identifier ? { identifier: e.identifier, apiSessionStorage: sessionStorage } : null
      dispatch(actions.generic_call(baseAPIURL, e.callback_url, body, 'post', token));
    }
  }
  const sidebarclick = () => {
    setSidebarclickVal(true);
    setOpen(true);
  }

  window.handleClose = () => {
    setOpen(false);
  }

      // For Error Message
      const errorCloseHandler = () => {
        setErrorOpen(false);
        dispatch(slice.actions.clearError());
      };
  
      useEffect(() => {
        if (error) {
          setErrorOpen(true)
          if(error.code===306)
          {
            setTimeout(() => {
              redirectAfterSessionExpire();
              localStorage.clear();
              setErrorOpen(false)
              dispatch(slice.actions.clearError());
            }, 2000);
          }
        }
        else {
          setErrorOpen(false)
        } 
      }, [error]);

  const redirectAfterSessionExpire = () => {
    dispatch(slice.actions.setPage('login'));
  }

    // Handle change in click for dropdown and backdrop
    const handleChange = () => {
      setWarehouseClick((prev) => !prev);
      // setOpen(!open);
    };

  return (
    home ? <>
      <SideBar 
        menuResponsedata={menuResponse} 
        sidebarstatus={sidebarclickVal} 
        sidebarclick={setSidebarclickVal} 
        setBackdropClose={setOpen}
        setSidebarClose={open}/>
        <ClientSideList 
        clientScreenHeader={home?.warehouse_page_title}
        clientdataHeader={home?.warehouse_table_header}
        clientdata={home?.warehouse_list}
        setclientTitle={setclientTitle}
        sidebarclick={warehouseClick?true:false} 
        setSidebarClose={setWarehouseClick}
        />
      <AppHeader
        menuIconVisible={true}
        sidebarclick={sidebarclick}
        backIconVisible={false}
        textVisible={true}
        text={home.title}
        warehouseName={clientTitle}
        warehouseClick={setWarehouseClick}
        onChange={handleChange}
        warehouseEnable={home?.warehouse_list?.length>0 ? true : false}
      />

      <div className="cpt-20 cpb-20 cpl-20 cpr-20 backgroundColor-Type5 mainPageType1">
        <div className="row">
          {home.tiles.map((tile, index) => <MenuTile data={tile} onClickHandler={onSelectTileHandler} key={tile.display_text} index={index} />)} 
        </div>
        
        {errorOpen && <ErrorMessage open={errorOpen} onClose={errorCloseHandler} error={error} />}

      <Backdrop sx={{ color: "#fff" }} open={open} onClick={window.handleClose}/>

      </div>
    </> : <h1>Loading...</h1>
  );
};

export default MenuScreen;
