import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const ScanValidateTile = ({ palletQtyAndLocation, unboundedText, boundedText, tileqty, boundedText1, button_type, locationQtyPallet_List, InputPlaceholder, onScanHandler, scanValue, inputRef, scanText }) => {
    const { keyboardType } = useSelector(state => state.slice);
    const [inputMode, setInputMode] = useState('none');

    useEffect(() => setInputMode('none'), [unboundedText, boundedText, InputPlaceholder])

    const showKeyboard = () => {
        setInputMode('text');
        inputRef.current.inputMode = 'text';
    }

    return (
        <div className="cmt-10 backgroundColor-Type3 mainScanTile">
            <div className={"backgroundColor-Gradient" + (palletQtyAndLocation ? ' uperScaner-div2' : ' uperScaner-div-1')}>
                <div className="uperSD-Text font-small pick-locations-title-1 w-100 cmb-38">{unboundedText}</div>

                <div className="d-flex justify-content-between align-items-center w-100 cpl-20 cpr-20">

                    <div className="d-block">
                        <div className="textColor-Type2 font-small17">{boundedText}</div>
                        <div className="textColor-Type2 font-small17">{boundedText1}</div>
                    </div>

                    <div className="backgroundColor-Type6 border-radius-8">
                        <div className="scancount font-regular-1 textColor-Type1">{tileqty}</div>
                    </div>

                </div>
            </div>
            <div className="backgroundColor-Type3 scanner-div cpt-15 cmb-15">
                <div className="backgroundColor-Type4 scannerInput cmb-15 field" style={{ "width": button_type ? "80%" : "95%" }}>
                    <input
                        type={keyboardType}
                        autoFocus
                        ref={inputRef}
                        className="backgroundColor-Type4 inputscan"
                        onPaste={(e) => {
                            e.preventDefault()
                            return false;
                        }}
                        onCopy={(e) => {
                            e.preventDefault()
                            return false;
                        }}
                        onClick={showKeyboard}
                        inputMode={inputMode}
                        onKeyUp={onScanHandler}
                        placeholder={InputPlaceholder}
                        onChange={(e) => scanValue(e.target.value)}
                    />
                    {
                        !button_type &&
                        <img className="floatRight op_5" src={process.env.PUBLIC_URL + '/Assets/icons/noun_Barcode_2011116 2.svg'} alt="line" onClick={onScanHandler} />
                    }

                </div>
                {
                    button_type &&
                    <img className="floatRight cml-10 cmb-15" src={process.env.PUBLIC_URL + '/Assets/icons/B3.png'} alt="line" onClick={onScanHandler} />
                }
            </div>
        </div>
    )
}

export default ScanValidateTile;