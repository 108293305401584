import React, { useEffect } from "react";
import { useStopwatch } from 'react-timer-hook';

const PauseTimer = ({totalPauseTime}) => {

const {
  seconds,
  minutes,
  hours,
} = useStopwatch({ autoStart: true });

  // Add additional ZERO at start if digit is in single figure
  const checkDigit = (val) => {
          return val < 10 ? `0${val}` : val;
        }
  // Will Update total time
  useEffect(() => {
          totalPauseTime(convertToSec(hours,minutes,seconds));          
        }, [hours,minutes,seconds]);

// Will convert All Hours , Minutes and Seconds into Seconds
const convertToSec = (hrs, mins, sec) => {
          const hrsToSec = hrs>0? (hrs*3600) : 0;
          const minsToSec = mins>0? (mins*60) : 0;
          const counterSeconds = hrsToSec + minsToSec + sec;
          return counterSeconds 
  }

    return(
      
        <div className="formsubmit-container fixed-bottom cmb-15 cmb-90">
        <div className="backgroundColor-Type3 ScanScreen1Footer pause_timer">
          {
            <div className="cpl-15 cpr-15 font-medium"> Pause Timer: {checkDigit(hours)}:{checkDigit(minutes)}:{checkDigit(seconds)}</div>
          }
          </div>
          </div>
        
    )
}

export default PauseTimer;