import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import Dialog from '@mui/material/Dialog';

export default function ActionBarComponentProps({ changeValue, setShowCalender, setCalenderValue, onClose, open }) {
  // checking if calender value is recieved then converting into date and setting value for calendar 
  if (changeValue) {
    var arr = (changeValue.replace(/\b0/g, '')).split('/');
    var d = new Date((`${parseInt(arr[2])},${parseInt(arr[0])},${parseInt(arr[1])}`));
  }

  const [value, setValue] = React.useState(() => dayjs(d));


  return (
    <Dialog className="calenderDrop" onClose={onClose} open={open}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StaticDatePicker
          onChange={(newValue) => {
            setValue(new Intl.DateTimeFormat(['en-US', 'id'], { year: 'numeric', day: '2-digit', month: '2-digit' }).format(newValue.toDate()))
            setCalenderValue(new Intl.DateTimeFormat(['en-US', 'id'], { year: 'numeric', day: '2-digit', month: '2-digit' }).format(newValue.toDate()))
            setShowCalender(false)
          }}
          onClose={onClose}
          value={value}
          renderInput={(params) => <TextField {...params} />}
          slotProps={{
            actionBar: {
              actions: ["accept", "cancel"]
            }
          }}
        />
      </LocalizationProvider>
    </Dialog>

  );
}