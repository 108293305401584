import { createSlice } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";


export let V2_BASE_URL;
export const setValue = (value) => {
    return V2_BASE_URL = value;
}

export const initialState = {
    currentPage: 'login',
    sessionStorage: null,
    token: null,
    warehouse: null,
    forgetPassword: null,
    home: null,
    tile2: null,
    next_pick: null,
    scan1: null,
    scan2: null,
    scan4: null,
    inquiry: null,
    validate1: null,
    validate2: null,
    drop: null,
    warehouseChange: null,
    confirmationOption: null,
    success: null,
    apiSuccess: null,
    homeError: null,
    error: null,
    warning: null,
    timerdetail: null,
    timer: null,
    pauseTime: null,
    pauseCount: null,
    totalTimeSpend: null,
    isLoading: false,
    keyboardType: "text",
    keyboardStatus: false,
    menuResponse: null,
    iframeURL: V2_BASE_URL,
    home_btn: null,
    bluetooth: false,
    totpData: null,
    userCredentials: null,
    showLockUserPopup: false,
    userType: null,
    clients: null,
    searchGrid: null,
    baseAPIURL: null,
    progressiveArray: null,
    progressiveLoading: false,
    confirmation: null,
    sourceOfRecord: null,
};

export const slice = createSlice({
    name: "main",
    initialState: initialState,
    reducers: {
        setPage: (state, action) => {
            //console.log("From Action", action.payload);
            state.currentPage = action.payload;
        },
        setResponse: (state, action) => {
            state[action.payload.variable] = action.payload.response;
        },
        setMenuResponse: (state, action) => {
            state.menuResponse = action.payload;
        },
        setSessionStorage: (state, action) => {
            state.sessionStorage = action.payload;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setWarehouse: (state, action) => {
            state.warehouse = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload.error;
        },
        clearError: (state, action) => {
            state.error = null;
        },
        setWarning: (state, action) => {
            state.warning = action.payload;
        },
        clearWarning: (state, action) => {
            state.warning = null;
        },
        setApiSuccess: (state, action) => {
            state.apiSuccess = action.payload;
        },
        clearSuccess: (state, action) => {
            state.success = null;
        },
        setTimerDetail: (state, action) => {
            state.timerdetail = action.payload;
        },
        setTimer: (state, action) => {
            state.timer = action.payload;
        },
        setpauseTime: (state, action) => {
            state.pauseTime = action.payload;
        },
        setpauseCount: (state, action) => {
            state.pauseCount = action.payload;
        },
        setTotalTimeSpend: (state, action) => {
            state.totalTimeSpend = action.payload;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setKeyboardStatus: (state, action) => {
            state.keyboardStatus = action.payload;
        },
        clearforgetPassword: (state, action) => {
            state.forgetPassword = null;
        },
        setIframeURL: (state, action) => {
            state.iframeURL = action.payload
        },
        setHomeBtn: (state, action) => {
            state.home_btn = action.payload;
        },
        clearHomeBtn: (state, action) => {
            state.home_btn = null;
        },
        setKeyboardType: (state, action) => {
            state.keyboardType = action.payload;
        },
        setBluetooth: (state, action) => {
            state.bluetooth = action.payload;
        },
        setTotpData: (state, action) => {
            state.totpData = action.payload;
        },
        setUserCredentials: (state, action) => {
            state.userCredentials = action.payload;
        },
        setShowLockUserPopup: (state, action) => {
            state.showLockUserPopup = action.payload;
        },
        setUserType: (state, action) => {
            state.userType = action.payload;
        },
        setBaseAPIURL: (state, action) => {
            state.baseAPIURL = action.payload;
        },
        setProgressiveArray: (state, action) => {
            state.progressiveArray = action.payload;
        },
        setProgressiveLoading(state, action) {
            state.progressiveLoading = action.payload;
        },
        setSourceOfRecord(state, action) {
            state.sourceOfRecord = action.payload;
        },
        updateLookupList(state, action) {
            if(action.payload.type && action.payload.type === 'search') {
                const search_object = {
                    title: [...state[action.payload.field]?.search_object.title],
                    value: [...action.payload.data]
                }
    
                Object.assign(state[action.payload.field].search_object, search_object);
            } else {
                const search_object = {
                    title: [...state[action.payload.field]?.search_object.title],
                    value: [...state[action.payload.field]?.search_object.value, ...action.payload.data]
                }
                Object.assign(state[action.payload.field].search_object, search_object);
            }
        }
    }
});

