import React, { useState } from "react";
const WorkFlowTile = ({ data, onClick }) => {
  const [style, setStyle] = useState(!data.disabled?"fulfillmentTextInactive":"cpt-20 pd-max cpl-10 cpr-10 fulfillmentText");
//   console.log("tile prop", test, !data.disabled);
  const changeStyle = () => {
    !data.disabled? setStyle("fulfillmentTextactive"):setStyle("cpt-20 pd-max cpl-10 cpr-10 fulfillmentText")
  };

  return (
    // <div className={`col-sm-3 col-xs-6 col-6 ${tiles.disabled ? 'disableTile' : 'enableTile'}`} key={tiles.display_text}>
    <div className={`col-sm-3 col-xs-6 col-6`} onClick={() => onClick(data)}>
    <div
      className={`${!data.disabled?"button":""} cmb-30 backgroundColor-Type3 fulfillmentTile `}
      onClick={changeStyle}
    >
      <div className={style}>
        <label className={data.disabled ?'font-medium textColor-Type4 lineBreak' : 'font-large textColor-Type1 lineBreak'}>{data.display_text}</label>
      </div>
      <div className={`${!data.disabled?"backgroundColor-Gradient":"backgroundColor-Disable disabled-arrow"} fulfillmentArrowDiv`}>
       {!data.disabled?<img className="cmt-10 cmb-10" src={process.env.PUBLIC_URL + "/Assets/icons/arrow.svg"}alt="arrow"/>
       :
       <img className="cmt-10 cmb-10" src={process.env.PUBLIC_URL + "/Assets/icons/arrowdisable.svg"} alt="arrow"/>} 
      </div>
    </div>
    </div>
  ) 
};
export default WorkFlowTile;
