import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
const EnterQuantityTile = ({ palletQtyAndLocation, unboundedText, unboundedText1, InputPlaceholder, InputPlaceholder1, onScanHandler, scanValue, inputRef1, inputRef2 }) => {
    const { keyboardType } = useSelector(state => state.slice);
    const [inputMode, setInputMode] = useState('none');

    useEffect(() => setInputMode('none'), [unboundedText, InputPlaceholder])

    const showKeyboard = () => {
        setInputMode('text');
        inputRef1.current.inputMode = 'text';
        inputRef2.current.inputMode = 'text';
    }

    return (
        <div className="cmt-10 backgroundColor-Type3 mainScanTile">
            <div className={"backgroundColor-Gradient" + (palletQtyAndLocation ? ' uperScaner-div2' : ' uperScaner-div-3')}>
                <div className="font-family-inherit uperSD-Text font-small2 w-100 cmt-60">{unboundedText1}</div>
                <div className="font-family-inherit uperSD-Text font-small2 w-100 cmb-55">{unboundedText}</div>
                <div className="d-flex justify-content-between align-items-center w-100 cpl-12 cpr-12 cmb-17">
                </div>
            </div>
            <div className="backgroundColor-Type3 scanner-div cmt-15 cmb-15">
                <div className="cmb-15 d-flex justify-content-between align-items-center w-100 cml-12 cmr-12">
                    <input
                        type={keyboardType}
                        autoFocus
                        ref={inputRef1}
                        className="font-family-inherit backgroundColor-Type4 quantity-input font-small2"
                        onPaste={(e) => {
                            e.preventDefault()
                            return false;
                        }}
                        onCopy={(e) => {
                            e.preventDefault()
                            return false;
                        }}
                        onClick={showKeyboard}
                        inputMode={inputMode}
                        onKeyUp={onScanHandler}
                        placeholder={InputPlaceholder}
                        onChange={(e) => scanValue(e.target.value)}
                    />

                    <input
                        type={keyboardType}
                        ref={inputRef2}
                        className="font-family-inherit backgroundColor-Type4 quantity-input font-small2"
                        onPaste={(e) => {
                            e.preventDefault()
                            return false;
                        }}
                        onCopy={(e) => {
                            e.preventDefault()
                            return false;
                        }}
                        onClick={showKeyboard}
                        inputMode={inputMode}
                        placeholder={InputPlaceholder1}
                        onChange={(e) => scanValue(e.target.value)} />
                    <img className="floatRight" src={process.env.PUBLIC_URL + '/Assets/icons/B3.png'} alt="line" onClick={onScanHandler} />
                </div>
            </div>
        </div>
    )
}
export default EnterQuantityTile;