const EnterRemainingQty = ({ unboundedText, InputPlaceholder, onKeyUp }) => {
    return (
        <div className="cmt-10 backgroundColor-Type3 mainScanTile">
            <div className="backgroundColor-Gradient uperScaner-div">
                <div className="uperSD-Text font-small2">{unboundedText}</div>
            </div>
            <div className="backgroundColor-Type3 scanner-div">
                <input type="text" className="backgroundColor-Type4 scannerInput" onKeyUp={onKeyUp} placeholder={InputPlaceholder} />
            </div>
        </div>
    )
}

export default EnterRemainingQty;