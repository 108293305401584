import React, { useEffect, useState, useRef } from "react";
import AppHeader from "../Components/appHeader";
import TimerFooter from "../Components/timerFooter";
import PickLocationsTile from "../Components/PicklocationsTile";
import ScanValidateTile from "../Components/ScanValidateTile";
import ScanScreenSubHeader from "../Components/ScanScreenSubHeader";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../Redux/mainRedux/mainActions";
import { slice } from "../Redux/mainRedux/mainSlice";
import ErrorMessage from "../Components/errorMessage";
import DroppedPackagesList from "../Components/droppedPackagesList";
import AudioPlayer from "../Components/audioPlayer";
import AppButton from "../Components/appButton";
import BottomSlide from "../Components/bottomSlide";
import { hideKeyboard } from '../utility/hideKeyboard';

const ScanTypeValidate = (props) => {
  const { baseAPIURL, validate1, token, sessionStorage, error, warning, success } = useSelector(state => state.slice);
  const dispatch = useDispatch();

  const [scanVal, setScanVal] = useState(null)
  const [errorOpen, setErrorOpen] = useState(false);
  const [warningOpen, setWarningOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [scanError, setScanError] = useState(false);
  const [hideArea, setHideArea] = useState(true)
  const [checkPopupHolder, setPopupHolder] = useState(null);
  const [checkPackageList, setPackageList] = useState(false)
  const [checkSuccessBeep, setSuccessBeep] = useState(false)
  const [showExitPopupVal, setExitPopupVal] = useState(false);
  const [checkBottomSlider, setBottomSlider] = useState(false);
  const [checkBottomSliderVal, setBottomSliderVal] = useState(null);

  let sound2 = "/Assets/audio/goodscan.mp3";
  let sound3 = "/Assets/audio/badscan.mp3";

  let scanErrorMessage = {
    'detail': 'Barcode not recognized. Please scan again properly.'
  }

  const inputRef = useRef();
  // useEffect(() => {
  //   inputRef.current.focus();
  window.scrollEnable = function () {
    setHideArea(false)
  }
  window.scrollDisable = function () {
    setHideArea(true)
  }

  // For Error Message
  const errorCloseHandler = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ name: 'hide_keyboard' }))
    }
    setScanError(false);
    setErrorOpen(false);
    if (error.call_back_url && error.call_back_url_params) redirectAfterError(error)
    dispatch(slice.actions.clearError());
  };

  // This will only come when there is RLLP is already created or call back URL is comes in error block
  const redirectAfterError = (data) => {
    let body = { ...data.call_back_url_params, apiSessionStorage: sessionStorage };
    dispatch(actions.generic_call(baseAPIURL, data.call_back_url, body, 'post', token));
  }

  useEffect(() => {
    // check error
    if (error) {
      setErrorOpen(true)
      if (error.code === 306) {
        setTimeout(() => {
          redirectAfterSessionExpire();
          localStorage.clear();
          setErrorOpen(false)
          dispatch(slice.actions.clearError());
        }, 2000);
      }
    }
    else {
      setErrorOpen(false)
    }

    // // check success
    // if (success) {
    //   setSuccessOpen(true)
    //   setTimeout(() => {
    //     setSuccessOpen(false);
    //     dispatch(slice.actions.clearSuccess());
    //     redirectAfterSuccess();
    //   }, 2000);

    // } else setSuccessOpen(false)
    // check warning
    // if (warning) {
    //   setWarningOpen(true)
    //   setTimeout(() => {
    //     setWarningOpen(false);
    //     dispatch(slice.actions.clearWarning());
    //   }, 2000);
    // } else {
    //   setWarningOpen(false)
    // }

  }, [error]);


  const scanHandler = e => {
    e.preventDefault()
    if (e.key === "Enter" || e.type === "click") {
      if (inputRef.current.value) {
        setSuccessBeep(false)
        dispatch(slice.actions.setApiSuccess(false))
        let body = { ...validate1.call_back_url_params, scan_value: inputRef.current.value, apiSessionStorage: sessionStorage };
        inputRef.current.value = "";
        // inputRef.current.focus();
        // window.ReactNativeWebView.postMessage(JSON.stringify({ name: 'hide_keyboard' }))
        hideKeyboard();
        dispatch(actions.generic_call(baseAPIURL, validate1.call_back_url, body, 'post', token));
      } else {
        hideKeyboard();
        setScanError(true);
        setErrorOpen(true);
      }
    }
  }

  // On selecting drop package list
  const onClickDropPackageList = () => {
    setPackageList(true)
  }

  const redirectAfterSessionExpire = () => {
    dispatch(slice.actions.setPage('login'));
  }

  const getSubmitHandler = (data) => {
    // Api will hit with Date and comments fields data
    let body = { ...data.call_back_url_params, scan_value: inputRef.current.value, apiSessionStorage: sessionStorage };
    dispatch(actions.generic_call(baseAPIURL, data.callback_url, body, 'post', token));
  }

  useEffect(() => {
    const audio = new Audio((error?.code === 409 ? sound3 : sound2));
    audio.play();
  }, [validate1, error]);

  const backClickHandler = () => {
    let body = {}
    // body for back Button api call
    if (validate1?.back_button) {
      body['apiSessionStorage'] = sessionStorage;
      if (validate1.back_button.call_back_url_params) {
        for (const [pramkey, pramValue] of Object.entries(validate1.back_button.call_back_url_params)) {
          body[pramkey] = pramValue;
        }
      } else {
        body['identifier'] = validate1?.back_button?.identifier
      }

      dispatch(actions.generic_call(
        validate1?.back_button?.base_url,
        validate1?.back_button?.callback_url,
        body, 'post', token
      ));
    }
  }

  const exitClickPopup = (val) => {
    setExitPopupVal(val);
  }

  const routeOnExit = (callbackparam, callback_url) => {
    setExitPopupVal(false);
    let body = { ...callbackparam, apiSessionStorage: sessionStorage };
    dispatch(actions.generic_call(baseAPIURL, callback_url, body, 'post', token));
  }

  const onSubHeaderDescClick = (e) => {
    if (e.length > 24) {
      setBottomSlider(true)
      setBottomSliderVal(null)
      setBottomSliderVal(e)
    }
  }

  const closeHandle = () => {
    setBottomSlider(false)
  }

  return (
    <>
      {!checkPackageList &&
        <>
          <AppHeader
            menuIconVisible={false}
            backIconVisible={validate1?.back_button}
            textVisible={true}
            text={validate1.title}
            onBackClick={backClickHandler}
            waveDesc={validate1?.description}
            onHeaderDescClick={onSubHeaderDescClick}
            warehouseName={validate1?.warehouse_selector_button_text}
            warehouseEnable={validate1?.warehouse_list?.length > 0 ? true : false}
          />
          <ScanScreenSubHeader
            title1={validate1?.item?.title}
            value1={validate1?.item?.value}
            title2={validate1?.pallet?.title}
            value2={validate1?.pallet?.value}
            title3={validate1?.lot_title ? validate1?.lot_title?.title : null}
            value3={validate1?.lot_title ? validate1?.lot_title?.value : null}
            remainValue={{ total: validate1?.qty_requested_pick, remaining: validate1?.qty_remaining }}
            remainText={validate1?.remaining_text} />
          <div className="cpt-10 cpl-15 cpr-15 cpb-15 backgroundColor-Type5 mainPageType2">
            <div className="wfScreen">
              <div className="row">

                {validate1.upper_left &&
                  <div className="col-sm-6 col-xs-6 col-6 cpr-5">
                    <PickLocationsTile text={validate1.upper_left.title} value={validate1.upper_left.value} />
                  </div>}
                {validate1.upper_right &&
                  <div className="col-sm-6 col-xs-6 col-6 cpl-2">
                    <PickLocationsTile text={validate1.upper_right.title} value={validate1.upper_right.value} />
                  </div>}

                {validate1.upper_left_bottom &&
                  <div className="col-sm-6 col-xs-6 col-6 cpr-5 mt-2">
                    <PickLocationsTile text={validate1.upper_left_bottom.title} value={validate1.upper_left_bottom.value} />
                  </div>}
                {validate1.upper_right_bottom &&
                  <div className="col-sm-6 col-xs-6 col-6 cpl-2 mt-2">
                    <PickLocationsTile text={validate1.upper_right_bottom.title} value={validate1.upper_right_bottom.value} />
                  </div>}
                <div className="col-sm-12 col-xs-12 col-12">
                  <ScanValidateTile
                    unboundedText={validate1.blue_text}
                    boundedText={validate1.blue_dotted_text_first}
                    boundedText1={validate1.blue_dotted_text_second}
                    InputPlaceholder={validate1.scan_text}
                    button_type={validate1?.button_type}
                    tileqty={validate1.item_qty_text}
                    onScanHandler={scanHandler}
                    scanValue={setScanVal}
                    inputRef={inputRef}
                    onKeyUp={scanHandler}
                    scanText={scanVal} />
                </div>

                {validate1?.dropPackageList &&
                  <div className="row justify-content-center footer fixed-bottom cmb-20 cml-0">
                    <div className={" d-flex align-items-center justify-content-center cpt-10 cpb-10 col-6 dropped-pkg-holder enableTile"} onClick={() => onClickDropPackageList()}>
                      <img className="cpr-7" src={process.env.PUBLIC_URL + '/Assets/icons/Drop.png'} alt="swapicon"></img>
                      <label className="drop-title">{validate1?.dropPackageList?.title}</label>
                    </div>
                  </div>}
              </div>
              {validate1?.mini_exit_btn && hideArea &&
                <div className="d-flex col-sm-6 col-xs-6 cpl-2">
                  <div className={"ExitTile backgroundColor-Type3 font-small"} onClick={() => exitClickPopup(true)}>
                    <span className="font-medium textColor-Type8">{validate1?.mini_exit_btn?.btn_text}</span>
                  </div>
                </div>
              }
            </div>
          </div>
          {
            <BottomSlide open={showExitPopupVal} closeHandle={() => exitClickPopup(false)}>
              <div className="w-100">
                <h5 className="text-center my-4">{validate1?.mini_exit_btn?.blue_text}</h5>
                <div className="d-flex gap-2 p-2">
                  <AppButton displayText={validate1?.mini_exit_btn?.btn_option[0].title} onClick={() => routeOnExit(validate1?.mini_exit_btn?.btn_option[0].on_click_url_params, validate1?.mini_exit_btn?.btn_option[0].on_click_url)} />
                  <AppButton displayText={validate1?.mini_exit_btn?.btn_option[1].title} onClick={() => exitClickPopup(false)} isOutlined={true} />
                </div>
              </div>
            </BottomSlide>
          }
          <BottomSlide
            children={checkBottomSliderVal}
            open={checkBottomSlider}
            closeHandle={closeHandle}
          />
        </>
      }

      {/* {((validate1?.beep_code === 200 && checkSuccessBeep) || (error?.code === 409)) &&
        <AudioPlayer
          sound={validate1?.beep_code === 200 ? sound2 : (error?.code === 409 ? sound3 : '')}
          loop={false}
          muted={false}
        />
      } */}

      <div className="cml-30 cmr-30 col-sm-12 col-xs-12 fixed-bottom index cmb-30">
        {/* Will show once button response recieve from backend */}
        {(validate1?.complete_button) &&
          <AppButton
            displayText={validate1?.complete_button?.button_text}
            onClick={() => getSubmitHandler(validate1?.complete_button)}
          />
        }
      </div>

      {errorOpen && <ErrorMessage open={errorOpen} onClose={errorCloseHandler} error={scanError ? scanErrorMessage : (checkPopupHolder ? checkPopupHolder : error)} />}

      { // for drop package list
        checkPackageList &&
        <DroppedPackagesList
          setPackageList={setPackageList}
          dropPackageData={validate1?.dropPackageList?.detail}
        />
      }
    </>
  );
};

export default ScanTypeValidate;


