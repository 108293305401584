import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Login from '../Pages/login';
import MenuScreen from '../Pages/menuScreen';
import FulfillmentWorkFlowScreen from '../Pages/fulfillmentWorkFlowScreen';
import FulfillmentWorkFlowUnassigned from '../Pages/fulfillmentWorkFlowUnassigned';
import ScanScreenType2 from "../Pages/scanScreenType2";
import ScanScreenType1 from "../Pages/scanScreenType1";
import ScanScreenType3 from "../Pages/scanScreenType3";
import Confirmation from "../Pages/confirmation";
import RemainingQty from "../Pages/remainQty";
import ScanTypeValidate from "../Pages/scanTypeValidate";
import ScanTypeValidateWithQuantity from "../Pages/scanTypeValidateWithQuantity";
import DropPackage from "../Pages/dropPackage";
import * as actions from "../Redux/mainRedux/mainActions";
import Loading from "../Components/Loading";
import TwoFactorAuth from "../Pages/2fa";
import Password from "../Pages/pasword"
import Settings from "../Pages/settings"
import About from "../Pages/about";
import { slice, V2_BASE_URL } from "../Redux/mainRedux/mainSlice";
import ClientSelectionScreen from "../Components/clientSelectionScreen";
import AccountSelectionScreen from "../Components/accountSelection"
import Inquiry from "../Pages/inquiry";
import ScanScreenType4 from "../Pages/scanScreenType4";
import { SearchableGrid } from "../Pages/SearchableGrid";
import { DocumentOption } from "../Pages/DocumentOption";


const RoutesNavigator = () => {
  
  const { baseAPIURL, tile2, currentPage, isLoading, sessionStorage, token, iframeURL, sourceOfRecord } = useSelector(state => state.slice);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  useEffect(() => {
    
    // if (getdata() === 'tile2') {
    //   navigate(getdata())
    // } else {
      navigate(`/${currentPage}`);
    // }
  }, [currentPage]);

  window.callRefresh = function () {
    if (currentPage === 'get_next_pick_select_pallet_button' && sourceOfRecord === 'v2') {
      let body = { identifier: 'regular', apiSessionStorage: sessionStorage };
      dispatch(actions.generic_call(baseAPIURL, 'workflow', body, 'post', token));
    }
  }
  
  //this function will used to navigate the user to previous screen and it will called from hardware side
  window.callPrevPage = function () {
    const v2Url = new URL(iframeURL);

    if(v2Url.pathname !== '/') {
      dispatch(slice.actions.setIframeURL(V2_BASE_URL));
    } else if (currentPage === 'tile2') {
      localStorage.clear();
      let body = { identifier: tile2?.back_button?.identifier, apiSessionStorage: sessionStorage }
      dispatch(actions.generic_call(baseAPIURL, tile2?.back_button?.callback_url, body, 'post', token));
    } else if (currentPage === 'get_next_pick_select_pallet_button') {
      localStorage.clear();
      let body = { identifier: tile2?.back_button?.identifier, apiSessionStorage: sessionStorage }
      dispatch(actions.generic_call(baseAPIURL, tile2?.back_button?.callback_url, body, 'post', token));
      dispatch(slice.actions.setPage('tile2'));
    }
  }
  
  return (
    <>
      <Routes>
        {/* <Route path='*' element={<NotFound />} /> */}
        {/* <Route path="/" element={<Navigate to="/login" />} /> */}
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/TwoFactorAuth" element={<TwoFactorAuth />} />
        <Route path="/login_page" element={<Login />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/about" element={<About />} />
        <Route path="/password" element={<Password />} />
        <Route path="/clientSelection" element={<ClientSelectionScreen />} />
        <Route path="/accountSelection" element={<AccountSelectionScreen />} />
        <Route path="/tile1" element={<MenuScreen />} />
        <Route path="/tile2" element={<FulfillmentWorkFlowScreen />} />
        <Route path="/get_next_pick_select_pallet_button" element={<FulfillmentWorkFlowUnassigned />} />
        <Route path="/scan1" element={<ScanScreenType1 />} />
        <Route path="/startingLabel" element={<ScanScreenType1 />} />
        <Route path="/nextBin" element={<ScanScreenType1 />} />
        <Route path="/askIfAnyMoreItems" element={<Confirmation />} />
        <Route path="/scanCartonLabel1" element={<ScanScreenType2 />} />
        <Route path="/scanScreenType2" element={<ScanScreenType2 />} />
        <Route path="/scan_lp_from_bins" element={<ScanScreenType3 />} />
        <Route path="/confirmation" element={<Confirmation />} />
        <Route path="/documentOption" element={<DocumentOption />} />
        <Route path="/returnExtraQty" element={<ScanScreenType1 />} />
        <Route path="/remaining-qty" element={<RemainingQty />} />
        <Route path="/scan-type-validate" element={<ScanTypeValidate />} />
        <Route path="/scan-type-validate-with-quantity" element={<ScanTypeValidateWithQuantity />} />
        <Route path="/drop-package" element={<DropPackage />} />
        <Route path="/inquiry" element={<Inquiry />} />
        <Route path="/ScanScreenType4" element={<ScanScreenType4 />} />
        <Route path="/search-grid" element={<SearchableGrid />} />
        {/* <Route path="/undefined" element={<Login />} /> */}
      </Routes>
      {isLoading && <Loading />}
    </>
  );
}
const getdata = () => {
  // return window.location.href;
  return localStorage.getItem("CurrentPage");
  // let api =  sessionStorage.getItem("CurrentApi");
  // let payload =  sessionStorage.getItem("CurrentPayload");
}
export default RoutesNavigator;