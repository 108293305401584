import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { slice } from "../Redux/mainRedux/mainSlice";
import * as actions from "../Redux/mainRedux/mainActions";

const MainScanTileWithCondition = ({ unboundedText, boundedText, dropdownOptions, InputPlaceholder, casepack_text, casepack_text_color, casepack_background_color, throw_type, onScanHandler, scanValue, inputRef }) => {
    const { baseAPIURL, sessionStorage, token, keyboardType } = useSelector(state => state.slice);
    const dispatch = useDispatch();
    const [inputMode, setInputMode] = useState('none');
    // State for open/close dropdown
    const [showText, setShowText] = useState(false);
    const onClick = () => {
        setShowText(!showText);
        //calling function on dropdown
    }

    // State selecting value from dropdown    
    const [selectedItem, setselectedItem] = useState({ name: dropdownOptions[0].title, color: dropdownOptions[0].color });
    function itemSelection(title, color, call_back_url, call_back_url_params) {
        setShowText(!showText);
        setselectedItem({ name: title, color: color });
        callOptionUrl(call_back_url, call_back_url_params);
    }

    // Hit Api for selected option
    function callOptionUrl(callback, callbackparam) {
        console.log('options selected', callback, callbackparam);
        let body = { identifier: callbackparam.identifier, put_id: callbackparam.put_id, pick_ticket_id: callbackparam.pick_ticket_id, apiSessionStorage: sessionStorage };
        dispatch(actions.generic_call(baseAPIURL, callback, body, 'post', token));
    }

    useEffect(() => setInputMode('none'), [unboundedText, boundedText, InputPlaceholder])

    return (
        <div className={"cmt-10 mainScanTile avoid-keypad" + (throw_type === "specialpick" ? ' backgroundColor-Type8' : ' backgroundColor-Type3')}>
            {throw_type === "specialpick" &&
                <div className="sp_Pick"><label>Special Pick</label></div>
            }
            <div className="backgroundColor-Gradient upper_casePack-div">
                <div className="uperScaner-div">
                    <div className="uperSD-Text-conditionSC font-large-md pick-locations-title">{unboundedText}</div>
                    {boundedText && <div className="uperSD-boundedText font-xlarge">{boundedText}</div>}
                </div>
                {casepack_text &&
                    <div className="inner_casePack-div" style={{ backgroundColor: casepack_background_color }}>
                        <label style={{ color: casepack_text_color }}>{casepack_text}</label>
                    </div>
                }
            </div>

            <div onClick={onClick} className="backgroundColor-Disable dropdown-div">
                <label className="textColor-Type1 dropdown-font">Regional Throw Skip</label>
                <div className="dropdown-innerdiv">
                    <label className="dropdown-font" style={{ color: selectedItem.color }}>{selectedItem.name}</label>
                    <img className={showText ? "floatRight rotate" : "floatRight"} src={process.env.PUBLIC_URL + '/Assets/icons/right-arrow.svg'} alt="line" />
                </div>
            </div>
            {showText ? <Options selectedItem={itemSelection} showText={showText} dropdownOptions={dropdownOptions} /> : <Scan Placeholder={InputPlaceholder} onScanHandler={onScanHandler} keyboardType={keyboardType} scanValue={scanValue} inputRef={inputRef} setInputMode={setInputMode} inputMode={inputMode} />}
        </div>
    )
}

const Options = ({ selectedItem, showText, dropdownOptions }) => {
    return (

        <div className="backgroundColor-Type3 dropdown-optiondiv-1">
            <ul>
                {dropdownOptions.map(({ title, color, call_back_url, call_back_url_params }) => (

                    <li className="options" key={title} style={{ color: color }} onClick={() => selectedItem(title, color, call_back_url, call_back_url_params)}>
                        {/* <img className={icon? "show": "hide"} src={process.env.PUBLIC_URL + '/Assets/icons/'+icon} alt="line"/> */}
                        <label className="floatRight">{title}</label>
                    </li>
                ))}
            </ul>
        </div>
    )
}
const Scan = ({ Placeholder, keyboardType, scanValue, onScanHandler, inputRef, inputMode, setInputMode }) => {
    const showKeyboard = () => {
        setInputMode('text');
        inputRef.current.inputMode = 'text';
    }

    return (
        <div className="backgroundColor-Type3 scanner-div cpt-15 cmb-15">
            <div className=" backgroundColor-Type4 scannerInput cmb-15 field">
                <input
                    autoFocus
                    type={keyboardType}
                    ref={inputRef}
                    className="backgroundColor-Type4 inputscan"
                    onPaste={(e) => {
                        e.preventDefault()
                        return false;
                    }}
                    onCopy={(e) => {
                        e.preventDefault()
                        return false;
                    }}
                    onKeyUp={onScanHandler}
                    placeholder={Placeholder}
                    onClick={showKeyboard}
                    inputMode={inputMode}
                    onChange={(e) => scanValue(e.target.value)}
                />
                <img className="floatRight op_5" src={process.env.PUBLIC_URL + '/Assets/icons/noun_Barcode_2011116 2.svg'} alt="line" onClick={(e) => { onScanHandler(e) }} />
            </div>
        </div>)
}

export default MainScanTileWithCondition;