import { useSelector } from "react-redux";

export function IframeWindow() {
    const { iframeURL } = useSelector(state => state.slice);

    const url = new URL(iframeURL);

    const showIframe = url.pathname !== '/';

    return (
        showIframe && (
            <iframe
                loading="lazy"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                className="iframe" name="v2-iframe" src={iframeURL}>
            </iframe>
        )
    );
}