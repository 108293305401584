import React, { useState, useLayoutEffect, useEffect, useRef,  } from "react";

const AuthCode = ({ error }) => {
    const ref_input1 = useRef();
    const ref_input2 = useRef();
    const ref_input3 = useRef();
    const ref_input4 = useRef();
    const ref_input5 = useRef();
    const ref_input6 = useRef();

    const style = {
        PinCodeTextBoxPrimary: { width: '35px', height: '40px', borderRadius: '5px', border: '2px solid', borderColor: '#56CCF2', fontSize: '22px', padding: '10px 5px', fontWeight: '500', marginRight: '10px', textAlign: 'center' },
        PinCodeTextBoxError: { width: '35px', height: '40px', borderRadius: '5px', border: '2px solid', borderColor: '#FF3A41', fontSize: '22px', padding: '10px 5px', fontWeight: '500', marginRight: '10px', textAlign: 'center' }
    }

    window.getInputCode = () => {
        const code = ref_input1.current.value + ref_input2.current.value + ref_input3.current.value + ref_input4.current.value + ref_input5.current.value + ref_input6.current.value;
        return code;
    }

    return (
        <>
            <input
                style={error ? style.PinCodeTextBoxError : style.PinCodeTextBoxPrimary}
                type={"number"}
                max='9'
                ref={ref_input1}
                onChange={(text) => { if (text.target.value !== "") { ref_input2.current.focus(); } }}
            />

            <input
                style={error ? style.PinCodeTextBoxError : style.PinCodeTextBoxPrimary}
                type={"number"}
                max='9'
                ref={ref_input2}
                onKeyDown={(e) => { if (e.code === 'Backspace') { ref_input2.current.value = ''; ref_input1.current.focus(); } }}
                onChange={(text) => { if (text.target.value !== "") { ref_input3.current.focus(); } }}
            />

            <input
                style={error ? style.PinCodeTextBoxError : style.PinCodeTextBoxPrimary}
                type={"number"}
                max='9'
                ref={ref_input3}
                onKeyDown={(e) => { if (e.code === 'Backspace') { ref_input3.current.value = ''; ref_input2.current.focus(); } }}
                onChange={(text) => { if (text.target.value !== "") { ref_input4.current.focus(); } }}
            />

            <input
                style={error ? style.PinCodeTextBoxError : style.PinCodeTextBoxPrimary}
                type={"number"}
                max='9'
                ref={ref_input4}
                onKeyDown={(e) => { if (e.code === 'Backspace') { ref_input4.current.value = ''; ref_input3.current.focus(); } }}
                onChange={(text) => { if (text.target.value !== "") { ref_input5.current.focus(); } }}
            />

            <input
                style={error ? style.PinCodeTextBoxError : style.PinCodeTextBoxPrimary}
                type={"number"}
                max='9'
                ref={ref_input5}
                onKeyDown={(e) => { if (e.code === 'Backspace') { ref_input5.current.value = ''; ref_input4.current.focus(); } }}
                onChange={(text) => { if (text.target.value !== "") { ref_input6.current.focus(); } }}
            />

            <input
                style={error ? style.PinCodeTextBoxError : style.PinCodeTextBoxPrimary}
                type={"number"}
                max='9'
                ref={ref_input6}
                onKeyDown={(e) => { if (e.code === 'Backspace') { ref_input6.current.value = ''; ref_input5.current.focus(); } }}
                onChange={(text) => { ref_input6.current.blur(); }}
            />
        </>
    )
}

export default AuthCode; 