const SubTitle = (props) => {
  let { valueVisible, text, value } = props;

  return (
    <div className={valueVisible ? "show row cpt-10 cpb-10 cpl-10 cpr-10 backgroundColor-Type3" : "hide"}>
      <div className="headerMain2">
        <div className={valueVisible ? "show" : "hide"}>
          {/* <img className={props.menuIconVisible === "true"? "show": "hide"} src={process.env.PUBLIC_URL + '/Assets/icons/Menu Icon.svg'} alt="menu"></img> */}
          {/* <img className={props.backIconVisible === "true"? "show": "hide"} src={process.env.PUBLIC_URL + '/Assets/icons/BAck.svg'} alt="backicon"></img> */}
          <label className="show cml-8 font-small2 font-weight-bold">{text}</label>
        </div>
        <div className={valueVisible ? "show" : "hide"}>
          <label className="show cmr-10 font-mediumBold font-weight-500">{value}</label>
        </div>
      </div>
    </div>
  );
};

export default SubTitle;