const CountTile = (props) => {
  let {count, text} = props;

    return (
        <div className="cmb-30 backgroundColor-Type3 countTile cml-18 cmr-20 cmt-15">        
        <div className="cpl-15 cpt-7 cpr-10 cpb-7 countText">
            <label className="cpr-10 font-mlarge textColor-Type6">{count}</label>
            <b className="bl-max"></b>
            <label className="cpl-10 font-large-md textColor-Type1">{text}</label>
        </div>                         

        </div>
    );
  };
  
  export default CountTile;