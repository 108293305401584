import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

const ScanTwoFields = ({ blueTitle, unboundedText, customFields, updateFields, unboundedText1, boundedText, throw_type, button_type, Input1Placeholder, Input2Placeholder, onScanHandler, scanValue, debounceInput }) => {
    const { keyboardType } = useSelector(state => state.slice);
    const [inputMode, setInputMode] = useState('none');
    const inputRef1 = useRef();
    const inputRef2 = useRef();

    useEffect(() => setInputMode('none'), [unboundedText, boundedText, Input1Placeholder, Input2Placeholder])

    const handleInputFields = (e) => {
        console.log(e.target.name);
        updateFields({ ...customFields, [e.target.name]: e.target.value })
    }

    const showKeyboard = () => {
        setInputMode('text');
        inputRef1.current.inputMode = 'text';
        inputRef2.current.inputMode = 'text';
    }

    return (
        <div className={"cmt-10 mainScanTile backgroundColor-Type3 relative"}>
            <div className={"backgroundColor-Gradient uperScaner-div-4"} >
                {blueTitle && <div className="uperSD-Text font-small pick-locations-title-1 w-100 cmt-10">{blueTitle}</div>}
                <div style={{ padding: '35px 0px 25px' }}>
                    <div className="uperSD-Text font-large-md pick-locations-title">{unboundedText}
                        {unboundedText1 && <div className="textColor-Type2 font-smallBold">{unboundedText1}</div>}
                    </div>
                    {boundedText && <div className="uperSD-boundedText font-xlarge">{boundedText}</div>}
                </div>

            </div>
            <div className="backgroundColor-Type3 scanner-div-with-2-input cpt-15 cmb-15">
                <div className={`backgroundColor-Type4 scannerInput-4 field mb-2`} style={{ "width": button_type ? "80%" : "95%" }}>
                    <input type={keyboardType}
                        autoFocus
                        ref={inputRef1}
                        value={customFields.customReference1}
                        name="customReference1"
                        className="backgroundColor-Type4 inputscan"
                        onPaste={(e) => {
                            e.preventDefault()
                            return false;
                        }}
                        onCopy={(e) => {
                            e.preventDefault()
                            return false;
                        }}
                        onClick={showKeyboard}
                        inputMode={inputMode}
                        onKeyUp={onScanHandler}
                        placeholder={Input1Placeholder}
                        onChange={handleInputFields}
                    />

                </div>
                <div className={`backgroundColor-Type4 scannerInput-4 field cmb-15`} style={{ "width": button_type ? "80%" : "95%" }}>
                    <input type={keyboardType}
                        name="customReference2"
                        ref={inputRef2}
                        value={customFields.customReference2}
                        className="backgroundColor-Type4 inputscan"
                        onPaste={(e) => {
                            e.preventDefault()
                            return false;
                        }}
                        onCopy={(e) => {
                            e.preventDefault()
                            return false;
                        }}
                        onClick={showKeyboard}
                        inputMode={inputMode}
                        onKeyUp={onScanHandler}
                        placeholder={Input2Placeholder}
                        onChange={handleInputFields}
                    />
                </div>
            </div>
        </div>
    )
}

export default ScanTwoFields;
