import { Logout } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppHeader from "../Components/appHeader";
import * as actions from "../Redux/mainRedux/mainActions";
import { slice } from "../Redux/mainRedux/mainSlice";

const ClientSideList = ({ clientScreenHeader, clientdataHeader, clientdata, setclientTitle, sidebarclick, setSidebarClose }) => {
  const { baseAPIURL, menuResponse, token, sessionStorage } = useSelector(state => state.slice);
  const dispatch = useDispatch();

  const [sidebar, setSidebar] = useState(false);

  useEffect(() => {
    if(sidebarclick){
      setSidebar(true)
    } else
    (
      setSidebar(false)
    )
  }, [sidebarclick])

  const onBackClick = () => {
    setSidebar(false)
    setSidebarClose(false)
  }
  const onClientSelect = (client) => {
    const body = {  ...client.call_back_url_params, apiSessionStorage: sessionStorage };
    dispatch(actions.without_PageSetting('', client.call_back_url, body, 'post', token, ''));
    setclientTitle(client.name)
    setSidebar(false)
    setSidebarClose(false)
}

  return <>
    <nav className={sidebar ? "client-menu client-menuactive" : "client-menu"}>
    <div>
            <AppHeader
                menuIconVisible={false}
                backIconVisible={true}
                textVisible={true}
                onBackClick={onBackClick}
                text={clientScreenHeader}
            />

       <div className="clientTable cpl-8 cpr-8 cpb-8 cpt-15" style={{ overflow: "scroll", padding: "8px", width: "375px" }}>
        <div style={{ overflow: "scroll", width: "500px" }}>
        <div className="title font-large cpb-10 cmr-10 cpl-10">
        {clientdataHeader && clientdataHeader.map((client, index) => (
                  <div key={index}>{client}</div>

        ))}
          </div>

        {clientdata && clientdata.map((client, index) => (
                  <div key={index} className="detail bg-white font-large py-3 cpl-10 cmb-6 cmr-10" onClick={() => onClientSelect(client)}>
                  <div >{client.name ? client.name : '-'}</div>
                  <div >{client.client_code ? client.client_code : '-'}</div>
                  <div >{client.state_province ? client.state_province : '-'}</div>
                  <div >{client.country ? client.country : '-'}</div>
                  </div>
        ))}
        </div>


        </div>
        </div>
    </nav>
  </>

}

export default ClientSideList;