import React, { useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';

const WarningMessage = (props) => {
  const { open, warning, onClose } = props;
  const closeIconStyle = {
    top: "10px",
    position: "absolute",
    right: "15px"
  }

  useEffect(() => {
    return () => {
      const inputs = document.querySelectorAll('input');
      inputs.forEach((input) => input.focus());
    }
  }, []);

  return (
    <Dialog className="dialog" onClose={onClose} open={open}>

      <div className={`errorMessage`}>

        <div className="row w-100 position-sticky top-0 bottom-0 bg-white cpb-10">

          <div className="col-12 text-center cpt-10">

            <img src={process.env.PUBLIC_URL + '/Assets/icons/warning.svg'} alt="line" />
          </div>
          <div className="col-12 text-center"><label>{warning?.detail}</label></div>
        </div>
      </div>
    </Dialog>

  )
};
export default WarningMessage;