/**
 * @SearchableGrid is screen component.
 * This is a dynamic grid with the search functionality.
**/
import AppHeader from "../Components/appHeader";
import List from "../Components/list";
import { FloatingSearch } from "../Components/FloatingSearch";
import { useEffect, useRef, useState } from "react";
import BottomSlide from "../Components/bottomSlide";
import AppButton from "../Components/appButton";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../Redux/mainRedux/mainActions";
import ErrorMessage from "../Components/errorMessage";
import { slice } from "../Redux/mainRedux/mainSlice";
import { hideKeyboard } from '../utility/hideKeyboard';
import { isRequestPending } from "../Redux/mainRedux/mainCrud";

export function SearchableGrid() {
    const { baseAPIURL, searchGrid, token, sessionStorage, apiSuccess, error, warning, success } = useSelector(state => state.slice);
    const dispatch = useDispatch();
    const [showPermission, setShowPermission] = useState(true);
    const [errorOpen, setErrorOpen] = useState(false);
    const [scanError, setScanError] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [hideArea, setHideArea] = useState(true);
    const [showExitPopupVal, setExitPopupVal] = useState(false);
    const [checkBottomSlider, setBottomSlider] = useState(false);
    const [checkBottomSliderVal, setBottomSliderVal] = useState(null);
    const inputRef = useRef(null);

    useEffect(() => {
        console.log(hideArea);
    }, [hideArea])

    window.scrollEnable = function () {
        console.log('scrollEnable called!');
        setHideArea(false)
    }

    window.scrollDisable = function () {
        console.log('scrollDisable called!');
        setHideArea(true)
    }
    let scanErrorMessage = {
        'detail': 'Tote Id is required.'
    }

    const handleSearch = (e) => {
        e.preventDefault();
        // console.log(isRequestPending);
        if (searchText && !isRequestPending) {
            // console.log("Search Text: ", searchText);
            let body = { ...searchGrid?.call_back_url_params, orderNumber: searchGrid?.search_object?.value[0]?.orderNumber, toteId: searchText, apiSessionStorage: sessionStorage };
            dispatch(actions.generic_call(baseAPIURL, searchGrid?.call_back_url, body, 'post', token));
            setSearchText('');
            hideKeyboard();
        } else if (isRequestPending) {
            // console.log("request pending? ", isRequestPending);
            return setSearchText('');
        } else {
            // debugger;
            hideKeyboard();
            setScanError(true);
            setErrorOpen(true);
            setSearchText('');
        }
    }

    // on clicking 
    const nextBinPage = () => {
        let body = { ...searchGrid?.btn_option?.on_click_url_params, apiSessionStorage: sessionStorage };
        dispatch(actions.generic_call(baseAPIURL, searchGrid?.btn_option?.on_click_url, body, 'post', token));
        onPermissionClose();
    }

    // For Error Message
    const errorCloseHandler = () => {
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ name: 'hide_keyboard' }))
        }
        setErrorOpen(false);
        setScanError(false);
        dispatch(slice.actions.clearError());
    };
    useEffect(() => {
        setSearchText('');
        // check error
        if (error) {
            setErrorOpen(true)
        }
        else {
            setErrorOpen(false)
        }
    }, [error]);

    const onPermissionClose = () => {
        inputRef.current.focus()
        setShowPermission(false);
        // forcfully close the keyboard
        hideKeyboard();
    }

    const exitClickPopup = (val) => {
        setExitPopupVal(val);
    }

    const routeOnExit = (callbackparam, callback_url) => {
        setExitPopupVal(false);
        let body = { ...callbackparam, apiSessionStorage: sessionStorage };
        dispatch(actions.generic_call(baseAPIURL, callback_url, body, 'post', token));
    }

    const onSubHeaderDescClick = (e) => {
        if (e.length > 24) {
            setBottomSlider(true)
            setBottomSliderVal(null)
            setBottomSliderVal(e)
        }
    }

    const closeHandle = () => {
        setBottomSlider(false)
    }
    return (
        <div>
            <AppHeader
                menuIconVisible={false}
                backIconVisible={false}
                textVisible={true}
                text={searchGrid?.title}
                waveDesc={searchGrid?.description}
                onHeaderDescClick={onSubHeaderDescClick}
                warehouseName={searchGrid?.warehouse_selector_button_text}
                warehouseEnable={searchGrid?.warehouse_list?.length > 0 ? true : false}
            />
            <div className="d-flex justify-content-between px-4 py-2 font-weight-500" style={{ "borderBottom": "1px solid #34495E4D" }}>
                <label>{searchGrid?.item.title}</label>
                <label>{searchGrid?.item.value}</label>
            </div>
            <div className="px-2">
                <List
                    textAling="left-right"
                    showsearch={false}
                    listtitle={searchGrid?.search_object?.title}
                    prifix='ORD-'
                    outlineClass='outLine'
                    listdata={searchGrid?.search_object?.value}
                    onRowClick={() => { }}
                />

                <FloatingSearch
                    inputRef={inputRef}
                    inputType="text"
                    placeholder={searchGrid?.scan_text}
                    inputValue={searchText}
                    inputHandler={(text) => setSearchText(text)}
                    handleSubmit={handleSearch}
                    exitButtonData={searchGrid?.mini_exit_btn}
                    exitClickPopup={(val) => exitClickPopup(val)}
                />
            </div>

            {searchGrid?.btn_option &&
                <BottomSlide open={showPermission} closeHandle={setShowPermission}>
                    <div className="w-100">
                        <h5 className="text-center my-4">{searchGrid?.popup_text}</h5>
                        <div className="d-flex gap-2 p-2">
                            <AppButton displayText="Yes" onClick={onPermissionClose} />
                            <AppButton displayText="No" onClick={() => nextBinPage()} isOutlined={true} />
                        </div>
                    </div>
                </BottomSlide>
            }

            <BottomSlide open={showExitPopupVal} closeHandle={() => exitClickPopup(false)}>
                <div className="w-100">
                    <h5 className="text-center my-4">
                        {searchGrid?.mini_exit_btn?.blue_text}
                    </h5>
                    <div className="d-flex gap-2 p-2">
                        <AppButton
                            displayText={searchGrid?.mini_exit_btn?.btn_option[0].title}
                            onClick={() => routeOnExit(
                                searchGrid?.mini_exit_btn?.btn_option[0].on_click_url_params,
                                searchGrid?.mini_exit_btn?.btn_option[0].on_click_url
                            )}
                        />
                        <AppButton
                            displayText={searchGrid?.mini_exit_btn?.btn_option[1].title}
                            onClick={() => exitClickPopup(false)}
                            isOutlined={true}
                        />
                    </div>
                </div>
            </BottomSlide>
            <BottomSlide
                children={checkBottomSliderVal}
                open={checkBottomSlider}
                closeHandle={closeHandle}
            />
            {errorOpen && <ErrorMessage open={errorOpen} onClose={errorCloseHandler} error={scanError ? scanErrorMessage : error} />}
        </div>
    );
}