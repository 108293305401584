import React from "react";
import ReactAudioPlayer from "react-audio-player";
import { useSelector } from "react-redux";

const AudioPlayer = (props) => {
  const { sound, loop, muted } = props;
  return (
      <ReactAudioPlayer
      src={process.env.PUBLIC_URL + sound}
      autoPlay
      controls
      className="hide"
      loop={loop}
      // muted={totalTimeSpend>timerdetail?.cutoff_2_time ? false:true}
      muted={muted}
    />
  );
};

export default AudioPlayer;
