import React, { useState, useLayoutEffect, useEffect, useRef } from "react";
import AppButton from "../Components/appButton";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../Redux/mainRedux/mainActions";
import { Alert } from '@mui/material';
import { slice } from "../Redux/mainRedux/mainSlice";

const Login = () => {
  const dispatch = useDispatch();
  const { error, forgetPassword, totpData } = useSelector(state => state.slice);
  const [userValue, setUserValue] = useState({ value: '', error: null })
  const [passwordValue, setPasswordValue] = useState({ value: '', error: null });
  const [passwordType, setPasswordType] = useState("password");
  const usernameref = useRef(null);
  const passwordref = useRef(null);
  const loginURL=''; // http://192.168.88.186:8080/outbound-service/mobile/V3_0_0/

  const error1 = "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)."
  const style = {
    row: {
      flexDirection: 'row',
      marginTop: 4,
      bottom: 25,
      position: 'absolute'
    },
  }

  const loginHandler = e => {
    e.preventDefault();
    dispatch(slice.actions.clearError());
    dispatch(slice.actions.clearforgetPassword());
    dispatch(slice.actions.setUserCredentials({ userValue, passwordValue }));
    if (document.activeElement === usernameref.current && e.key === "Enter") {
      userValue.value ? passwordref.current.focus() : setUserValue({ ...userValue, error: true });
    }else if (document.activeElement === passwordref.current && e.key === "Enter" || e.type === "submit") {
      let isValidated = checkValidation();
      if (isValidated) {
        let body = { username: userValue.value, password: passwordValue.value }
        dispatch(actions.generic_call(loginURL, 'login', body, 'post', null, 'home'));
      }
    }

  }

  const showKeyboardHandler = () => {
    if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ name: 'hide_keyboard_off' }))
    }
}

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
    } else {
      setPasswordType("password")
    }
  }

  const forgotPassword = () => {
    if (userValue.value) {
      let body = { username: userValue.value }
      dispatch(actions.generic_call('', 'forgotPassword', body, 'post', null, 'forgetPassword'));

    } else {
      setUserValue({ ...userValue, error: true });
    }

  }

  useEffect(() => {
    showKeyboardHandler();
  }, [])


  const checkValidation = () => {
    let isValidate = true;
    if (!userValue.value) {
      setUserValue({ ...userValue, error: true });
      isValidate = false;
    }

    if (!passwordValue.value) {
      setPasswordValue({ ...passwordValue, error: true });
      isValidate = false;
    }
    return isValidate;
  }

  return (
    <div className="row">
      <div className="col-sm-12 col-xs-12">
        <img className="floatRight" src={process.env.PUBLIC_URL + '/Assets/logo/SphereWms.svg'} alt="sphereLogo" />
      </div>
      <div className="col-sm-12 col-xs-12 ">
        <div className="cml-30 font-xxlarge">Sign in</div>
      </div>
      {
        error &&
        <div className="col-10 offset-1 cmt-25">
          <Alert variant="filled" severity="error">
            {error.detail}
          </Alert>
        </div>
      }

      <div className="col-sm-12 col-xs-12">
        <form className="cml-20 cmr-20 cmt-30" onSubmit={loginHandler}>
          <div className="cml-10 cmr-10 cmt-10 cmb-10 input-container">
            <label className="font-large">User ID </label>
            <input 
            placeholder="Enter User"
            className={`cpt-20 cpb-20 cpl-20 cpr-20 font-large-md textColor-Type1 backgroundColor-Type1 forminput focusinput ${userValue.error && 'error-field'}`} 
            type="text" 
            name="uname" 
            onChange={(e) => setUserValue({ value: e.target.value, error: null })} 
            ref={usernameref}
            onKeyUp={loginHandler}
            required
            />
            {userValue.error && <p className="font-smallBold textColor-LoginError">This is required Field </p>}
          </div>
          <div className="cml-10 cmr-10 cmt-10 cmb-10 input-container">
            <label className="font-large">Password </label>
            <div className={`cpt-22 cpb-22 passInput forminput ${passwordValue.error && 'error-field'}`}>
              <input
              placeholder="Enter Password"
                className={`cpt-20 cpb-20 cpl-20 cpr-20 font-large-md textColor-Type1 backgroundColor-Type1 forminput ${passwordValue.error ? 'errorfocusinput' : 'focusinput'}`}
                type={passwordType}
                name="pass"
                onChange={(e) => setPasswordValue({ value: e.target.value, error: null })}
                ref={passwordref}
                onKeyUp={loginHandler}
                required
              />
              {passwordType === "password"
                ? <img className="floatRight eyeImage cpr-10" src={process.env.PUBLIC_URL + '/Assets/icons/EyeClose.png'} alt="line" onClick={togglePassword} />
                : <img className="floatRight eyeImage cpr-10" src={process.env.PUBLIC_URL + '/Assets/icons/EyeOpen.png'} alt="line" onClick={togglePassword} />}
            </div>

            {passwordValue.error && <p className="font-smallBold textColor-LoginError">This is required Field </p>}
          </div>
          <div className="cml-30 cmr-30 cmt-30 cmb-30 formsubmit-container">
            <AppButton displayText="Sign in" />
          </div>
        </form>
      </div >
      {forgetPassword &&
        <div className="col-sm-12 col-xs-12">
          <div className="cml-20 cmr-20 forgot">
            <label>We have e-mailed your password reset link!</label>
          </div>
        </div>
      }
      <div className="col-sm-12 col-xs-12">
        <div className="cmt-29 formforgotpassContainer font-regular">Forgot Passcode? <span className="textColor-Type5" onClick={forgotPassword} >&nbsp;Click Here</span></div>
      </div>
      {/* <SuccessMessage open={open} onClose={handleClose}/> */}
    </div >
  );
};

export default Login;
