import React, { useEffect, useState, useRef } from "react";
import AppHeader from "../Components/appHeader";
import TimerFooter from "../Components/timerFooter";
import PickLocationsTile from "../Components/PicklocationsTile";
import ScanValidateTileWithCondition from "../Components/ScanValidateTileWithCondition";
import ScanScreenSubHeader from "../Components/ScanScreenSubHeader";
import Section from "../Components/section";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../Redux/mainRedux/mainActions";
import { slice } from "../Redux/mainRedux/mainSlice";
import ErrorMessage from "../Components/errorMessage";
import DroppedPackagesList from "../Components/droppedPackagesList";
import AudioPlayer from "../Components/audioPlayer";
import { BottomSheet } from '../Components/BottomSheet';
import BottomSlide from "../Components/bottomSlide";
import AppButton from "../Components/appButton";
import { hideKeyboard } from '../utility/hideKeyboard';

const ScanTypeValidateWithQuantity = (props) => {
  const { baseAPIURL, validate2, token, sessionStorage, apiSuccess, error, warning, success, keyboardStatus, userType } = useSelector(state => state.slice);
  const dispatch = useDispatch();
  const [scanVal, setScanVal] = useState(null)
  const [errorOpen, setErrorOpen] = useState(false);
  const [hideArea, setHideArea] = useState(true)
  const [checkPopupHolder, setPopupHolder] = useState(null);
  const [scanError, setScanError] = useState(false);
  const [checkPackageList, setPackageList] = useState(false)
  const [checkWarehouseSelection, setWarehouseSelection] = useState(null)
  const [checkSuccessBeep, setSuccessBeep] = useState(false)
  const [showBottomList, setShowBottomList] = useState([]);
  const [showExitPopupVal, setExitPopupVal] = useState(false);
  const [checkBottomSlider, setBottomSlider] = useState(false);
  const [checkBottomSliderVal, setBottomSliderVal] = useState(null);


  let sound2 = "/Assets/audio/goodscan.mp3";
  let sound3 = "/Assets/audio/badscan.mp3";

  let scanErrorMessage = {
    'detail': 'Barcode not recognized. Please scan again properly.'
  }
  window.scrollEnable = function () {
    setHideArea(false)
  }
  window.scrollDisable = function () {
    setHideArea(true)
  }
  const inputRef = useRef();
  const scanHandler = e => {
    e.preventDefault()
    if (e.key === "Enter" || e.type === "click") {
      if (inputRef.current.value) {
        setSuccessBeep(false)
        dispatch(slice.actions.setApiSuccess(false))
        if (checkWarehouseSelection) {
          let body = { ...checkWarehouseSelection.call_back_url_params, scan_value: inputRef.current.value, apiSessionStorage: sessionStorage };
          inputRef.current.value = "";
          // window.ReactNativeWebView.postMessage(JSON.stringify({ name: 'hide_keyboard' }))
          // inputRef.current.focus();
          dispatch(actions.generic_call(baseAPIURL, checkWarehouseSelection.call_back_url, body, 'post', token));
        } else {
          let body = { ...validate2?.regional_throw_skip[0]?.call_back_url_params, scan_value: inputRef.current.value, apiSessionStorage: sessionStorage };
          inputRef.current.value = "";
          // window.ReactNativeWebView.postMessage(JSON.stringify({ name: 'hide_keyboard' }))
          // inputRef.current.focus();
          dispatch(actions.generic_call(baseAPIURL, validate2?.regional_throw_skip[0]?.call_back_url, body, 'post', token));
        }

      } else {
        hideKeyboard();
        setScanError(true);
        setErrorOpen(true);
      }
    }
  }

  useEffect(() => {
    const audio = new Audio((error?.code === 409 ? sound3 : sound2));
    audio.play();
  }, [validate2, error]);

  // For Error Message
  const errorCloseHandler = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ name: 'hide_keyboard' }))
    }
    setScanError(false);
    setErrorOpen(false);
    if (error.call_back_url && error.call_back_url_params) redirectAfterError(error)
    dispatch(slice.actions.clearError());
  };

  // This will only come when there is RLLP is already created or call back URL is comes in error block
  const redirectAfterError = (data) => {
    let body = { ...data.call_back_url_params, apiSessionStorage: sessionStorage };
    dispatch(actions.generic_call(baseAPIURL, data.call_back_url, body, 'post', token));
  }

  useEffect(() => {
    // check error
    if (error) {
      setErrorOpen(true)
      if (error.code === 306) {
        setTimeout(() => {
          redirectAfterSessionExpire();
          localStorage.clear();
          setErrorOpen(false)
          dispatch(slice.actions.clearError());
        }, 2000);
      }
    }
    else {
      setErrorOpen(false)
    }

    // // check success
    // if (success) {
    //   setSuccessOpen(true)
    //   setTimeout(() => {
    //     setSuccessOpen(false);
    //     dispatch(slice.actions.clearSuccess());
    //     redirectAfterSuccess();
    //   }, 2000);

    // } else setSuccessOpen(false)
    // check warning
    // if (warning) {
    //   setWarningOpen(true)
    //   setTimeout(() => {
    //     setWarningOpen(false);
    //     dispatch(slice.actions.clearWarning());
    //   }, 2000);
    // } else {
    //   setWarningOpen(false)
    // }

  }, [error]);

  // On selecting drop package list
  const onClickDropPackageList = () => {
    setPackageList(true)
  }

  const redirectAfterSessionExpire = () => {
    dispatch(slice.actions.setPage('login'));
  }

  const handleSubheader = () => {
    const { onclick_bottom_popup } = validate2.pallet;
    if (onclick_bottom_popup && onclick_bottom_popup.length) {
      setShowBottomList(onclick_bottom_popup);
    }
  }

  const exitClickPopup = (val) => {
    setExitPopupVal(val);
  }

  const routeOnExit = (callbackparam, callback_url) => {
    setExitPopupVal(false);
    let body = { ...callbackparam, apiSessionStorage: sessionStorage };
    dispatch(actions.generic_call(baseAPIURL, callback_url, body, 'post', token));
  }

  const onSubHeaderDescClick = (e) => {
    if (e.length > 24) {
      setBottomSlider(true)
      setBottomSliderVal(null)
      setBottomSliderVal(e)
    }
  }

  const closeHandle = () => {
    setBottomSlider(false)
  }

  return (
    <>
      {!checkPackageList &&
        <>
          <AppHeader
            menuIconVisible={false}
            backIconVisible={false}
            textVisible={true}
            text={validate2.title}
            waveDesc={validate2?.description}
            onHeaderDescClick={onSubHeaderDescClick}
            warehouseName={validate2?.warehouse_selector_button_text}
            warehouseEnable={validate2?.warehouse_list?.length > 0 ? true : false}
          />
          <ScanScreenSubHeader
            title1={validate2?.item?.title}
            value1={validate2?.item?.value}
            title2={validate2?.pallet?.title}
            value2={validate2?.pallet?.value}
            title3={validate2?.lot_title ? validate2?.lot_title?.title : null}
            value3={validate2?.lot_title ? validate2?.lot_title?.value : null}
            onSubHeaderClick={handleSubheader}
            remainValue={{ total: validate2?.qty_requested_pick, remaining: validate2?.qty_remaining }}
            remainText={validate2?.remaining_text}
          />
          <div className="cpt-10 cpl-15 cpr-15 cpb-15 backgroundColor-Type5 mainPageType2">
            <div className="wfScreen">
              <div className="row">
                {validate2.upper_left &&
                  <div className="col-sm-6 col-xs-6 col-6 cpr-5">
                    <PickLocationsTile text={validate2.upper_left.title} value={validate2.upper_left.value} />
                  </div>}
                {validate2.upper_right &&
                  <div className="col-sm-6 col-xs-6 col-6 cpl-2">
                    <PickLocationsTile text={validate2.upper_right.title} value={validate2.upper_right.value} />
                  </div>}
                <div className="col-sm-12 col-xs-12 col-12 cp-custom">
                  <ScanValidateTileWithCondition
                    unboundedText={validate2.blue_text}
                    boundedText={validate2.blue_dotted_text_first}
                    InputPlaceholder={validate2.scan_text}
                    DefaultValue={validate2?.default_scan_value} // default_scan_value
                    text1={validate2.blue_dotted_text_second}
                    count={validate2.item_qty_text}
                    dropoptions={validate2.regional_throw_skip}
                    button_type={validate2?.button_type}
                    throw_type={validate2?.throw_type}
                    onScanHandler={scanHandler}
                    scanValue={setScanVal}
                    inputRef={inputRef}
                    scanText={scanVal}
                    onKeyUp={scanHandler}
                    setWarehouseSelection={setWarehouseSelection} />
                </div>
                {validate2?.dropPackage &&
                  <div className="col-12">
                    <div className="d-flex">
                      <img src={process.env.PUBLIC_URL + '/Assets/icons/Group1.png'} alt="drop" />
                      <p className="textColor-Type1 mb-0 font-xsmall-1 cml-5">Drop Package</p>
                    </div>
                  </div>}

                <div className="col-sm-12 col-xs-12 col-12 cmtRem-21 place-carton">
                  <Section left={validate2.framed_footer_box_text} right={validate2.framed_footer_box_right_justified} />
                </div>
                {validate2.serialnumber &&
                  <div className="col-12">
                    <div className="serial-number-table">
                      <li className="flexspacebtw">
                        <div className="font-small2 textColor-Type1 font-weight-bold">Serial Number</div>
                        <div className="font-small2 textColor-Type1 font-weight-bold">Pick Complete</div>
                      </li>
                      {validate2.serialnumber.map(item => (
                        <li className="flexspacebtw" key={item}>
                          <div className="font-small textColor-Type1">{item.serial_number}</div>
                          <div className="font-small w-30 text-center"><img src={process.env.PUBLIC_URL + item.image} alt="line" /></div>
                        </li>
                      ))}
                    </div>
                  </div>}

                {validate2?.dropPackageList &&
                  <div className="row justify-content-center footer fixed-bottom cmb-20 cml-0">
                    <div className={" d-flex align-items-center justify-content-center cpt-10 cpb-10 col-6 dropped-pkg-holder enableTile"} onClick={() => onClickDropPackageList()}>
                      <img className="cpr-7" src={process.env.PUBLIC_URL + '/Assets/icons/Drop.png'} alt="swapicon"></img>
                      <label className="drop-title">{validate2?.dropPackageList?.title}</label>
                    </div>
                  </div>}

              </div>
              {validate2?.mini_exit_btn && hideArea &&
                <div className="d-flex col-sm-6 col-xs-6 cpl-2">
                  <div className={"ExitTile backgroundColor-Type3 font-small"} onClick={() => exitClickPopup(true)}>
                    <span className="font-medium textColor-Type8">{validate2?.mini_exit_btn?.btn_text}</span>
                  </div>
                </div>
              }
            </div>
          </div>

          {
            <BottomSlide open={showExitPopupVal} closeHandle={() => exitClickPopup(false)}>
              <div className="w-100">
                <h5 className="text-center my-4">{validate2?.mini_exit_btn?.blue_text}</h5>
                <div className="d-flex gap-2 p-2">
                  <AppButton displayText={validate2?.mini_exit_btn?.btn_option[0].title} onClick={() => routeOnExit(validate2?.mini_exit_btn?.btn_option[0].on_click_url_params, validate2?.mini_exit_btn?.btn_option[0].on_click_url)} />
                  <AppButton displayText={validate2?.mini_exit_btn?.btn_option[1].title} onClick={() => exitClickPopup(false)} isOutlined={true} />
                </div>
              </div>
            </BottomSlide>
          }

          <BottomSheet open={showBottomList.length > 0} closeHandle={() => setShowBottomList([])}>
            <table id="order-table" className="w-100">
              {showBottomList.map(row => (
                <tr>
                  <td>{row.index}</td>
                  <td>{row.order_numer}</td>
                  <td>{row.qty}</td>
                </tr>
              ))}
            </table>
          </BottomSheet>
          <BottomSlide
            children={checkBottomSliderVal}
            open={checkBottomSlider}
            closeHandle={closeHandle}
          />
        </>
      }
      {/* {((validate2?.beep_code === 200 && checkSuccessBeep) || (error?.code === 409)) &&
              <AudioPlayer
                sound={validate2?.beep_code === 200 ? sound2 : (error?.code === 409 ? sound3 : '')}
                loop={false}
                muted={false}
              />
    } */}
      {errorOpen && <ErrorMessage open={errorOpen} onClose={errorCloseHandler} error={scanError ? scanErrorMessage : (checkPopupHolder ? checkPopupHolder : error)} />}
      {checkPackageList &&
        <DroppedPackagesList
          setPackageList={setPackageList}
          dropPackageData={validate2?.dropPackageList?.detail}
        />
      }
    </>
  );
};

export default ScanTypeValidateWithQuantity;
