import React, { useEffect, useState, useRef } from "react";
import AppHeader from "../Components/appHeader";
import AppButton from "../Components/appButton";
import * as actions from "../Redux/mainRedux/mainActions";
import { slice } from "../Redux/mainRedux/mainSlice";
import { useDispatch, useSelector } from "react-redux";




const About = () => {
    const dispatch = useDispatch();

    const onBackClick = () => {
        // localStorage.clear();
        // let body = { identifier: tile2?.back_button?.identifier, apiSessionStorage:sessionStorage }
        // dispatch(actions.generic_call(tile2?.back_button?.callback_url, body, 'post', token));
        dispatch(slice.actions.setPage('settings'))

    }

    return (
        <> <AppHeader
            pauseStatus={false}
            checkCountdown={false}
            menuIconVisible={false}
            backIconVisible={true}
            onBackClick={onBackClick}
            textVisible={true}
            text={"About"}
            warehouseName={""}
            warehouseEnable={false}
        />
            <div className="row">
                <div className="col-sm-12 col-xs-12">
                    <img className="floatRight" src={process.env.PUBLIC_URL + '/Assets/logo/Sphere wms Logo.svg'} alt="sphereLogo" />
                </div>
                <div className="mx-auto mt-4 text-center col-sm-6 col-xs-6">
                    <img src={process.env.PUBLIC_URL + '/Assets/icons/Sphere-wms.png'} alt="spherelogo" />
                    <p style={{ marginTop: "30px" }} className="font-medium">
                        For questions or Feedback about<br /> SphereWMS, using below <br /> information:
                    </p>


                    <div className="mt-4 d-flex justify-content-center align-items-center" >
                        <img style={{ marginRight: "5px" }} className="" src={process.env.PUBLIC_URL + '/Assets/icons/message.png'} alt="message" />
                        <p className=" mb-0 font-medium ">
                            support@spherewms.com
                        </p>
                    </div>
                    <div className="d-flex justify-content-center align-items-center" style={{ marginBottom: "auto", flexGrow: "1" }} >
                        <img style={{ marginRight: "5px" }} className="" src={process.env.PUBLIC_URL + '/Assets/icons/phone.png'} alt="phone" />
                        <p className="mt-2 mb-0 font-medium ">
                            +1.818.678.2601
                        </p>
                    </div>
                    <div>
                        <p className="font-xsmall-setting about-footer ">Copyright © 2022<br />
                            APS Global Services, Inc. Version 3.0.1</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About