import { useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateLookup } from "../Redux/mainRedux/mainActions";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { debounce } from "../utility/debounce";
import { slice } from '../Redux/mainRedux/mainSlice';
import { isRequestPending } from "../Redux/mainRedux/mainCrud";

/**
 * @textAling Prop
 * Default all text align left
 * value = left-right will align left to left column and right to right column
**/
const List = (props) => {
	const { showsearch, listtitle, listdata, onsearchhandler, setSearchValue, inputref, ondetailclick, onRowClick, textAling = 'left', outlineClass, serchLoading, lookupField } = props;
	const state = useSelector(state => state.slice);
	const { baseAPIURL, token, sessionStorage } = state;
	const [progressiveLoading, setProgressiveLoading] = useState(false);
	const [serchList, setSerchList] = useState([]);

	const listRef = useRef(null);
	const dispatch = useDispatch();

	window.setProgressiveLoading = setProgressiveLoading;

	useLayoutEffect(() => {
		const list = listRef.current;
		let lastScrolled = 0;
		// payload to send on backend
		let body = {
			page: 1,
			apiSessionStorage: sessionStorage,
			is_json: true,
			...state[lookupField]?.call_back_url_params,
			...(state[lookupField]?.search_loading
				&& state[lookupField]?.search_loading.call_back_url_params),
		}

		const scrollHandler = (e) => {
			const visibleHeight = list.offsetHeight;
			const totalHeight = list.scrollHeight;
			let totalScrolled = Math.round(list.scrollTop + visibleHeight);
			let isScrollEnd = false;
			// moveout and do nothing
			// if (list.scrollTop <= lastScrolled) return;

			lastScrolled = list.scrollTop <= 0 ? 0 : list.scrollTop;
			if (totalScrolled < totalHeight) {
				isScrollEnd = totalScrolled + 1 >= totalHeight;
			} else {
				isScrollEnd = totalScrolled >= totalHeight;
			}

			console.log({ isScrollEnd });
			if (isScrollEnd && state[lookupField]?.search_loading && !isRequestPending) {
				body.page += 1;
				console.log(body.page);

				dispatch(updateLookup(
					baseAPIURL, serchLoading.callback_url, body, 'post', token, lookupField, undefined
				));
			}
		}

		list.addEventListener('scroll', scrollHandler);

		return () => list.removeEventListener('scroll', scrollHandler);
	}, [])

	const debounceSearch = debounce((searchText) => {
		inputref.current.blur();
		setSearchValue(searchText)
	}, 1500);

	function searchHandler(e) {
		if (state[lookupField]?.search_loading) {
			console.log('search api');
			debounceSearch(e.target.value)
		} else {
			// client side searching
			const searchTerm = e.target.value.toLowerCase();

			const filtered = state[lookupField]?.search_object.value.filter(item => {
				let searchValue = item['value'].toLowerCase();
				return searchValue.indexOf(searchTerm) !== -1;
			})

			console.log("Search List: ", filtered);
			setSerchList(filtered);
		}
	}

	return (
		<>
			<div className="col-sm-12 col-xs-12 col-12" style={{ height: 'calc(100vh - 130px)', overflowY: 'auto' }} ref={listRef}>
				{showsearch &&
					<div className="col-12 cpt-10 cpb-10 cpr-10 cpl-10 ScanScreen1Header search_holder align-items-center">
						<img className="floatleft" src={process.env.PUBLIC_URL + '/Assets/icons/search.png'} alt="line" onClick={onsearchhandler} />
						<input
							type="text"
							ref={inputref}
							className="font-family-inherit backgroundColor-Type4 search_input cpl-10"
							placeholder="Search"
							onKeyUp={searchHandler}
							onChange={searchHandler}
						/>
					</div>
				}

				<div className="Inquiry cpt-10 listScroll">

					{listtitle &&
						<div className={`title font-large ${textAling}`}>
							{
								listtitle.map((item, index) => {
									return (
										<div
											key={index}
											className={`tableColumn cpt-15 cpb-15 cpl-15 cpr-15 ${listtitle.length === 1 ? 'text-center' : ''}`}
										>
											{item}
										</div>
									)
								})
							}
						</div>
					}

					<ListCollection
						collection={serchList.length ? serchList : listdata}
						textAling={textAling}
						outlineClass={outlineClass}
						onRowClick={onRowClick}
						ondetailclick={ondetailclick}
					/>

				</div>
			</div>
			{progressiveLoading && (
				<div className="mt-2">
					<Box sx={{ display: 'flex', justifyContent: 'center' }}>
						<CircularProgress size={20} thickness={4} />
					</Box>
				</div>
			)}
		</>
	)
}

export default List;

// list
function ListCollection({ collection, textAling, outlineClass, onRowClick, ondetailclick }) {
	return collection && collection.map((item, index) => (
		<div
			key={index}
			className={`detail bg-white font-small2 cmb-8 mx-2 ${textAling} ${index == 0 ? `${outlineClass}` : ''} `}
			onClick={e => onRowClick(item)}
		>
			{
				// map out no of columns
				Object.keys(item).map((key, index) => (
					<div
						key={index}
						className={
							`tableColumn cpt-15 cpb-15 cpl-15 cpr-15 ${Object.keys(item).length === 1 ? 'text-center' : ''}`
						}
					>
						{key !== 'info' && key !== 'call_back_url_params' && key !== 'callback_url' && key !== 'index' && item[key] ? item[key] : null}
						{key === 'info' ? (
							<img
								className="floatRight"
								src={process.env.PUBLIC_URL + '/Assets/icons/detail.png'}
								alt="detail" onClick={(e) => {
									e.stopPropagation();
									ondetailclick(item)
								}}
							/>
						) : null}
					</div>
				))
			}
		</div>
	))
}
