import { useSelector } from 'react-redux';
import CustomizedProgressBars from './CustomizedProgressBars';

const ScanScreenSubHeader = (props) => {
  let { title1, value1, title2, value2, title3, value3, remainValue, remainText, onSubHeaderClick, gridLayout } = props;
  const { sourceOfRecord } = useSelector(state => state.slice);

  return (
    <div className="col-12 cpt-10 cpb-10 cpl-15 cpr-10 backgroundColor-Type3 ScanScreen1Header font-medium">
      {value2?.length > 24 ? (
        <div className={`Screen4text ${gridLayout ? 'grid-layout' : ''}`} style={{ width: (remainValue && remainText) ? '' : '100%', maxWidth: (remainValue && remainText) ? '' : '100%' }}>
          {title1 && <p className="font-xsmall mb-0 wrapText textColor-Type8">{title1}<span className="font-xsmall font-weight-bold textColor-Type1">{value1}</span></p>}
          {
            title2 &&
            <div style={{ "display": "flex", "justifyContent": "space-between", "alignItems": "center" }}>
              <div className="font-xsmall mb-0 wrapText textColor-Type8" style={{ "width": "89%" }}>
                {title2} <span className="font-xsmall font-weight-bold textColor-Type1" onClick={(e) => { onSubHeaderClick(value2) }}>{value2}</span>
              </div>
              {/* <div className='cpb-3'>
                <img
                  className='floatRight'
                  src={process.env.PUBLIC_URL + "/Assets/icons/Vector.svg"}
                  alt="line" onClick={(e) => { onSubHeaderClick(value2) }}
                />
              </div> */}
            </div>
          }
          {title3 && <p className="font-xsmall mb-0 wrapText textColor-Type8">{title3} <span className="font-xsmall font-weight-bold textColor-Type1">{value3}</span></p>}
        </div>
      ) : (
        <div className={`Screen1text ${gridLayout ? 'grid-layout' : ''}`} style={{ width: (remainValue && remainText) ? '' : '100%' ,"display": "flex", "flexDirection": "column", "justifyContent":"center"}}>
          {title1 && <p className="font-xsmall mb-0 wrapText textColor-Type8">{title1} <span className="font-xsmall font-weight-bold textColor-Type1">{value1}</span></p>}
          {title2 && sourceOfRecord !== 'v2' ? (
            <p className="font-xsmall mb-0 wrapText textColor-Type8">
              {title2} <span className="font-xsmall font-weight-bold textColor-Type1" onClick={(e) => { onSubHeaderClick(value2) }}>{value2}</span>
            </p>
          ) : null}
          {title3 && <p className="font-xsmall mb-0 wrapText textColor-Type8">{title3} <span className="font-xsmall font-weight-bold textColor-Type1">{value3}</span></p>}
        </div>
      )}

      {(remainValue && remainText) &&
        <>
          <div className="Screen1line">
            <img className='cml-5' src={process.env.PUBLIC_URL + "/Assets/icons/line176.svg"} alt="line" />
          </div>
          <div className="Screen1graph">
            <div className="screen1Graphimg cml-15">
              <CustomizedProgressBars
                value={(remainValue.total - remainValue.remaining) / (remainValue.total) * 100}
                complete={remainValue.remaining} />
            </div>
            <div className="font-xxsmall screen1Graphtxt">
              <span className="font-x-min-large textColor-Type1 c-line-height">{remainValue.remaining}</span>
              <p className='mb-0 textColor-Type8 c-line-height-1'>{remainText}</p>
            </div>
          </div>
        </>
      }
    </div>
  );
};

export default ScanScreenSubHeader;
