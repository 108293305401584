import React, { useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../Redux/mainRedux/mainActions";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { isRequestPending } from "../Redux/mainRedux/mainCrud";
import { slice } from "../Redux/mainRedux/mainSlice";
import AppButton from "../Components/appButton";
import { hideKeyboard } from "../utility/hideKeyboard";

const ErrorMessage = (props) => {
  const { open, error, onClose } = props;
  const { baseAPIURL, token, warehouse, sessionStorage } = useSelector(state => state.slice);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      const inputs = document.querySelectorAll('input');
      inputs.forEach((input) => {
        // input.inputMode = 'none';
        hideKeyboard()
        input.focus()
      });
    }
  }, []);
  const closeIconStyle = {
    top: "10px",
    position: "absolute",
    right: "15px"
  }

  const onClickHandler = () => {
    // when user is unlock and try to go back to process
    if (error.detail?.redirect_route) {
      onClose();
      window.location.href = `${error.detail?.redirect_route}`;
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify(error.detail))
      }
    } else {
      let body = { identifier: 'regular', apiSessionStorage: sessionStorage };
      dispatch(actions.generic_call(baseAPIURL, 'workflow', body, 'post', token));
      onClose();
    }
  }

  useEffect(() => {
    if (error?.call_back_url && error?.call_back_url_params) redirectAfterError(error)
  }, [error?.action])

    // This will be called only in auto sync scenario 
    const redirectAfterError = (data) => {
      let body = { ...data.call_back_url_params, apiSessionStorage: sessionStorage };
      dispatch(actions.generic_call(baseAPIURL, data.call_back_url, body, 'post', token));

    }

  return (
    <Dialog className="dialog" open={open}>

        <div className={`errorMessage`}>
        {/* {error?.code===403 || error?.popup_message || error?.action ? ""
        :  <div className="col-12 alignEnd">
        <div onClick={() => {onClose()}}>
        <CloseIcon />
        </div>
        </div> 
        } */}
        <div className="row w-100 position-sticky top-0 bottom-0 bg-white cpb-10">

          {/* <div className="col-10 text-center cpt-10">
        <img className="cml-30" src={process.env.PUBLIC_URL + '/Assets/icons/error.svg'} alt="line" />
        </div> */}
        <div className="col-12 text-center cpt-10">
        {/* <img src={error?.code===403?process.env.PUBLIC_URL + '/Assets/icons/lock.svg':process.env.PUBLIC_URL + '/Assets/icons/error.svg'} alt="line" /> */}
        <img src={error?.popup_message ? process.env.PUBLIC_URL + '/Assets/icons/error.svg' : (error?.code===403 ? process.env.PUBLIC_URL + '/Assets/icons/lock.svg' : process.env.PUBLIC_URL + '/Assets/icons/error.svg')} alt="line" />
        </div>
        {error?.code===403 &&
          <label className="locktitle">User Locked</label>
        }
        <div className="col-12 text-center"><label>{error?.code===403?error?.detail?.message : (error?.popup_message ? error?.popup_message : error?.detail)}</label></div>
        {error?.code===403 &&
          <input className= "formsubmit backgroundColor-Gradient font-large" value="Go back to process" type="button" onClick={onClickHandler}/>
        }

        </div>
        {/* Only for auto sync operation */}
        {(isRequestPending && error?.action) && (
				<div className="mt-2">
					<Box sx={{ display: 'flex', justifyContent: 'center' }}>
						<CircularProgress size={30} thickness={4} />
					</Box>
				</div>
			)}
      {/* For every error except auto sync and user lock scenario */}
      {(error?.code!=403 && !error?.action && !error?.popup_message) &&(
          <div className="cpl-25 cpr-25 cmb-8 w100 formsubmit-container">
          <AppButton onClick={onClose} displayText="Ok" />
        </div>
      )}
      </div>
    </Dialog>

  )
};
export default ErrorMessage;