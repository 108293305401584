import React, { useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import AppButton from "../Components/appButton";
const SuccessMessage = (props) => {
  const { onClose, open, message, redirectAfterSuccess } = props;
  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    return () => {
      const inputs = document.querySelectorAll('input');
      inputs.forEach((input) => input.focus());
      closeKeyboardHandler();
    }
  }, []);

  const closeKeyboardHandler = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ name: 'hide_keyboard' }))
    }
  }

  return (
    <Dialog className="dialog" onClose={handleClose} open={open}>
      <div className={`successMessage`}>
        <img src={process.env.PUBLIC_URL + '/Assets/icons/noun_tick.png'} alt="line" />
        <label>{message}</label>
      </div>
      <div className="cml-30 cmr-30 cmb-30 formsubmit-container">
            <AppButton onClick={(e) => { redirectAfterSuccess() }} displayText="Ok" />
          </div>
    </Dialog>

  )
};
export default SuccessMessage;