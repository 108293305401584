import AppHeader from "../Components/appHeader";
import React, { useEffect, useState, useRef } from "react";
import PickLocationsTile from "../Components/PicklocationsTile";
import MainScanTileWithCondition from "../Components/mainScanTileWithCondition";
import ScanScreenSubHeader from "../Components/ScanScreenSubHeader";
import Section from "../Components/section";
import AppButton from "../Components/appButton";
import ConfirmationPopup from "../Components/ConfirmationPopup";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../Redux/mainRedux/mainActions";
import ErrorMessage from "../Components/errorMessage";
import WarningMessage from "../Components/warningMessage";
import SuccessMessage from "../Components/successMessage";
import { slice } from "../Redux/mainRedux/mainSlice";
import TimerFooter from "../Components/timerFooter";
import TimerFooterCountup from "../Components/timerFooterCountup";
import PauseTimer from "../Components/pauseTimer";
import AudioPlayer from "../Components/audioPlayer";
import Backdrop from "@mui/material/Backdrop";
import ToggleButton from "../Components/toggleButton"
import BottomSlide from "../Components/bottomSlide";
import { hideKeyboard } from '../utility/hideKeyboard';

const ScanScreenType2 = () => {
  const { baseAPIURL, scan2, token, sessionStorage, apiSuccess, error, warning, success, timer, totalTimeSpend, timerdetail, pauseTime, pauseCount, keyboardStatus, userType } = useSelector(state => state.slice);
  const dispatch = useDispatch();
  const [scanVal, setScanVal] = useState(null)
  const [errorOpen, setErrorOpen] = useState(false);
  const [warningOpen, setWarningOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [checkReprintClick, setReprintClick] = useState(false);
  const [checkCountdown, setCountDown] = useState(false)
  const [checkShowCountDown, setShowCountDown] = useState(false)
  const [checkRestartTimer, setRestartTimer] = useState(false)
  const [checkPopupHolder, setPopupHolder] = useState(null);
  const [checkPause, setPause] = useState(false)
  const [totalTime, setTotalTime] = useState(0);
  const [totalPauseTime, setTotalPauseTime] = useState(0);
  const [successOpen, setSuccessOpen] = useState(false);
  const [scanError, setScanError] = useState(false);
  const [checkBackdrop, setBackdrop] = useState(false);
  const [hideArea, setHideArea] = useState(true)
  const [checkSuccessBeep, setSuccessBeep] = useState(false)
  const [showExitPopupVal, setExitPopupVal] = useState(false);
  const [checkBottomSlider, setBottomSlider] = useState(false);
  const [checkBottomSliderVal, setBottomSliderVal] = useState(null);


  let sound1 = "/Assets/audio/beep90.mp3";
  let sound2 = "/Assets/audio/goodscan.mp3";
  let sound3 = "/Assets/audio/badscan.mp3";
  let scanErrorMessage = {
    'detail': 'Barcode not recognized. Please scan again properly.'
  }

  window.scrollEnable = function () {
    setHideArea(false)
  }
  window.scrollDisable = function () {
    setHideArea(true)
  }
  const inputRef = useRef();
  // useEffect(() => {
  // }, [scanVal]);
  const scanHandler = e => {
    e.preventDefault()
    if (e.key === "Enter" || e.type === "click") {
      hideKeyboard();
      if (inputRef.current.value) {
        setSuccessBeep(false)
        dispatch(slice.actions.setApiSuccess(false))
        let body = { ...scan2.call_back_url_params, scan_value: inputRef.current.value, apiSessionStorage: sessionStorage, pause_duration: pauseTime, pause_count: pauseCount, net_time_consumed: totalTime };
        inputRef.current.value = "";
        // window.ReactNativeWebView.postMessage(JSON.stringify({ name: 'hide_keyboard' }))
        // inputRef.current.focus();
        hideKeyboard();
        dispatch(actions.generic_call(baseAPIURL, scan2.call_back_url, body, 'post', token));
      } else {
        hideKeyboard();
        setScanError(true);
        setErrorOpen(true);
      }
    }
  }

  const printCartonLabelsByPickTicketId = () => {
    // let body = { pick_ticket_id: scan2.pick_ticket_id, apiSessionStorage: sessionStorage }
    // dispatch(actions.generic_call('pickpack/printCartonLabelsByPickTicketId', body, 'post', token));
    setReprintClick(true);
    setConfirmOpen(true);
  }

  // For Error Message
  const errorCloseHandler = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ name: 'hide_keyboard' }))
    }
    setScanError(false);
    setErrorOpen(false);
    if (error.call_back_url && error.call_back_url_params) redirectAfterError(error)
    dispatch(slice.actions.clearError());
  };

  const confirmationCloseHandler = () => {
    setConfirmOpen(false);
  };

  const confirmationActionHandler = (data) => {
    setConfirmOpen(false);
    if (data.on_click_url) {
      if (data.title === "No" && data?.popup_message) {
        setPopupHolder(data)
        setErrorOpen(true);
        setTimeout(() => {
          redirectAfterConfirmation(data);
          // setErrorOpen(false);
          // dispatch(slice.actions.clearError());
          // setPopupHolder(null)
        }, 2000);
      } else {
        redirectAfterConfirmation(data);
      }
    }
  };

  const redirectAfterConfirmation = (data) => {
    let body = { ...data.on_click_url_params, apiSessionStorage: sessionStorage };
    dispatch(actions.generic_call(baseAPIURL, data.on_click_url, body, 'post', token));
  }

  // This will only come when there is RLLP is already created or call back URL is comes in error block
  const redirectAfterError = (data) => {
    let body = { ...data.call_back_url_params, apiSessionStorage: sessionStorage };
    dispatch(actions.generic_call(baseAPIURL, data.call_back_url, body, 'post', token));
  }

  const onExitClickHandler = () => {
    setReprintClick(false);
    setConfirmOpen(true);
  }

  useEffect(() => {
    // check error
    if (error) {
      setErrorOpen(true)
      if (error.code === 306) {
        setTimeout(() => {
          redirectAfterSessionExpire();
          localStorage.clear();
          setErrorOpen(false)
          dispatch(slice.actions.clearError());
        }, 2000);
      }
    }
    else {
      setErrorOpen(false)
    }
    // check success
    if (success) {
      setSuccessOpen(true)
      setSuccessBeep(true)

    } else setSuccessOpen(false)

    // check warning
    if (warning) {
      setWarningOpen(true)
      setTimeout(() => {
        setWarningOpen(false);
        dispatch(slice.actions.clearWarning());
      }, 2000);
    } else {
      setWarningOpen(false)
    }

  }, [error, success, warning]);

  useEffect(() => {
    if (checkPause) {
      setBackdrop(true)
    } else {
      setTimeout(() => {
        setBackdrop(false)
      }, 1000);

    }
  }, [checkPause])

  useEffect(() => {
    apiSuccess && resetTimer()
  }, [apiSuccess])

  const resetTimer = () => {
    dispatch(slice.actions.setTotalTimeSpend(null));
    setSuccessBeep(true)
    if (checkCountdown) {
      setCountDown(false);
      setShowCountDown(true);
      setRestartTimer(true);
    } else {
      if (checkShowCountDown) {
        setShowCountDown(false);
      }
      setTimeout(() => {
        setShowCountDown(true);
        setRestartTimer(true);
      }, 500);
    }
  }

  const redirectAfterSuccess = () => {
    setSuccessOpen(false);
    dispatch(slice.actions.clearSuccess());
    let body = { ...success.call_back_url_params, apiSessionStorage: sessionStorage };
    dispatch(actions.generic_call(baseAPIURL, success.call_back_url, body, 'post', token));
  }

  const redirectAfterSessionExpire = () => {
    dispatch(slice.actions.setPage('login'));
  }

  const exitClickPopup = (val) => {
    setExitPopupVal(val);
  }

  const routeOnExit = (callbackparam, callback_url) => {
    setExitPopupVal(false);
    let body = { ...callbackparam, apiSessionStorage: sessionStorage };
    dispatch(actions.generic_call(baseAPIURL, callback_url, body, 'post', token));
  }

  const onSubHeaderDescClick = (e) => {
    if (e.length > 24) {
      setBottomSlider(true)
      setBottomSliderVal(null)
      setBottomSliderVal(e)
    }
  }

  const closeHandle = () => {
    setBottomSlider(false)
  }

  return (
    <>
      <AppHeader
        pauseStatus={checkPause}
        checkCountdown={checkCountdown}
        menuIconVisible={false}
        backIconVisible={false}
        textVisible={true}
        text={scan2.title}
        waveDesc={scan2?.description}
        onHeaderDescClick={onSubHeaderDescClick}
        warehouseName={scan2?.warehouse_selector_button_text}
        warehouseEnable={scan2?.warehouse_list?.length > 0 ? true : false}
      />
      <ScanScreenSubHeader
        title1={scan2?.item?.title}
        value1={scan2?.item?.value}
        title2={scan2?.pallet?.title}
        value2={scan2?.pallet?.value}
        title3={scan2?.lot_title ? scan2?.lot_title?.title : null}
        value3={scan2?.lot_title ? scan2?.lot_title?.value : null}
        remainValue={{ total: scan2?.qty_requested_pick, remaining: scan2?.qty_remaining }}
        remainText={scan2?.remaining_text}
      />
      <div className="cpt-10 cpl-15 cpr-15 cpb-15 backgroundColor-Type5 mainPageType2">
        <div className="wfScreen">
          <div className="row">
            {
              hideArea ? (
                <>
                  {scan2.upper_left &&
                    <div className="col-sm-6 col-xs-6 col-6 cpr-5">
                      <PickLocationsTile text={scan2.upper_left.title} value={scan2.upper_left.value} />
                    </div>
                  }
                  {scan2.upper_right &&
                    <div className="col-sm-6 col-xs-6 col-6 cpl-2">
                      <PickLocationsTile text={scan2.upper_right.title} value={scan2.upper_right.value} />
                    </div>
                  }
                </>
              ) : null
            }
            <div className={`col-sm-12 col-xs-12 col-12 ${checkBackdrop ? 'cp-customwithBD' : 'cp-custom'}`}>
              <MainScanTileWithCondition
                unboundedText={scan2.blue_text}
                boundedText={scan2.blue_dotted_text}
                InputPlaceholder={scan2.scan_text}
                dropdownOptions={scan2.regional_throw_skip}
                casepack_text={scan2?.case_pack_text}
                casepack_text_color={scan2?.case_pack_text_color}
                casepack_background_color={scan2?.case_pack_background_color}
                throw_type={scan2?.throw_type}
                onScanHandler={scanHandler}
                scanValue={setScanVal}
                inputRef={inputRef}
                scanText={scanVal}
                onKeyUp={scanHandler}
              ></MainScanTileWithCondition>
            </div>
            <div className="col-sm-12 col-xs-12 col-12 cmtRem-24 place-carton">
              <Section left={scan2.framed_footer_box_text} right={scan2.framed_footer_box_right_justified} />
            </div>
            <div className="col-sm-12 col-xs-12 col-12 mb-1">
              <AppButton displayText="" />
            </div>
            {/* <ToggleButton
              displayText='Keyboard Enable'
              onChange={handleToggle}
            /> */}
            {checkPause &&
              <PauseTimer
                totalPauseTime={setTotalPauseTime}
              />
            }
            {
              userType != 'v1' ?
                (
                  <div className={`footer fixed-bottom ${keyboardStatus && 'focused'}`}>
                    {((!checkCountdown && checkShowCountDown) || (!scan2.rfTimerValues)) &&
                      <TimerFooter
                        reprint_data={scan2.reprint_btn}
                        pick_ticket_id={scan2.pick_ticket_id}
                        onreprintclick={printCartonLabelsByPickTicketId}
                        onExitClick={onExitClickHandler}
                        actualTimer={scan2.rfTimerValues}
                        expiryTimestamp={timer}
                        checkCountdown={setCountDown}
                        restartTimer={checkRestartTimer}
                        checkPause={setPause}
                        totalTime={setTotalTime}
                        totalPauseTime={totalPauseTime}
                        // this will send the boolean value
                        enableExit={scan2.hasOwnProperty('exit_button_response')}
                      />
                    }
                    {scan2.rfTimerValues && checkCountdown &&
                      <TimerFooterCountup
                        reprint_data={scan2.reprint_btn}
                        pick_ticket_id={scan2.pick_ticket_id}
                        onreprintclick={printCartonLabelsByPickTicketId}
                        onExitClick={onExitClickHandler}
                        checkPause={setPause}
                        totalTime={setTotalTime}
                        totalPauseTime={totalPauseTime}
                        // this will send the boolean value
                        enableExit={scan2.hasOwnProperty('exit_button_response')}
                      />
                    }

                  </div>
                ) : null
            }


            {(((checkCountdown) && (!checkPause) && (timerdetail?.cutoff_2_time > 0) && (timerdetail?.is_beep_enable === "Y")) || ((error?.code === 409))) &&
              <AudioPlayer
                sound={error?.code === 409 ? sound3 : sound1}
                loop={error?.code === 409 ? false : true}
                muted={(error?.code === 409) ? false : (totalTimeSpend > timerdetail?.cutoff_2_time ? false : true)}
              />
            }
            {(scan2?.beep_code === 200 && checkSuccessBeep) &&
              <AudioPlayer
                sound={sound2}
                loop={false}
                muted={false}
              />
            }
            {checkPause &&
              <Backdrop sx={{ color: "#fff" }} open={true} />
            }
          </div>
          {scan2?.mini_exit_btn && hideArea &&
            <div className="d-flex col-sm-6 col-xs-6 cpl-2">
              <div className={"ExitTile backgroundColor-Type3 font-small"} onClick={() => exitClickPopup(true)}>
                <span className="font-medium textColor-Type8">{scan2?.mini_exit_btn?.btn_text}</span>
              </div>
            </div>
          }
        </div>
      </div>
      {
        <BottomSlide open={showExitPopupVal} closeHandle={() => exitClickPopup(false)}>
          <div className="w-100">
            <h5 className="text-center my-4">{scan2?.mini_exit_btn?.blue_text}</h5>
            <div className="d-flex gap-2 p-2">
              <AppButton displayText={scan2?.mini_exit_btn?.btn_option[0].title} onClick={() => routeOnExit(scan2?.mini_exit_btn?.btn_option[0].on_click_url_params, scan2?.mini_exit_btn?.btn_option[0].on_click_url)} />
              <AppButton displayText={scan2?.mini_exit_btn?.btn_option[1].title} onClick={() => exitClickPopup(false)} isOutlined={true} />
            </div>
          </div>
        </BottomSlide>
      }
      <BottomSlide
        children={checkBottomSliderVal}
        open={checkBottomSlider}
        closeHandle={closeHandle}
      />
      {confirmOpen && <ConfirmationPopup open={confirmOpen} onClose={confirmationCloseHandler} data={checkReprintClick ? scan2.reprint_btn : scan2.exit_button_response} actionHandler={confirmationActionHandler} />}
      {errorOpen && <ErrorMessage open={errorOpen} onClose={errorCloseHandler} error={scanError ? scanErrorMessage : (checkPopupHolder ? checkPopupHolder : error)} />}
      {warningOpen && <WarningMessage open={warningOpen} warning={warning} />}
      {successOpen && <SuccessMessage open={successOpen} message={success.popup_message} redirectAfterSuccess={redirectAfterSuccess} />}
    </>
  );
};

export default ScanScreenType2;