export function FloatingSearch({
    placeholder,
    inputType,
    inputValue,
    inputHandler,
    handleSubmit,
    inputRef,
    exitButtonData,
    exitClickPopup
}) {

    const showKeyboardHandler = () => {
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                name: 'hide_keyboard_off',
            }))
        }
    }

    return (
        <form className="floating-search" onSubmit={handleSubmit}>
            <div style={{"display":"flex", "justifyContent": "space-between"}}>
            <div className="search-wrapper">
                <input
                    ref={inputRef}
                    // autoFocus={true}
                    value={inputValue}
                    type={inputType}
                    placeholder={placeholder}
                    onClick={showKeyboardHandler}
                    onChange={(e) => inputHandler(e.target.value)}
                    onPaste={(e) => {
                        e.preventDefault()
                        return false;
                    }}
                    onCopy={(e) => {
                        e.preventDefault()
                        return false;
                    }}
                />
                <img
                    onClick={handleSubmit}
                    className="op_5"
                    src={process.env.PUBLIC_URL + '/Assets/icons/noun_Barcode_2011116 2.svg'}
                    alt="line"
                />
            </div>
            {exitButtonData && 
                        <div className={"ExitTileSearchGrid font-small"} onClick={() => exitClickPopup(true)}>
                            <span className="font-medium textColor-Type8">
                                {exitButtonData?.btn_text}
                            </span>
                        </div>
            }
            </div>

        </form>
    );
}