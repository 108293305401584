
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { slice } from "../Redux/mainRedux/mainSlice";
import AppHeader from "../Components/appHeader";
import * as actions from "../Redux/mainRedux/mainActions";
const ClientSelectionScreen = () => {
    const { baseAPIURL, clients, token, sessionStorage } = useSelector(state => state.slice);
    const dispatch = useDispatch()
    
    const onClientSelect = (client) => {
        console.log(client);
        const body = { clientCode: client.client_code, apiSessionStorage: sessionStorage };
        dispatch(actions.generic_call(baseAPIURL, 'setClient', body, 'post', token, 'home'));
    }

    const onBackClick = () => {
        dispatch(slice.actions.setPage('accountSelection'));
    }

    return (
        <div>
            <AppHeader
                menuIconVisible={false}
                backIconVisible={true}
                textVisible={true}
                onBackClick={onBackClick}
                text={clients?.warehouse_page_title}
            />
       <div className="clientTable cpl-8 cpr-8 cpb-8 cpt-15" style={{ overflow: "scroll", padding: "8px", width: "375px" }}>
        <div style={{ overflow: "scroll", width: "500px" }}>
        <div className="title font-large cpb-10 cmr-10 cpl-10">
        {clients?.warehouse_table_header && clients?.warehouse_table_header.map((client, index) => (
                  <div key={index}>{client}</div>

        ))}
          </div>

        {clients?.warehouse_list && clients?.warehouse_list.map((client, index) => (
                  <div key={index} className="detail bg-white font-large py-3 cpl-10 cmb-6 cmr-10" onClick={() => onClientSelect(client)}>
                  <div >{client.name ? client.name : '-'}</div>
                  <div >{client.client_code ? client.client_code : '-'}</div>
                  <div >{client.state_province ? client.state_province : '-'}</div>
                  <div >{client.country ? client.country : '-'}</div>
                  </div>
        ))}
        </div>


        </div>
        </div>
    )
}

export default ClientSelectionScreen;