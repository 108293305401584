import React, { useEffect, useState } from "react";
import AppButton from "../Components/appButton";
import AppHeader from "../Components/appHeader";
import CountTile from "../Components/countTile";
import SubTitle from "../Components/subTitle";
import ErrorMessage from "../Components/errorMessage";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../Redux/mainRedux/mainActions";
import Slide from "@mui/material/Slide";
import Backdrop from "@mui/material/Backdrop";
import { slice } from "../Redux/mainRedux/mainSlice";
import HomeButton from "../Components/homeButton";

const FulfillmentWorkFlowScreen = () => {
  const { baseAPIURL, tile2, token, warehouse, sessionStorage, error, sourceOfRecord } = useSelector((state) => state.slice);
  const dispatch = useDispatch();
  const [warehouseClick, setWarehouseClick] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [errorOpen, setErrorOpen] = React.useState(false);

  useEffect(() => {
    if (window.ReactNativeWebView && sourceOfRecord === 'v2') {
      window.ReactNativeWebView.postMessage(JSON.stringify({ allowPullRefrest: true }))
    }
  }, [])

  // Api Call
  const getNextPickHandler = (e) => {
    if (!e.disabled) {
      clearSccess();
      let body = { ...e.call_back_url_params, apiSessionStorage: sessionStorage };
      dispatch(
        actions.generic_call(
          baseAPIURL,
          e.callback_url,
          body,
          "post",
          token
        )
      );
    }
  };

  // clear success after redirect from throw
  const clearSccess = () => {
    dispatch(slice.actions.clearSuccess());
  }

  // Handle change in click for dropdown and backdrop
  const handleChange = () => {
    //disable refresh when warehouse popup open
    if (window.ReactNativeWebView && sourceOfRecord === 'v2') {
      window.ReactNativeWebView.postMessage(JSON.stringify({ allowPullRefrest: false }))
    }
    setWarehouseClick((prev) => !prev);
    setOpen(!open);
  };

  // Handle close for dropdown and backdrop
  const handleClose = () => {
    //enable refresh when warehouse popup close
    if (window.ReactNativeWebView && sourceOfRecord === 'v2') {
      window.ReactNativeWebView.postMessage(JSON.stringify({ allowPullRefrest: true }))
    }
    setWarehouseClick((prev) => !prev);
    setOpen(false);
  };

  // For Error Message
  const errorCloseHandler = () => {
    setErrorOpen(false);
    dispatch(slice.actions.clearError());
  };

  useEffect(() => {
    if (error) {
      setErrorOpen(true)
      if (error.code === 306) {
        setTimeout(() => {
          redirectAfterSessionExpire();
          localStorage.clear();
          setErrorOpen(false)
          dispatch(slice.actions.clearError());
        }, 2000);
      }
    }
    else {
      setErrorOpen(false)
    }
  }, [error]);

  const onBackClick = () => {
    localStorage.clear();
    let body = { identifier: tile2?.back_button?.identifier, apiSessionStorage:sessionStorage }
    dispatch(actions.generic_call(baseAPIURL, tile2?.back_button?.callback_url, body, 'post', token));
    dispatch(slice.actions.setPage('tile2'))

  }

  const redirectAfterSessionExpire = () => {
    dispatch(slice.actions.setPage('login'));
  }

  return (
    tile2 && (
      <div className="backgroundColor-Type5 scroll-hide vh-100">
        <AppHeader
          menuIconVisible={false}
          backIconVisible={true}
          onBackClick={onBackClick}
          textVisible={true}
          text={tile2.title}
          warehouseName={tile2.warehouse_selector_button_text}
          warehouseClick={setWarehouseClick}
          onChange={handleChange}
          warehouseEnable={tile2?.warehouse_list?.length > 0 ? true : false}
        />
        {tile2.sub_title && (
          <SubTitle
            text={tile2.sub_title + " : "}
            value={tile2.sub_title ? tile2.sub_title_value : ""}
            valueVisible={true}
          />
        )}
        <CountTile count={tile2.remaining} text={tile2.assign_status} />
        <div style={{ bottom: 74 }} className="cml-30 cmr-30 col-sm-12 col-xs-12 fixed-bottom index cmb-30">
          <AppButton
            displayText={tile2.button_text}
            onClick={() => getNextPickHandler(tile2)}
            disabled={tile2.remaining > 0 ? false : true}
          />
        </div>

        <Backdrop sx={{ color: "#fff" }} open={open} onClick={handleClose} />
        <Slide direction="up" in={warehouseClick} mountOnEnter unmountOnExit>
          {Icon(tile2.warehouse_list, tile2, setWarehouseClick, setOpen)}
        </Slide>
        {errorOpen && <ErrorMessage open={errorOpen} onClose={errorCloseHandler} error={error} />}
        <HomeButton redirectData={tile2?.allow_home_button} />
      </div>
    )
  );
};

export default FulfillmentWorkFlowScreen;

const Icon = (WarehouseOptions, tile2_data, setWarehouseClick, setOpen) => {
  const { baseAPIURL, tile2, token, sessionStorage, currentPage, warehouseChange, sourceOfRecord } = useSelector(
    (state) => state.slice
  );

  const [flag, setFlag] = useState(false);

  const dispatch = useDispatch();
  const onClickHandler = (warehouseid) => {
    console.log("warehouse", warehouseid);
    if (window.ReactNativeWebView && sourceOfRecord === 'v2') {
      window.ReactNativeWebView.postMessage(JSON.stringify({ allowPullRefrest: true }))
    }
    if (warehouseid) {
      let body = {
        changed_warehouse_id: warehouseid,
        page_identifier: currentPage,
        apiSessionStorage: sessionStorage,
      };
      dispatch(
        actions.generic_call(
          baseAPIURL,
          tile2_data.warehouse_change_url,
          body,
          "post",
          token,
          "warehouseChange"
        )
      );
      setFlag(true);
      // If Status returns 200 then go for workflow screen and again hit api again -- This will be done after error handling on FE.
      // Identifier is hardcoded due to further discussions for any permanent solution
    }
  };

  useEffect(() => {
    if (flag && sourceOfRecord === 'v2') callScreen();
  }, [sessionStorage]);

  const callScreen = () => {

    let bodyforTile2 = {
      identifier: "regular",
      apiSessionStorage: sessionStorage,
    };
    dispatch(actions.generic_call(baseAPIURL, "workflow", bodyforTile2, "post", token, "tile2"));
    setFlag(false);
    setWarehouseClick(false)
    setOpen(false)
  };
  return (
    <div className="warehouse-panel">
      <div className="warehouse-panel-title">
        <span></span>
        <label>Select Warehouse</label>
      </div>
      <div className="warehouse-panel-items">
        {WarehouseOptions.map(({ v3Id, name }) => (
          <div
            className="warehouse-panel-list"
            key={v3Id}
            onClick={() => onClickHandler(v3Id)}
          >
            <label>{name}</label>
          </div>
        ))}
      </div>
    </div>
  );
};
