import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ActionBarComponentProps from "../Components/calender";

const MainScanTile4WithDate = ({ unboundedText, throw_type, button_type, DatePlaceholder, CommentsPlaceholder, onSearchHandler, onScanHandler, scanValue, inputRef, inputRef1, searchoptions, scanicon }) => {
    const { keyboardType } = useSelector(state => state.slice);
    const [showCalender, setShowCalender] = useState(false);
    const [inputMode, setInputMode] = useState('none');
    // setting the default date and it's format for calender field
    const [calenderValue, setCalenderValue] = useState(new Intl.DateTimeFormat(['en-US', 'id'], { year: 'numeric', day: '2-digit', month: '2-digit' }).format(new Date()));

    useEffect(() => setInputMode('none'), [unboundedText])
    const onCalenderClick = () => {
        setShowCalender(true)
    }

    const showKeyboard = () => {
        setInputMode('text');
        inputRef.current.inputMode = 'text';
    }

    return (
        <><div className={"cmt-10 mainScanTile backgroundColor-Type3"}>
            <div className={"backgroundColor-Gradient uperScaner-div"}>
                <div className="uperSD-Text font-large-md pick-locations-title">{unboundedText}</div>
            </div>
            <div className="backgroundColor-Type3 scanner-divWithDate cpt-15 cmb-15">
                {/* Date Section */}
                <div className=" backgroundColor-Type4 scannerInput-4 cmb-15 field" style={{ "width": button_type ? "80%" : "95%" }}>
                    <input type={keyboardType}
                        autoFocus
                        readOnly
                        ref={inputRef}
                        className="backgroundColor-Type4 inputscan"
                        onPaste={(e) => {
                            e.preventDefault();
                            return false;
                        }}
                        onCopy={(e) => {
                            e.preventDefault();
                            return false;
                        }}
                        value={calenderValue}
                        onKeyUp={onScanHandler}
                        placeholder={DatePlaceholder}
                        onChange={(e) => scanValue(e.target.value)}
                    />

                    <img className="floatRandight" src={process.env.PUBLIC_URL + 'Assets/icons/calendar.png'} alt="calender" onClick={(e) => { onCalenderClick(e); }} />

                </div>
                {/* Comment section */}
                <div className=" backgroundColor-Type4 scannerInput-4 cmb-15 field" style={{ "height": "100%", "padding": "0" }}>
                    <textarea type={keyboardType}
                        autoFocus
                        ref={inputRef1}
                        className="backgroundColor-Type4 inputscan" style={{ "height": "100%" }}
                        onPaste={(e) => {
                            e.preventDefault();
                            return false;
                        }}
                        onCopy={(e) => {
                            e.preventDefault();
                            return false;
                        }}
                        placeholder={CommentsPlaceholder}
                        onClick={showKeyboard}
                        inputMode={inputMode}
                    />
                </div>
            </div>

        </div>
            {showCalender &&
                <ActionBarComponentProps
                    changeValue={calenderValue}
                    setShowCalender={setShowCalender}
                    setCalenderValue={setCalenderValue}
                    open={showCalender}
                    onClose={() => setShowCalender(false)}
                />
            }</>
    )
}

export default MainScanTile4WithDate;
