import React, { useState, useEffect } from "react";
import { useTimer } from "react-timer-hook";
import PauseOutlinedIcon from "@mui/icons-material/PauseOutlined";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useDispatch, useSelector } from "react-redux";
import { slice } from "../Redux/mainRedux/mainSlice";
import * as actions from "../Redux/mainRedux/mainActions";

const TimerFooter = ({ reprint_data, pick_ticket_id, onreprintclick, onExitClick, enableExit, actualTimer, expiryTimestamp, checkCountdown, checkPause, restartTimer, totalTime, totalPauseTime }) => {
  const { baseAPIURL, token, sessionStorage, timerdetail, pauseCount, pauseTime } = useSelector(state => state.slice);
  const dispatch = useDispatch();
  const [isPlay, setICon] = useState(false);
  const [totalPauseCountVal, setTotalPauseCount] = useState(0)

  // Click function for Pause / Play
  const isClick = () => {
    setICon((icon) => !icon);
    if (!isPlay) {
      // Will Start Pause timer
      checkPause(true);
      // Will plus 1 in the pause count
      setTotalPauseCount(totalPauseCountVal => totalPauseCountVal + 1);
      setTotalPauseCount(totalPauseCountVal => totalPauseCountVal + pauseCount);
      dispatch(slice.actions.setpauseCount(totalPauseCountVal));
    } else {
      // Will Stop Pause timer
      checkPause(false);
      sendPauseTimer();
    }
  };

   // Will send total Pause time and count to separate API
  const sendPauseTimer = () => {
  const time = pauseTime + totalPauseTime;
  dispatch(slice.actions.setpauseTime(time));
  let body = { pause_duration: time,pause_count: totalPauseCountVal,  apiSessionStorage: sessionStorage };
  dispatch(actions.generic_call(baseAPIURL, 'pickpack/pauseButtonPressed', body, 'post', token)); 
}

  const {
    seconds,
    minutes,
    hours,
    pause,
    resume,
    restart
  } = useTimer({
    expiryTimestamp,
    autoStart: true,
    onExpire() {
      callCoTimer();
    },
  });

  useEffect(() => {
  if(restartTimer)
  {
       // Restarts to newer value
       const time = new Date();
       time.setSeconds(time.getSeconds() + timerdetail?.timer_value);
       restart(time);
  }
  }, [restartTimer]);

  // Will run at the end of countdown
  const callCoTimer = () => {
    checkCountdown(true);
    checkPause(false);
  };

  // Add additional ZERO at start if digit is in single figure
  const checkDigit = (val) => {
    return val < 10 ? `0${val}` : val;
  };
  
  // Will Update total time
  useEffect(() => {
    const time = timerdetail?.timer_value - convertToSec(hours,minutes,seconds);
    totalTime(time);
  }, [hours,minutes,seconds]);

  // Will convert All Hours , Minutes and Seconds into Seconds
  const convertToSec = (hrs, mins, sec) => {
    const hrsToSec = hrs>0? (hrs*3600) : 0;
    const minsToSec = mins>0? (mins*60) : 0;
    const counterSeconds = hrsToSec + minsToSec + sec;
    return counterSeconds 
}

  return (
    <>
      {/* <div className="formsubmit-container"> */}
      <div className="formsubmit-container cmb-15">
        <div className="backgroundColor-Type3 ScanScreen1Footer">
          {
            <div className="cpl-15 cpr-15 font-medium">
              {" "}
              Timer: <span>{checkDigit(minutes)}</span>:
              <span>{checkDigit(seconds)}</span>
            </div>
          }

          <div className={`timer_outer ${!actualTimer && 'clickNotAllow'}`}>
            {isPlay ? (
              <div className="timer_inner" onClick={resume}>
                <PlayArrowIcon onClick={isClick} />
              </div>
            ) : (
              <div className="timer_inner" onClick={pause}>
                <PauseOutlinedIcon onClick={isClick} />
              </div>
            )}
          </div>
          <div className="pd-max cpl-10 cpr-10 cpb-5">
              <span className={!reprint_data || isPlay ? 'disableTile' : ''} onClick={reprint_data && !isPlay ? onreprintclick : undefined}>Reprint</span>
              <span> | </span>
              <span className={!enableExit || isPlay ? 'disableTile' : ''} onClick={enableExit && !isPlay ? onExitClick : undefined}>Exit</span>
        </div>
        </div>
      </div>
    </>
  );
};

export default TimerFooter;
