const MenuTile = ({ data, onClickHandler, index }) => {
  return (
    <div className={`col-sm-3 col-xs-6 col-6 cmb-30 ${data.disabled ? 'disableTile' : 'enableTile'} ${(index + 1) % 2 === 0 ? "cpl-5" : "cpr-5"}`}>
      <div className="backgroundColor-Type3 menutile" onClick={() => onClickHandler(data)}>
        <img src={data.display_icon} alt="menuIcon" style={{ width: '30%' }} />
        <img className="my-1" src={process.env.PUBLIC_URL + "/Assets/icons/Line 1.svg"} alt="line" />
        <p className="font-large m-0">{data.display_text}</p>
      </div>
    </div>
  );
};

export default MenuTile;
